import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, getDay, parse, set, startOfWeek } from "date-fns";
import Modal from "react-modal";
import { defaultDataIdFromObject, useMutation } from "@apollo/client";
import { utcToZonedTime } from "date-fns-tz";
import {
  Mutation,
  MutationCreateAppointmentArgs,
  MutationDeleteAppointmentArgs,
  MutationUpdateAppointmentArgs,
  Query,
  QueryGetAppointmentsArgs,
  Technician,
} from "../../generated/nest-graphql";
import { CREATE_APPOINTMENT } from "../../graphql/mutations/createAppointment";
import { GET_APPOINTMENTS } from "../../graphql/queries/getAppointments";
import { UPDATE_APPOINTMENT } from "../../graphql/mutations/updateAppointment";
import { AppointmentForm, AppointmentFormValues } from "../Forms/AppointmentForm";
import { head, last, path, pipe, prop } from "ramda";
import { default as React, useState } from "react";
import { DELETE_APPOINTMENT } from "../../graphql/mutations/deleteAppointment";
import { TechnicianResourceView } from "./TechnicianResourceView";
import { add, endOfDay } from "date-fns/fp";
import { useHistory } from "react-router-dom";
import { NuModal } from "../NuModal";
import Box from "@material-ui/core/Box";
import { useToggle } from "../../hooks/useToggle";
import { technicianCopySpec } from "../specs/technicianCopySpec";
import { zonedTimeToUtc } from "date-fns-tz";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
type AppointmentCalendarProps = {
  startRange: Date;
  endRange: Date;
  setCalendarView: (view: any) => void;
  calendarView: string;
  events: any[];
  technicianColors: any;
  dateToFocusOn: Date;
  onDateChange: any;
  onDateRangeChange;
  technicians: Technician[];
  marketFilter?: any;
  timeZone: string;
};
export const AppointmentCalendar: React.FC<AppointmentCalendarProps> = ({
  onDateRangeChange,
  dateToFocusOn,
  onDateChange,
  events,
  technicians,
  technicianColors,
  calendarView,
  setCalendarView,
  startRange,
  endRange,
  marketFilter,
  timeZone,
}) => {
  Modal.setAppElement("#root");
  const history = useHistory();
  const [deleteAppointment] = useMutation<Mutation, MutationDeleteAppointmentArgs>(DELETE_APPOINTMENT);
  const [updateAppointment] = useMutation<Mutation, MutationUpdateAppointmentArgs>(UPDATE_APPOINTMENT);
  const [createAppointment] = useMutation<Mutation, MutationCreateAppointmentArgs>(CREATE_APPOINTMENT);
  const createOnSubmit = async (
    { startTimeWindow, endTimeWindow, technician, subject, startDate, endDate, allDay }: AppointmentFormValues,
    formikHelpers
  ) => {
    // TODO: test this asap
    await createAppointment({
      variables: {
        createAppointmentInput: {
          technician: technician.id,
          subject,
          timeWindow: {
            startTimeWindow: zonedTimeToUtc(startTimeWindow, timeZone),
            endTimeWindow: zonedTimeToUtc(endTimeWindow, timeZone),
          },
          startDate: zonedTimeToUtc(startDate, timeZone),
          endDate: zonedTimeToUtc(endDate, timeZone),
          allDay,
          technicianCopy: technicianCopySpec(technician),
        },
      },
      update: (cache, mutationResult) => {
        try {
          const { getAppointments } = cache.readQuery<Query, QueryGetAppointmentsArgs>({
            query: GET_APPOINTMENTS,
            variables: {
              startRange,
              endRange,
              ...marketFilter,
            },
          });
          cache.writeQuery({
            query: GET_APPOINTMENTS,
            variables: { startRange, endRange, ...marketFilter },
            data: {
              getAppointments: [...getAppointments, mutationResult.data.createAppointment],
            },
          });
        } catch (err) {
          console.error(err);
        }
      },
    });
    toggleCreateModal();
  };
  const updateOnSubmit = async (values: AppointmentFormValues, formikHelpers) => {
    const { startTimeWindow, endTimeWindow, technician, subject, startDate, endDate, allDay, job } = values;
    await updateAppointment({
      variables: {
        id: appointmentValues.id,
        updateAppointmentInput: {
          technician: prop("id", technician),
          subject,
          timeWindow: {
            startTimeWindow: zonedTimeToUtc(startTimeWindow, timeZone),
            endTimeWindow: zonedTimeToUtc(endTimeWindow, timeZone),
          },
          startDate: zonedTimeToUtc(startDate, timeZone),
          endDate: zonedTimeToUtc(endDate, timeZone),
          allDay,
          job,
          technicianCopy: technicianCopySpec(technician),
        },
      },
    });
    toggleUpdateModal();
  };
  const [appointmentValues, setAppointmentValues] = useState(null);
  const [onDeleteSubmitting, setOnDeleteSubmitting] = useState(false);
  const deleteOnSubmit = async () => {
    setOnDeleteSubmitting(true);
    const { id } = appointmentValues;
    await deleteAppointment({
      variables: {
        id,
      },
      update: (cache) => {
        cache.evict({
          id: defaultDataIdFromObject(appointmentValues),
          broadcast: true,
        });
        cache.gc();
      },
    });
    setOnDeleteSubmitting(false);
    toggleUpdateModal();
  };
  const [createModalIsOpen, , toggleCreateModal] = useToggle();
  const [updateModalIsOpen, , toggleUpdateModal] = useToggle();
  // @ts-ignore
  const minDate = set(new Date(), {
    hours: 6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return (
    //just works idk
    <Box style={{ overflow: "auto" }}>
      <Calendar
        selectable={true}
        localizer={localizer}
        // views={{["day", "agenda", "week", "month"]}}
        // @ts-ignore
        views={{
          day: true,
          week: true,
          agenda: true,
          month: true,
          assign: TechnicianResourceView,
        }}
        showMultiDayTimes
        technicians={technicians}
        events={events}
        onSelectEvent={async (event: any) => {
          if (prop("job", event)) {
            const jobId = path(["job", "id"], event);
            await history.push(`/jobs/${jobId}`);
          } else {
            setAppointmentValues({
              startDate: event.start,
              startTimeWindow: event.startTimeWindow,
              endTimeWindow: event.endTimeWindow,
              endDate: event.end,
              id: event.id,
              allDay: event.allDay,
              technician: event.technician,
              subject: event.subject,
              timeZone: event.timeZone,
              job: event.job,
              __typename: "Appointment",
            });
            toggleUpdateModal();
          }
        }}
        onSelectSlot={(slotInfo) => {
          setAppointmentValues({
            startDate: slotInfo.start,
            endDate: slotInfo.end,
            startTimeWindow: slotInfo.start,
            timeZone: timeZone,
            endTimeWindow: add({ minutes: 30 }, slotInfo.start),
          });
          toggleCreateModal();
        }}
        onNavigate={onDateChange}
        date={typeof dateToFocusOn === "string" ? new Date(dateToFocusOn) : dateToFocusOn}
        components={{ event: Event }}
        defaultView={calendarView}
        view={calendarView}
        onRangeChange={async (range) => {
          if (Array.isArray(range)) {
            const firstDay = head(range);
            const lastDay = pipe(last, endOfDay)(range);
            onDateRangeChange(firstDay, lastDay);
          } else {
            onDateRangeChange(prop("start", range), prop("end", range));
          }
        }}
        dayPropGetter={(date) => {
          return {
            className: "bg-black",
          };
        }}
        // @ts-ignore
        messages={{
          // @ts-ignore
          assign: "Assign",
        }}
        min={minDate}
        slotPropGetter={(date) => {
          return {
            className: "bg-black",
          };
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          // @ts-ignore
          const color = technicianColors[event.technician.id] ? technicianColors[event.technician.id].color : "#ff0029";
          return {
            style: {
              backgroundColor: `#${color}`,
            },
          };
        }}
        formats={{
          agendaDateFormat: (date, _, localizer) => {
            // @ts-ignore
            return localizer.format(date, "LLL d yyyy eee");
          },
          dayHeaderFormat: (date, _, localizer) => {
            // @ts-ignore
            return localizer.format(date, "LLL d yyyy eee");
          },
        }}
        onView={(view) => {
          setCalendarView(view);
        }}
        className="bg-black border border-black"
        getNow={() => utcToZonedTime(new Date(), timeZone)}
        startAccessor="start"
        endAccessor="end"
        resourceIdAccessor={"resourceId"}
        resourceAccessor={"technicianId"}
        resourceTitleAccessor={"resourceTitle"}
      />
      <NuModal isOpen={createModalIsOpen} maxWidth="md" title="Create Appointment">
        <AppointmentForm onSubmit={createOnSubmit} onCancel={toggleCreateModal} initialValues={appointmentValues} />
      </NuModal>
      <NuModal isOpen={updateModalIsOpen} maxWidth="md" title="Update Appointment">
        <AppointmentForm
          onDelete={deleteOnSubmit}
          onDeleteSubmitting={onDeleteSubmitting}
          onSubmit={updateOnSubmit}
          onCancel={toggleUpdateModal}
          initialValues={appointmentValues}
        />
      </NuModal>
    </Box>
  );
};

const Event = (props) => {
  const { event } = props;
  const job = prop("job", event);
  if (job) {
    return (
      <div>
        <div>
          <strong>
            {job.jobNumber}: {job.status}
          </strong>
          <div>{job.serviceLocation}</div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>{event.title}</div>
    </div>
  );
};
