import gql from "graphql-tag";
import { paymentDetailsFields } from "./getPayment";

export const GET_PAYMENTS = gql`
  query getPayments($cursor: String, $limit: Int, $filter: JSON) {
    getPayments(cursor: $cursor, limit: $limit, filter: $filter) {
      edges {
        ${paymentDetailsFields}
      }
      pageInfo {
        endCursor
        hasNextPage
        numRecords
      }
    }
  }
`;
