import React from "react";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { ContactColumns } from "../Contacts/ContactsTable";

export const ContactsSearchResults: React.FC<{ contacts: any[] }> = ({
  contacts,
}) => {
  return (
    <div className={"m-6"}>
      <h4>Contacts</h4>
      <SelectableEntityTable
        data={contacts}
        title="Contacts"
        columns={ContactColumns}
        numRecords={contacts.length}
      />
    </div>
  );
};
