import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Job, Query, QueryGetJobsPaginatedV2Args } from "../../generated/nest-graphql";
import { applySpec, isNil, mergeDeepRight, path, pathOr, pipe, prop, reject } from "ramda";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_JOBS_PAGINATED_V2 } from "../../graphql/queries/getJobsPaginatedV2";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT } from "../../lib/constants";

type JobsTableType = {
  id: string;
  jobNumber: string;
  type: string;
  jobName: string;
  contact: {
    id: string;
    firstName: string;
    lastName: string;
  };
  updateAt: string;
  status: string;
  appointment: string;
  technician: string;
  serviceLocation: string;
  partsOrdered: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export const JobColumns: Column<JobsTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/jobs/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Job Number", accessor: "jobNumber" },
  {
    Header: "Contact",
    accessor: "contact",
    Cell: (props) => {
      const { firstName, lastName, id } = props.cell.value;
      return (
        <Link className={"text-primary w-full block text-center"} to={`/contacts/${id}`}>
          {firstName} {lastName}
        </Link>
      );
    },
  },
  { Header: "Job Name", accessor: "jobName" },
  { Header: "Service Location", accessor: "serviceLocation" },
  { Header: "Technician", accessor: "technician" },
  { Header: "Type", accessor: "type" },
  { Header: "Status", accessor: "status" },
  { Header: "Appointment", accessor: "appointment" },
  {
    Header: "Parts Ordered",
    accessor: "partsOrdered",
    Cell: (props) => {
      const val = props.cell.value;
      return val ? "Y" : "N";
    },
  },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Created", accessor: "createdAt" },
  { Header: "Updated", accessor: "updatedAt" },
];

export const JobsTable: React.FC<{
  filters: any;
  endAdornments: ReactNode;
}> = ({ filters, endAdornments }) => {
  const limit = 50;
  const filtersToSet = reject(isNil)(filters);
  const { data, loading, fetchMore } = useQuery<Query, QueryGetJobsPaginatedV2Args>(GET_JOBS_PAGINATED_V2, {
    variables: {
      paginatedQueryInput: {
        filter: filtersToSet,
        limit,
        skip: 0,
      },
    },
  });
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          filter: filtersToSet,
          limit,
          skip: data.getJobsPaginatedV2.pageInfo.offset + 50,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getJobsPaginatedV2: {
            // @ts-ignore
            edges: [...prev.getJobsPaginatedV2.edges, ...fetchMoreResult.getJobsPaginatedV2.edges],
            // @ts-ignore
            pageInfo: mergeDeepRight(prev.getJobsPaginatedV2.pageInfo, fetchMoreResult.getJobsPaginatedV2.pageInfo),
            // @ts-ignore
            __typename: prev.getJobsPaginatedV2.__typename,
          },
        };
      },
    });
  };

  return (
    <>
      {loading && <LinearProgress />}
      <SelectablePageableEntityTable
        title={"Jobs"}
        queryResult={data}
        columns={JobColumns}
        queryKey={"getJobsPaginatedV2"}
        numRecords={pathOr(0, ["getJobsPaginatedV2", "pageInfo", "numRecords"], data)}
        loadMore={() => loadMore(data.getJobsPaginatedV2.pageInfo.endCursor)}
        limit={limit}
        spec={jobsToJobColumns}
        endAdornments={endAdornments}
      />
    </>
  );
};
export const jobsToJobColumns = applySpec({
  id: prop("id"),
  type: prop("type"),
  jobName: prop("jobName"),
  jobNumber: prop("jobNumber"),
  contact: {
    id: path(["contact", "id"]),
    firstName: path(["contact", "firstName"]),
    lastName: path(["contact", "lastName"]),
  },
  createdBy: prop("createdBy"),
  createdAt: pipe(prop("createdAt"), formatDateTime),
  updatedAt: pipe(prop("updatedAt"), formatDateTime),
  status: prop("status"),
  appointment: (job: Job) => {
    const startDateString = pathOr("", ["appointment", "startDate"], job);
    const localZone = DateTime.local().zoneName;
    const timeZone = pathOr(localZone, ["appointment", "timeZone"], job);
    return DateTime.fromJSDate(new Date(startDateString)).setZone(timeZone).toFormat(DATE_TIME_FORMAT);
  },
  technician: (job: Job) => {
    if (!path(["appointment", "technician"], job)) return "";
    const { firstName, lastName } = job.appointment.technician;
    return `${firstName} ${lastName}`;
  },
  serviceLocation: prop("serviceLocation"),
  partsOrdered: path(["partsInfo", "partsOrdered"]),
});
