import React from "react";
import { ContactsTabView } from "../../components/Contacts/ContactsTabView";
import { useParams } from "react-router-dom";

const ContactsDetailPage: React.FC<{}> = () => {
  const params = useParams();
  return (
    <div>
      <ContactsTabView
        // @ts-ignore
        contactId={params.id}
      />
    </div>
  );
};

export default ContactsDetailPage;
