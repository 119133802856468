import React from "react";
import { ItemFormValues } from "../Forms/ItemForm";
import { calculatePartsCostFromItems, calculateSubtotalFromItems } from "../../lib/functions";
import { useMarkets } from "../../hooks/useMarkets";
import { find, propEq, props } from "ramda";
import { flow } from "fp-ts/lib/function";
import currency from "currency.js";

export const ItemsReceiptSection: React.FC<{
  items: ItemFormValues[];
  market: string;
  amountPaid?: string;
  balanceDue?: string;
  taxable: boolean;
}> = ({ items, market, amountPaid, balanceDue, taxable }) => {
  const markets: any = useMarkets();
  if (!markets) {
    return null;
  }

  const [partsTaxRate, laborTaxRate] = flow<any, any, any>(
    find(propEq("name", market)),
    props(["partsTaxRate", "laborTaxRate"])
  )(markets);
  const subTotal = currency(calculateSubtotalFromItems(items));
  const partsCost = currency(calculatePartsCostFromItems(items));
  const laborCost = subTotal.subtract(partsCost);
  const partsTax = partsCost.multiply(partsTaxRate / 100);
  const laborTax = laborCost.multiply(laborTaxRate / 100);
  const taxAmount = taxable ? partsTax.add(laborTax) : 0;
  const total = subTotal.add(taxAmount);
  return (
    <div className="border rounded mt-1 mb-8 bg-black-900 px-4 py-2">
      <ReceiptItem text={"Subtotal"} amount={subTotal.toString()} />
      <ReceiptItem text={"Parts"} amount={partsCost.toString()} subItem />
      <ReceiptItem text={"Labor"} amount={laborCost.toString()} subItem />
      <ReceiptItem text={"Tax"} amount={taxAmount.toString()} />
      {taxable && (
        <>
          <ReceiptItem text={"Parts Tax"} amount={partsTax.toString()} subItem />
          <ReceiptItem text={"Labor Tax"} amount={laborTax.toString()} subItem />
        </>
      )}
      <ReceiptItem text={"Total"} amount={total.toString()} />
      {amountPaid && <ReceiptItem text={"Amount Paid"} amount={amountPaid} />}
      {balanceDue && <ReceiptItem text={"Balance Due"} amount={balanceDue} />}
    </div>
  );
};

export const ReceiptItem: React.FC<{ text: string; amount: string; subItem?: boolean }> = ({
  text,
  amount,
  subItem,
}) => {
  return (
    <div className={`flex flex-row justify-between m-4 ${subItem ? "pl-6" : ""}`}>
      <div className={`${subItem ? "text-xs" : "font-bold"}`}>{text}</div>
      <div className={`${subItem ? "text-xs" : "font-bold"}`}>${amount}</div>
    </div>
  );
};
