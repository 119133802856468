import gql from "graphql-tag";
import { partsStoreDetailsFields } from "./getPartsStore";

export const defaultServiceReturnFields = `
            service
            name
            technicianPay
            customerPrice
            inEstimate
            items {
                serviceProductId
                productSelection {
                    name
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
            }
        `;

export const GET_DEFAULT_SERVICES = gql`query getDefaultServices($getDefaultServicesInput: GetDefaultServicesInput!) {
    getDefaultServices(getDefaultServicesInput: $getDefaultServicesInput) {
        ${defaultServiceReturnFields}
    }
}`;
