import { FieldArray } from "formik";
import React from "react";
import { SchedulingDay } from "../../generated/nest-graphql";
import { SchedulingDayField } from "./SchedulingDayField";

export const TechnicianSchedulingDays: React.FC<{
  values: SchedulingDay[];
  name: string;
  dateModifier: any;
  setFieldValue: any;
}> = ({ values, name, dateModifier, setFieldValue }) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {values.map((schedulingDay, index) => (
              <SchedulingDayField
                key={index}
                schedulingDay={schedulingDay}
                startTimeName={`schedulingDays[${index}].startTime`}
                endTimeName={`schedulingDays[${index}].endTime`}
                dateModifier={dateModifier}
                className={"grid grid-cols-3 gap-4 pt-2 pb-1 place-items-center bg-gray-900"}
                setFieldValue={setFieldValue}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};
