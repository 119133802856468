export const partsOptions = [
  { name: "Standard", value: "standardOption" },
  { name: "Premium", value: "premiumOption" },
  { name: "Performance", value: "performanceOption" },
];

export const dayOfWeekOptions = [
  { name: "Monday", value: "monday" },
  { name: "Tuesday", value: "tuesday" },
  { name: "Wednesday", value: "wednesday" },
  { name: "Thursday", value: "thursday" },
  { name: "Friday", value: "friday" },
  { name: "Saturday", value: "saturday" },
  { name: "Sunday", value: "sunday" },
];

export const leadTimes = {
  sameDay: { name: "Same Day", value: "sameDay" },
  nextDay: { name: "Next Day", value: "nextDay" },
  twoPlusDays: { name: "2+ Days", value: "twoPlusDays" },
};

export const leadTimeOptions = [leadTimes.sameDay, leadTimes.nextDay, leadTimes.twoPlusDays];
