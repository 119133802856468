import React, { useState } from "react";
import { CREATE_CARD_SETUP_INTENT } from "../../graphql/mutations/createCardSetupIntent";
import { ADD_CONTACT_PAYMENT_METHOD } from "../../graphql/mutations/addContactPaymentMethod";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../generated/nest-graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CancelButton } from "../Buttons/CancelButton";
import { Button } from "../Buttons/Button";
import { path } from "ramda";
import { useShowSuccess } from "../../redux/slices/snackbar";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const AddCreditCardForm: React.FC<{ toggle: () => void; contactId: string }> = ({ toggle, contactId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const showSuccess = useShowSuccess();
  const [submitting, setIsSubmitting] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState("");

  const [createCardSetupIntent] = useMutation<Mutation>(CREATE_CARD_SETUP_INTENT);
  const [addContactPaymentMethod] = useMutation<Mutation>(ADD_CONTACT_PAYMENT_METHOD);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    setCardErrorMessage("");

    const { data } = await createCardSetupIntent({ variables: { contactId } });

    // @ts-ignore
    const client_secret: string = path(["createCardSetupIntent", "client_secret"], data);
    const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (error) {
      setCardErrorMessage(error.message);
    } else {
      const { payment_method } = setupIntent;
      await addContactPaymentMethod({
        variables: {
          contactId,
          paymentMethodId: payment_method,
        },
      });
      showSuccess({ message: "Successfully Added Credit Card" });
      toggle();
    }
    setIsSubmitting(false);
  };
  return (
    <div className="grid gap-4 mb-10">
      <>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        {cardErrorMessage && <div style={{ color: "red" }}>{cardErrorMessage}</div>}
        <Button type={"button"} onClick={handleSubmit} disabled={submitting || !stripe}>
          {submitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : "Submit"}
        </Button>
      </>
      <CancelButton onClick={toggle}>Cancel</CancelButton>
    </div>
  );
};
