import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import { findAppointmentIndexTime } from "./functions";
import { AppointmentEvent } from "./AppointmentEvent";
import { TravelDistanceEvent } from "./TravelDistanceEvent";
import { Appointment } from "../../generated/nest-graphql";
import { defaultTo } from "ramda";

export const useStyles = makeStyles({
  root: {
    minWidth: 200,
    padding: 1,
    border: "2px solid #a9a9a9",
  },
});

export const DriveTimeDistanceTableCell: React.FC<{
  appointments: Appointment[];
  time: string;
}> = ({ appointments, time }) => {
  const classes = useStyles();
  const index = findAppointmentIndexTime(time, appointments);
  if (index === -1) {
    return <TableCell className={classes.root} />;
  }

  const originAppointment = defaultTo(null)(appointments[index]);
  const destinationAppointment = defaultTo(null)(appointments[index + 1]);
  return (
    <TableCell className={classes.root}>
      <AppointmentEvent appointment={originAppointment} />
      {destinationAppointment && (
        <TravelDistanceEvent originAppointment={originAppointment} destinationAppointment={destinationAppointment} />
      )}
    </TableCell>
  );
};
