import { ProductSetDetailsFormValues } from "../Forms/ProductSetDetailsForm";
import { applySpec, pipe, pluck, prop } from "ramda";

export const productSetDetailsSpec = (
  formData: ProductSetDetailsFormValues
) => {
  return applySpec({
    name: prop("name"),
    description: prop("description"),
    // @ts-ignore
    products: pipe(prop("products"), pluck("id")),
  })(formData);
};
