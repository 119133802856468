import React from "react";
import { Box, Typography } from "@material-ui/core";

interface CartLineItemProps {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
  boxPropsOverride?: any;
  subItem?: boolean;
}

export const CartLineItem = ({ title, value, subItem = false }: CartLineItemProps) => {
  return (
    <Box
      paddingLeft={subItem ? 4 : 0}
      fontWeight={900}
      display={"flex"}
      flexDirection={"row"}
      justifyContent="space-between"
    >
      <Typography component="div">{title}</Typography>
      <Typography component="div">{value}</Typography>
    </Box>
  );
};
