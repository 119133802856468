import * as React from "react";
import { Button, Drawer, Tooltip } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons/faAddressBook";
import { faCar } from "@fortawesome/free-solid-svg-icons/faCar";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCalculator } from "@fortawesome/free-solid-svg-icons/faCalculator";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faCarSide, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { useToggle } from "../hooks/useToggle";
import { FeedbackDialog } from "./Feedback/FeedbackDialog";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { includes, propOr } from "ramda";
import { useAuth0 } from "@auth0/auth0-react";
import { authorizedEmails } from "../data/authorizedEmails";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#202020",
  },
  drawerOpen: {
    backgroundColor: "#202020",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#202020",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
export const SideDrawer: React.FC = () => {
  const { user } = useAuth0();
  const [feedbackDialogOpen, , toggleFeedbackDialog] = useToggle();
  const [open, , toggle] = useToggle(false);
  const classes = useStyles();
  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="permanent"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Toolbar />
      <div>
        <IconButton color="inherit" aria-label="open drawer" onClick={toggle} edge="end">
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <SideDrawerItem icon={faHome} textPrimary={"Home"} href={"/"} />
        <SideDrawerItem icon={faSearch} textPrimary={"Pricing Tool"} href={"/pricing-tool"} />
        <SideDrawerItem icon={faAddressBook} textPrimary={"Contacts"} href={"/contacts"} />
        <SideDrawerItem icon={faCar} textPrimary={"Jobs"} href={"/jobs"} />
        <SideDrawerItem icon={faMap} textPrimary={"Map"} href={"/map"} />
        <SideDrawerItem icon={faCalendar} textPrimary={"Scheduling"} href={"/scheduling"} />
        <SideDrawerItem icon={faCalculator} textPrimary={"Estimates"} href={"/estimates"} />
        <SideDrawerItem icon={faMoneyBill} textPrimary={"Invoices"} href={"/invoices"} />
        <SideDrawerItem icon={faCreditCard} textPrimary={"Payments"} href={"/payments"} />
        <SideDrawerItem icon={faCarSide} textPrimary={"Products"} href={"/products"} />
        <SideDrawerItem icon={faTruck} textPrimary={"Product Sets"} href={"/product-sets"} />
        <SideDrawerItem icon={faWrench} textPrimary={"Technicians"} href={"/technicians"} />
        <SideDrawerItem icon={faClock} textPrimary={"Drive Time & Distance"} href={"/drive_time_distance"} />
        <SideDrawerItem icon={faStore} textPrimary={"Markets"} href={"/markets"} />
        <SideDrawerItem icon={faStore} textPrimary={"Parts Stores"} href={"/parts-stores"} />
        {includes(propOr("", "email", user), authorizedEmails) && (
          <SideDrawerItem icon={faUserPlus} textPrimary={"Add Ops Manager"} href={"/add/ops-manager"} />
        )}
      </List>
      {open && (
        <div className="flex flex-1 items-center justify-center">
          <Button variant={"contained"} color={"primary"} onClick={toggleFeedbackDialog}>
            Submit Feedback
          </Button>
        </div>
      )}
      <FeedbackDialog open={feedbackDialogOpen} onClose={toggleFeedbackDialog} />
    </Drawer>
  );
};

export const SideDrawerItem: React.FC<{
  textPrimary: string;
  icon: any;
  href: string;
}> = ({ textPrimary, icon, href }) => {
  const location = useLocation();
  const currentHref = location.pathname;
  const isActive = currentHref === href;
  return (
    <Link to={href}>
      <ListItem button divider selected={isActive}>
        <Tooltip title={textPrimary}>
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={textPrimary} />
      </ListItem>
    </Link>
  );
};
