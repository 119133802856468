import gql from "graphql-tag";
import { appointmentDetailsFields } from "../queries/getAppointments";

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment(
    $id: ID!
    $updateAppointmentInput: AppointmentInput!
  ) {
    updateAppointment(
      id: $id
      updateAppointmentInput: $updateAppointmentInput
    ) {
      ${appointmentDetailsFields}
    }
  }
`;
