import gql from "graphql-tag";

export const CREATE_PAYMENT = gql`
  mutation createPayment($createPaymentInput: CreatePaymentInput!) {
    createPayment(createPaymentInput: $createPaymentInput) {
      amount
      invoicePrice
      createdAt
      id
      payer
      partsCost
      laborCost
      subTotal
      partsTax
      laborTax
      totalTax
      paymentMethod
      source
      type
      tip
      updatedAt
    }
  }
`;
