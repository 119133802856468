export const adminSources = [
  "Bing",
  "Email Campaign",
  "Employee Discount",
  "Facebook",
  "GMBlisting",
  "Google Local Service Lead",
  "Google Organic",
  "Google SEM",
  "Mailer",
  "Nextdoor",
  "Pandora",
  "Radio CarProUSA - Dallas",
  "Radio ESPN 103.3 - Dallas",
  "Radio ESPN 97.5 - Houston",
  "Radio Fallback",
  "Radio KLBJ AM - Austin",
  "Radio Sports610 - Houston",
  "Radio The Fan 105.3 - Dallas",
  "Radio The Ticket 1310AM - Dallas",
  "Radio",
  "Referral",
  "Spotify",
  "Unspecified",
  "Van",
  "Yahoo",
  "Yelp",
  "YouTube",
];
