import { useDropzone } from "react-dropzone";
import { head } from "ramda";

type UseS3UploadInput = {
  contactId: string;
  onUploadStart: (img: any) => Promise<string>;
  onUploadDone: ({ url, fileName }: { url: string; fileName: string }) => void;
  onError: any;
};

export function useS3Upload({
  onUploadStart,
  onUploadDone,
  onError,
  contactId,
}: UseS3UploadInput) {
  async function handleDrop(acceptedFiles, rejectedFiles, event) {
    // Let the caller know that a file has been selected and a fetch is beginning.
    const fileToUpload = head<any>(acceptedFiles);
    const presignedUploadUrl = await onUploadStart(fileToUpload.name);
    // Upload the image to our pre-signed URL.
    const response = await fetch(
      new Request(presignedUploadUrl, {
        method: "PUT",
        body: fileToUpload,
        headers: new Headers({
          "Content-Type": "*",
        }),
      })
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      onError();
      return;
    }
    const url = `${
      process.env.REACT_APP_S3_MEDIA_URL
    }/contacts/${contactId}/${encodeURI(fileToUpload.name)}`;
    await onUploadDone({ fileName: fileToUpload.name, url });
    // Let the caller know that the upload is done, so that it can send the URL
    // to the backend again, persisting it to the database.
  }

  return useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });
}
