import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEJIPriceInfo";
import { itemsFields } from "./getEstimate";
import { partsStoreDetailsFields } from "./getPartsStore";

export const invoiceDetailsFields = `
id
invoiceNumber
mHelpId
taxable
market
laborCost
partsCost
partsTax
laborTax
totalTax
job {
id
status
}
        status
        subTotal
        activityFeed {
            id
            actor
            verb
            objectId
            createdAt
        }
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            symptoms
        }
        technician {
            id
            firstName
            lastName
            market
            isActive
        }
        contact {
            id
            firstName
            lastName
            email
        }
        amountPaid
        balanceDue
        createdAt
        updatedAt
        issuedDate
        dueDate
        ${itemsFields}
        priceInfo{
            ${ejiPriceInfoFields}
        }
        estimate {
            id
        }
        serviceLocation
        privateNotes
        customerMessage
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
            }
            updatedAt
            createdAt
        }
        priceInfo {
            discounts {
                type
                amount
                reason
                total
            }
        }`;

export const GET_INVOICE = gql`query getInvoice($id: ID!) {
    getInvoice(id: $id) {
        ${invoiceDetailsFields}
    }
}`;
