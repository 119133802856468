import gql from "graphql-tag";

export const GET_TECHNICIAN_ROLES = gql`
  query getTechnicianRoles {
    getTechnicianRoles {
      id
      name
      payMultiplier
    }
  }
`;
