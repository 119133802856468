import React from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetContactsPaginatedArgs,
} from "../../generated/nest-graphql";
import { applySpec, mergeDeepRight, pipe, prop } from "ramda";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { Column } from "react-table";
import { GET_CONTACTS_PAGINATED } from "../../graphql/queries/getContactsPaginated";
import { AddToolbarButton } from "../Table/AddToolbarButton";

type ContactsTableType = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export const ContactColumns: Column<ContactsTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/contacts/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "First Name", accessor: "firstName" },
  { Header: "Last Name", accessor: "lastName" },
  { Header: "Phone Number", accessor: "phoneNumber" },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Created At", accessor: "createdAt" },
  { Header: "Updated At", accessor: "updatedAt" },
];

export const ContactsTable: React.FC<{}> = () => {
  const limit = 50;
  const queryKey = "getContactsPaginated";
  const { data, fetchMore } = useQuery<Query, QueryGetContactsPaginatedArgs>(
    GET_CONTACTS_PAGINATED,
    {
      variables: {
        paginatedQueryInput: {
          skip: 0,
          limit,
        },
      },
    }
  );
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          skip: data.getContactsPaginated.pageInfo.offset + 50,
          limit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getContactsPaginated: {
            // @ts-ignore
            edges: [
              ...prev.getContactsPaginated.edges,
              ...fetchMoreResult.getContactsPaginated.edges,
            ],
            // @ts-ignore
            pageInfo: mergeDeepRight(
              prev.getContactsPaginated.pageInfo,
              fetchMoreResult.getContactsPaginated.pageInfo
            ),
            // @ts-ignore
            __typename: prev.getContactsPaginated.__typename,
          },
        };
      },
    });
  };
  if (data) {
    return (
      <SelectablePageableEntityTable<ContactsTableType>
        title={"Contacts"}
        queryKey={queryKey}
        loadMore={loadMore}
        queryResult={data}
        limit={limit}
        spec={contactsToContactColumnSpec}
        columns={ContactColumns}
        numRecords={data.getContactsPaginated.pageInfo.numRecords}
        endAdornments={
          <AddToolbarButton link="/add/contacts" title="Contact" />
        }
      />
    );
  }
  return null;
};
const contactsToContactColumnSpec = applySpec({
  id: prop("id"),
  firstName: prop("firstName"),
  lastName: prop("lastName"),
  phoneNumber: prop("phoneNumber"),
  createdBy: prop("createdBy"),
  createdAt: pipe(prop("createdAt"), formatDateTime),
  updatedAt: pipe(prop("updatedAt"), formatDateTime),
});
