import gql from "graphql-tag";
import { appointmentDetailsFields } from "../queries/getAppointments";
export const reschedulesQueryReturnFields = `
        jobNumber
        originalDates {
            startDate
            endDate
        }
        newDates {
            startDate
            endDate
        }
        rescheduleReason
        rescheduleFiledBy
        appointment {
            ${appointmentDetailsFields}
        }

        `;

export const CREATE_RESCHEDULE_V2 = gql`
mutation createRescheduleV2($rescheduleInput: RescheduleInputV2!) {
    createRescheduleV2(rescheduleInput: $rescheduleInput) {
        ${reschedulesQueryReturnFields}
    }
}`;
