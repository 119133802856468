import gql from "graphql-tag";
import { partsStoreDetailsFields } from "./getPartsStore";

export const itemsFields = `items {
        product
        partsStore {
            ${partsStoreDetailsFields}
        }
        name
        isInEstimate
        amount
        description
        partNumber
        laborCost
        partsCost
        vendorPartsCost
        createdAt
        updatedAt
    }`;

export const estimateDetailsFields = `id
        estimateNumber
        issuedDate
        taxable
        ${itemsFields}
        market
        price
        serviceLocation
        privateNotes
        estimateNotes
        serviceLocationNotes
        status
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
            extraInfo
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            symptoms
        }
        contact {
            id
            firstName
            lastName
            email
            timeZone
        }
        activityFeed {
            actor
            id
            objectId
            objectType
            verb
            createdAt
        }
        createdAt
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
            }
            updatedAt
            createdAt
        }
        priceInfo {
            discounts {
                type
                amount
                reason
                total
            }
        }`;

export const GET_ESTIMATE = gql`query getEstimate($id: ID!) {
    getEstimate(id: $id) {
        ${estimateDetailsFields}
    }
}
`;
