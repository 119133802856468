import React from "react";
import { DriveTimeDistanceTableRows } from "./DriveTimeDistanceTableRows";
import { DriveTimeDistanceTableHeaderCell } from "./DriveTimeDistanceTableHeaderCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    background: "#000",
    maxHeight: 840,
  },
  timeColumn: {
    background: "#000",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
    height: 60,
    padding: 8,
    border: "2px solid #a9a9a9",
  },
});

export const DriveTimeDistanceTable: React.FC<{
  technicians: any[];
}> = ({ technicians }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.timeColumn}></TableCell>
            <DriveTimeDistanceTableHeaderCell technicians={technicians} />
          </TableRow>
        </TableHead>
        <TableBody>
          <DriveTimeDistanceTableRows technicians={technicians} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
