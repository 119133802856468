import { InvoiceDetailsFormValues } from "../Invoices/InvoiceDetailsForm";
import { prop } from "ramda";
import { itemsSpec } from "./jobDetailsSpec";
import { cleanedServices } from "../Services/ProductRow";
import { cleanedDiscounts } from "../Services/ServicesReceiptSection";

export const invoiceDetailsSpec = (formData: InvoiceDetailsFormValues) => {
  const {
    contact,
    market,
    job,
    technician,
    estimate,
    taxable,
    serviceLocation,
    privateNotes,
    items,
    status,
    issuedDate,
    dueDate,
    model,
    year,
    customerMessage,
    extraInfo,
    frontPadLife,
    licensePlate,
    make,
    odometer,
    rearPadLife,
    symptoms,
    vin,
    additionalNotes,
    customerExpectation,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    services,
    discounts,
  } = formData;
  return {
    contact: prop("id", contact),
    customerMessage,
    dueDate,
    issuedDate,
    items: items && itemsSpec(items),
    privateNotes,
    taxable,
    market,
    serviceLocation,
    status,
    estimate,
    technician: prop("id", technician),
    job,
    vehicleInfo: {
      year,
      extraInfo,
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      symptoms,
      vin,
      additionalNotes,
      customerExpectation,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
    },
    services: cleanedServices(services),
    discounts: cleanedDiscounts(discounts),
  };
};
