import React from "react";
import "date-fns";
import { useField } from "formik";
import { LuxonTZUtils } from "../DatePickerUtil";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorTextField } from "./ErrorTextField";
import { DATE_TIME_FORMAT } from "../../lib/constants";

export const DateTimeInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
  postChange?: (val: Date) => void;
}> = ({ readOnly = false, name, label, required, postChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { value, ...fieldRest } = field;
  return (
    <MuiPickersUtilsProvider utils={LuxonTZUtils}>
      <KeyboardDateTimePicker
        error={!!meta.error}
        value={value instanceof Date ? value : null}
        showTodayButton
        readOnly={readOnly}
        inputVariant="outlined"
        fullWidth
        label={label}
        required={required}
        {...fieldRest}
        // @ts-ignore
        onChange={(date: DateTime) => {
          if (date) {
            helpers.setValue(date.toJSDate());
            postChange && postChange(date.toJSDate());
          }
        }}
        format={DATE_TIME_FORMAT}
        mask={"__/__/____ __:__ __"}
        refuse={/[^\d|a|p|m]+/gi}
        helperText={<ErrorTextField fieldName={field.name} displayError={!!meta.error} />}
      />
    </MuiPickersUtilsProvider>
  );
};
