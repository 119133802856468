import React from "react";
import { Container } from "../../components/Container";
import { AddEstimate } from "../../components/Estimates/AddEstimate";

const AddEstimatePage: React.FC = () => {
  return (
    <div>
      <Container>
        <AddEstimate />
      </Container>
    </div>
  );
};

export default AddEstimatePage;
