import * as React from "react";
import { TabItem } from "../TabView/TabItem";
import { InvoiceDetailsContainer } from "./InvoiceDetailsContainer";

export const InvoicesTabView: React.FC<{ invoiceId: string }> = ({
  invoiceId,
}) => {
  return (
    <div className={"mt-8"}>
      <ul className={"flex border-b"}>
        <TabItem href={`/invoices/${invoiceId}`}>Invoice</TabItem>
        <TabItem href={`/invoices/${invoiceId}?tab=payment`}>Payment</TabItem>
        <TabItem href={`/invoices/${invoiceId}?tab=files`}>Files</TabItem>
        <TabItem href={`/invoices/${invoiceId}?tab=contact`}>Contact</TabItem>
      </ul>
      <InvoiceDetailsContainer invoiceId={invoiceId} />
    </div>
  );
};
