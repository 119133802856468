import React from "react";
import { Box, Typography } from "@material-ui/core";
import CurrencyInput from "./CurrencyInput";

interface Props {
  item: string;
  standardPricingVarianceName: string;
  premiumPricingVarianceName: string;
  performancePricingVarianceName: string;
}

export const MarketPricingFieldGroup = ({
  item,
  standardPricingVarianceName,
  premiumPricingVarianceName,
  performancePricingVarianceName,
}: Props) => {
  return (
    <Box className={"col-span-4 grid grid-cols-4 gap-2 pt-2 pb-1 place-items-center bg-gray-900"}>
      <Typography>{item}</Typography>
      <CurrencyInput name={standardPricingVarianceName} label={"Increase/Decrease"} placeHolder={"-10.00"} />
      <CurrencyInput name={premiumPricingVarianceName} label={"Increase/Decrease"} placeHolder={"-10.00"} />
      <CurrencyInput name={performancePricingVarianceName} label={"Increase/Decrease"} placeHolder={"-10.00"} />
    </Box>
  );
};
