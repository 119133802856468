import * as React from "react";
import { Query, QueryGetPaymentsPaginatedArgs } from "../../generated/nest-graphql";
import { useQuery } from "@apollo/client";
import { GET_PAYMENTS_PAGINATED } from "../../graphql/queries/getPaymentsPaginated";
import { applySpec, mergeDeepRight, pipe, prop } from "ramda";
import { Column } from "react-table";
import { Container } from "../Container";
import { CurrencyCell } from "../Estimates/EstimatesTable";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";

export const ContactsPayments: React.FC<{ contactId: string }> = ({ contactId }) => {
  const limit = 50;
  const { data, fetchMore } = useQuery<Query, QueryGetPaymentsPaginatedArgs>(GET_PAYMENTS_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          contact: contactId,
        },
        limit,
        skip: 0,
      },
    },
  });
  if (!data) return null;
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          filter: {
            contact: contactId,
          },
          limit,
          skip: 0,
        },
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getPayments: {
            // @ts-ignore
            edges: [...prev.getPayments.edges, ...fetchMoreResult.getPayments.edges],
            // @ts-ignore
            pageInfo: mergeDeepRight(prev.getPayments.pageInfo, fetchMoreResult.getPayments.pageInfo),
            // @ts-ignore
            __typename: prev.getPayments.__typename,
          },
        };
      },
    });
  };
  return (
    <Container>
      <div className={"px-4"}>
        <SelectablePageableEntityTable
          title={"Payments"}
          queryResult={data}
          loadMore={() => loadMore(data.getPaymentsPaginated.pageInfo.endCursor)}
          columns={PaymentColumns}
          queryKey={"getPaymentsPaginated"}
          numRecords={data.getPaymentsPaginated.pageInfo.numRecords}
          limit={limit}
          spec={paymentsToPaymentColumns}
        />
      </div>
    </Container>
  );
};

export const PaymentColumns: Column<PaymentTableType>[] = [
  { Header: "Id", accessor: "id" },
  { Header: "Payment Number", accessor: "paymentNumber" },
  { Header: "Type", accessor: "type" },
  { Header: "Source", accessor: "source" },
  { Header: "Status", accessor: "status" },
  { Header: "Received Date", accessor: "receivedDate" },
  { Header: "Payer", accessor: "payer" },
  { Header: "Amount With Tip", accessor: "amount", Cell: CurrencyCell },
  { Header: "Invoice Price", accessor: "invoicePrice", Cell: CurrencyCell },
  { Header: "Payment Method", accessor: "paymentMethod" },
  { Header: "Tip", accessor: "tip" },
];
export type PaymentTableType = {
  id: {
    paymentId: string;
    invoiceId: string;
    contactId: string;
  };
  paymentNumber: string;
  type: string;
  source: string;
  status: string;
  receivedDate: string;
  payer: string;
  amount: string;
  invoicePrice: string;
  paymentMethod: string;
  tip: string;
};

export const paymentsToPaymentColumns = applySpec({
  id: prop("id"),
  paymentNumber: prop("paymentNumber"),
  type: prop("type"),
  source: prop("source"),
  status: prop("status"),
  receivedDate: pipe(prop("receivedDate"), formatDateTime),
  payer: prop("payer"),
  amount: prop("amount"),
  invoicePrice: prop("invoicePrice"),
  paymentMethod: prop("paymentMethod"),
  tip: prop("tip"),
});
