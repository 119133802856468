import * as React from "react";
import { Query, QueryGetPaymentsArgs } from "../../generated/nest-graphql";
import { useQuery } from "@apollo/client";
import { GET_PAYMENTS } from "../../graphql/queries/getPayments";
import { applySpec, mergeDeepRight, path, pipe, prop } from "ramda";
import { Column } from "react-table";
import { CurrencyCell } from "../Estimates/EstimatesTable";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import * as queryString from "query-string";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";

export const PaymentsTable: React.FC<{}> = () => {
  const limit = 50;
  const { data, fetchMore } = useQuery<Query, QueryGetPaymentsArgs>(GET_PAYMENTS, {
    variables: {
      limit,
    },
  });
  if (!data) return null;
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        limit,
        cursor,
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getPayments: {
            // @ts-ignore
            edges: [...prev.getPayments.edges, ...fetchMoreResult.getPayments.edges],
            // @ts-ignore
            pageInfo: mergeDeepRight(prev.getPayments.pageInfo, fetchMoreResult.getPayments.pageInfo),
            // @ts-ignore
            __typename: prev.getPayments.__typename,
          },
        };
      },
    });
  };
  return (
    <SelectablePageableEntityTable
      title={"Payments"}
      queryResult={data}
      loadMore={() => loadMore(data.getPayments.pageInfo.endCursor)}
      columns={PaymentColumns}
      queryKey={"getPayments"}
      numRecords={data.getPayments.pageInfo.numRecords}
      limit={limit}
      spec={paymentsToPaymentColumns}
    />
  );
};

export const PaymentColumns: Column<PaymentTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link
          className={"text-primary"}
          to={{
            pathname: `/invoices/${props.cell.value.invoiceId}`,
            search: queryString.stringify({ tab: "payment" }),
          }}
        >
          {props.cell.value.paymentId}
        </Link>
      );
    },
  },
  { Header: "Payment Number", accessor: "paymentNumber" },
  { Header: "Type", accessor: "type" },
  { Header: "Source", accessor: "source" },
  { Header: "Status", accessor: "status" },
  { Header: "Received Date", accessor: "receivedDate" },
  { Header: "Payer", accessor: "payer" },
  { Header: "Amount With Tip", accessor: "amount", Cell: CurrencyCell },
  { Header: "Invoice Price", accessor: "invoicePrice", Cell: CurrencyCell },
  { Header: "Payment Method", accessor: "paymentMethod" },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Tip", accessor: "tip" },
];
export type PaymentTableType = {
  id: {
    paymentId: string;
    invoiceId: string;
  };
  paymentNumber: string;
  type: string;
  source: string;
  status: string;
  receivedDate: string;
  payer: string;
  amount: string;
  invoicePrice: string;
  paymentMethod: string;
  createdBy: string;
  tip: string;
};

export const paymentsToPaymentColumns = applySpec({
  id: {
    paymentId: prop("id"),
    invoiceId: path(["invoice", "id"]),
  },
  paymentNumber: prop("paymentNumber"),
  type: prop("type"),
  source: prop("source"),
  status: prop("status"),
  receivedDate: pipe(prop("receivedDate"), formatDateTime),
  payer: prop("payer"),
  amount: prop("amount"),
  invoicePrice: prop("invoicePrice"),
  paymentMethod: prop("paymentMethod"),
  createdBy: prop("createdBy"),
  tip: prop("tip"),
});
