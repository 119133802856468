import * as React from "react";
import { useRef, useState } from "react";
import {
  Contact,
  EjiDiscount,
  EjiService,
  Mutation,
  MutationDeleteInvoiceArgs,
  PossibleEjiService,
  Query,
  Technician,
} from "../../generated/nest-graphql";
import { VehicleInfoFormSection, VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import Modal from "react-modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ItemForm, ItemFormValues } from "../Forms/ItemForm";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { anyPass, append, equals, isEmpty, isNil, keys, omit, path, pipe, prop, propOr, remove } from "ramda";
import { SelectField } from "../FormFields/SelectField";
import { ActionsSelect } from "../FormFields/ActionsSelect";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { Button } from "../Buttons/Button";
import { TextField } from "../FormFields/TextField";
import { handleNoDecimal } from "../../lib/functions";
import { DateInput } from "../FormFields/DateInput";
import { isAfter } from "date-fns";
import { isBefore } from "date-fns/fp";
import { NuModal } from "../NuModal";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { SaveDeleteCancelRow } from "../SaveDeleteCancelRow";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_INVOICE } from "../../graphql/mutations/deleteInvoice";
import { TechnicianSelect } from "../FormFields/TechnicianSelect";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { Space } from "../Space";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { usePartsStores } from "../../hooks/usePartsStores";
import ServicesSection from "../Services/ServicesSection";
import { servicesSchema } from "../../yupSchemas";

export type InvoiceDetailsFormValues = {
  contact: Contact;
  serviceLocation: string;
  taxable?: boolean;
  status: string;
  estimate?: string;
  privateNotes?: string;
  technician: Technician;
  items: ItemsTableType[];
  customerMessage?: string;
  issuedDate: Date;
  dueDate: Date;
  job?: string;
  market: string;
  serviceCatalogueUsed?: string;
  services?: PossibleEjiService[] | EjiService[];
  discounts?: EjiDiscount[];
} & VehicleInfoValues;

const invoiceValidationSchema = Yup.object().shape({
  services: servicesSchema,
});

export const InvoiceDetailsForm: React.FC<{
  invoiceId?: string;
  invoiceNumber?: string;
  initialValues: InvoiceDetailsFormValues;
  onSubmit: any;
  amountPaid: string;
  balanceDue: string;
}> = ({ initialValues, onSubmit, invoiceId, invoiceNumber, amountPaid, balanceDue }) => {
  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const partsStoresData = usePartsStores();
  Modal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { push } = useHistory();
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const [deleteInvoiceMutation] = useMutation<Mutation, MutationDeleteInvoiceArgs>(DELETE_INVOICE);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const deleteInvoice = async () => {
    await deleteInvoiceMutation({
      variables: {
        id: invoiceId,
      },
    });
  };
  return (
    <Formik<InvoiceDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={invoiceValidationSchema}
      enableReinitialize={true}
    >
      {({ setFieldValue, setValues, values, resetForm, isSubmitting, isValid }) => {
        const { dueDate, market, issuedDate, items, status } = values;
        const partsStores = anyPass([isNil, isEmpty])(market)
          ? partsStoresData
          : partsStoresData.filter((x) => equals(path(["market", "name"], x), market));
        const onDeleteItem = (idx) => () => {
          const result = remove(idx, 1, values.items);
          setFieldValue("items", result);
        };
        const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
          const selectedValue = prop("value", value);
          const valueKeys = pipe(omit(["id"]), keys)(selectedValue);
          valueKeys.forEach((key) => {
            const val = selectedValue[key];
            const keyWithPrefix = `${prefix}.${key}`;
            setFieldValue(keyWithPrefix, val ? val : "");
          });
        };
        const taxable: boolean = propOr(false, "taxable", values);
        return (
          <div ref={currentRef}>
            <Form>
              <div className="mb-4">{invoiceNumber && <div>Invoice Number: {invoiceNumber}</div>}</div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <SelectField
                  name={"status"}
                  options={["Draft", "Sent", "UnPaid", "Paid", "Partially Paid"]}
                  label={"Status"}
                />
                <div>{invoiceId && <ActionsSelect entityName={"Invoice"} entityId={invoiceId} />}</div>
                <div className="grid grid-cols-2 gap-4">
                  <DateInput
                    name={"issuedDate"}
                    label={"Issued Date"}
                    postChange={(date: Date) => {
                      if (!dueDate || isAfter(date, dueDate)) {
                        setFieldValue("dueDate", date);
                      }
                    }}
                  />
                  <DateInput
                    name={"dueDate"}
                    label={"Due Date"}
                    postChange={(date: Date) => {
                      if (!issuedDate || isBefore(issuedDate, date)) {
                        setFieldValue("issuedDate", date);
                      }
                    }}
                  />
                </div>
                <TechnicianSelect name={"technician"} label={"Technician"} market={market} />
              </div>
              <DetailViewContainer title={"Contact info"}>
                <div className={"grid grid-cols-3 gap-4"}>
                  <ContactsSelect name={"contact"} label={"Contact"} required={true} />
                  <div>
                    <LocationSearchInput name={"serviceLocation"} label={"Service Location"} required={true} />
                  </div>
                  <MarketFieldSelect name={"market"} required={true} />
                </div>
              </DetailViewContainer>
              {values?.serviceCatalogueUsed === "Services" ? (
                <DetailViewContainer title={"Services"}>
                  {!market || market === "ZipUnknown" || market === "" ? (
                    "Please select a market first."
                  ) : (
                    <ServicesSection
                      showTable={values?.services?.length > 0}
                      partsStores={partsStores}
                      values={values}
                      setValues={setValues}
                      ejiType={"INVOICE"}
                    />
                  )}
                </DetailViewContainer>
              ) : (
                <DetailViewContainer title={"Items"}>
                  <div className="flex flex-row justify-start mb-4">
                    <div>
                      <Button onClick={toggleModal} type={"button"}>
                        + Add Item
                      </Button>
                    </div>
                  </div>
                  {!loading && data.getProducts && partsStores && (
                    <ItemsTable
                      products={data.getProducts}
                      items={items}
                      onDeleteItem={onDeleteItem}
                      postItemSelect={postSelectProduct}
                      partsStores={partsStores}
                    />
                  )}{" "}
                  <ItemsReceiptSection
                    items={items as ItemFormValues[]}
                    market={market}
                    amountPaid={amountPaid}
                    balanceDue={balanceDue}
                    taxable={taxable}
                  />
                </DetailViewContainer>
              )}
              <DetailViewContainer title={"Invoice Info"}>
                <div className={"grid grid-cols-2 gap-4"}>
                  <TextField
                    name={"privateNotes"}
                    label={"Private Notes"}
                    multiline={true}
                    rows={4}
                    helperText="Internal notes used by ops and technicians."
                  />
                  <TextField name={"customerMessage"} label={"Customer Message"} multiline={true} rows={4} />
                </div>
              </DetailViewContainer>
              <VehicleInfoFormSection />
              <Space />
              <SaveDeleteCancelRow
                onDelete={
                  invoiceId && status === "Draft"
                    ? async () => {
                        await deleteInvoice();
                        push("/invoices/");
                      }
                    : null
                }
                width={width}
                offsetLeft={left}
                isValid={isValid}
                isSubmitting={isSubmitting}
                onCancel={() => resetForm(initialValues)}
              />
              <NuModal isOpen={modalIsOpen} title="Add Item">
                {!loading && data.getProducts && (
                  <ItemForm
                    products={data.getProducts}
                    onSubmit={async (itemFormValues) => {
                      const oldItems: ItemsTableType[] = items;
                      const {
                        product,
                        name,
                        description,
                        partNumber,
                        partsCost,
                        isInEstimate,
                        laborCost,
                        amount,
                        partsStore,
                      } = itemFormValues;
                      const cleanedItemFormValues: ItemFormValues = {
                        amount: handleNoDecimal(amount),
                        laborCost: handleNoDecimal(laborCost),
                        partsCost: handleNoDecimal(partsCost),
                        isInEstimate,
                        partNumber,
                        description,
                        name,
                        product,
                        partsStore,
                      };
                      const result = append(cleanedItemFormValues, oldItems as any[]);
                      setFieldValue("items", result, false);
                      toggleModal();
                    }}
                    onCancel={toggleModal}
                  />
                )}{" "}
              </NuModal>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
