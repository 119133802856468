import React from "react";
import { prop } from "ramda";
import { EstimatesTabView } from "../../components/Estimates/EstimatesTabView";
import { useParams } from "react-router-dom";

const EstimatesDetailPage = () => {
  const params = useParams();
  return (
    <div>
      <EstimatesTabView
        // @ts-ignore
        estimateId={prop<any>("id", params)}
      />
    </div>
  );
};

export default EstimatesDetailPage;
