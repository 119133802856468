import gql from "graphql-tag";
import { contactListFields } from "./getContactsPaginated";

export const SEARCH_QUERY = gql`
    query Search($query: String!) {
        search(query: $query) {
            contacts {
                ${contactListFields}
            }
        }
    }
`;
