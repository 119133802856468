import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTimezones } from "../../hooks/useTimezones";
import { useField } from "formik";
import { ErrorTextField } from "./ErrorTextField";
import { path } from "ramda";

const TimeZoneSelect: React.FC<{
  name: string;
  label: string;
}> = ({ label, name }) => {
  const [field, meta, helper] = useField(name);
  const timezones = useTimezones();
  const timezoneName = field.value || "America/Chicago";
  const optionRef = React.createRef();

  const defaultValue: string | undefined = React.useMemo(() => {
    if (timezoneName === undefined) {
      return undefined;
    }
    const matchingOption = timezones.find((option: any) => option.value === timezoneName);
    return matchingOption ? matchingOption.value : undefined;
  }, [timezones, timezoneName]);

  return (
    <FormControl fullWidth variant="outlined" error={!!meta.error}>
      <InputLabel> {label} </InputLabel>
      <Select
        ref={optionRef}
        label={label}
        name={name}
        {...field}
        onChange={(event) => {
          // @ts-ignore
          helper.setValue(path<string>(["target", "value"], event));
        }}
        defaultValue={defaultValue}
        multiple={false}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {timezones.map((val: any, i) => (
          <MenuItem key={val.label} value={val.value}>
            {val.label}
          </MenuItem>
        ))}
      </Select>
      {meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}
    </FormControl>
  );
};

export default TimeZoneSelect;
