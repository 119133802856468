import * as React from "react";
import { useEffect } from "react";
import { DateInput } from "../FormFields/DateInput";
import CurrencyInput from "../FormFields/CurrencyInput";
import { TextField } from "../FormFields/TextField";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { useFormikContext } from "formik";
import { AddPaymentFormValues } from "./AddPaymentForm";

export const CashAndCheckFields: React.FC<{ payer: string }> = ({ payer }) => {
  const { isSubmitting, isValid, resetForm, setFieldValue } = useFormikContext<
    AddPaymentFormValues
  >();
  useEffect(() => {
    setFieldValue("payer", payer);
  }, [payer, setFieldValue]);
  return (
    <>
      <DateInput
        name={"receivedDate"}
        label={"Received Date"}
        required={true}
      />
      <CurrencyInput name={"amount"} label={"Amount"} required={true} />
      <TextField name={"refNumber"} label={"Ref #/Check #"} />
      <TextField name={"memo"} label={"Memo"} />
      <CurrencyInput name={"tip"} label={"Tip"} />
      <CheckBoxField name={"sendReceipt"} label={"Send Receipt?"} />
      <div className="flex flex-row justify-between">
        <div>
          <CancelButton type={"button"} onClick={() => resetForm()}>
            Cancel
          </CancelButton>
        </div>
        <div>
          <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
            Submit
          </SubmitButton>
        </div>
      </div>
    </>
  );
};
