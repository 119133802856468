import React from "react";
import {
  ProductOptionType,
  ProductSelectTypeAhead,
} from "./ProductSelectTypeHead";
import { Product } from "../../generated/nest-graphql";
type ItemFormProductSelectProps = {
  postSelect: (value: ProductOptionType) => void;
  name: string;
  label: string;
  required: boolean;
  products: Product[];
};
export const ItemFormProductSelect: React.FC<ItemFormProductSelectProps> = ({
  name,
  label,
  postSelect,
  required,
  products,
}) => {
  return (
    <ProductSelectTypeAhead
      required={required}
      name={name}
      label={label}
      postOnChange={postSelect}
      products={products}
    />
  );
};
