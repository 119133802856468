import gql from "graphql-tag";
import { marketFields } from "../queries/getMarket";

export const UPDATE_MARKET = gql`
  mutation updateMarket($id: ID!, $updateMarketInput: UpdateMarketInput!) {
    updateMarket(id: $id, updateMarketInput: $updateMarketInput) {
      ${marketFields}
    }
  }
`;
