import React from "react";
import { Summary } from "../components/Cards/Summary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Container } from "../components/Container";

function Home() {
  return (
    <Container className={"pt-8 ml-6"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h4"}>Home</Typography>
        </Grid>
        <Grid item xs={12}>
          <Summary />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
