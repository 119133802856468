import gql from "graphql-tag";

export const contactListFields = `
            id
            firstName
            lastName
            email
            phoneNumber
            timeZone
            createdBy
            createdAt
            updatedAt
            lead {
            address
            vehicleInfo {
                extraInfo
                make
                model
                year
                symptoms
            }
        }
`;

export const GET_CONTACTS_PAGINATED = gql`query getContactsPaginated($paginatedQueryInput: PaginatedQueryInput!) {
    getContactsPaginated(paginatedQueryInput: $paginatedQueryInput) {
        edges {
            ${contactListFields}
        }
        pageInfo {
            hasNextPage
            numRecords
            offset
        }
    }
}`;
