import { applySpec, prop } from "ramda";

export const contactDetailsSpec = (formData) =>
  applySpec({
    firstName: prop("firstName"),
    lastName: prop("lastName"),
    email: prop("email"),
    phoneNumber: prop("phoneNumber"),
    timeZone: prop("timeZone"),
    outreachMedium: prop("outreachMedium"),
    lead: {
      zip: prop("zip"),
      address: prop("address"),
      hearAboutUs: prop("hearAboutUs"),
      market: prop("market"),
      source: prop("source"),
      vehicleInfo: {
        frontPadLife: prop("frontPadLife"),
        licensePlate: prop("licensePlate"),
        make: prop("make"),
        model: prop("model"),
        odometer: prop("odometer"),
        rearPadLife: prop("rearPadLife"),
        vin: prop("vin"),
        year: prop("year"),
        extraInfo: prop("extraInfo"),
        symptoms: prop("symptoms"),
        customerExpectation: prop("customerExpectation"),
        additionalNotes: prop("additionalNotes"),
        frontBrakeSymptoms: prop("frontBrakeSymptoms"),
        rearBrakeSymptoms: prop("rearBrakeSymptoms"),
      },
    },
  })(formData);
