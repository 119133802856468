import React from "react";
import { ContactDetailsForm } from "../../components/Forms/ContactDetailsForm";
import { Container } from "../../components/Container";
import { cleanObject } from "../../lib/functions";
import { keys, pipe } from "ramda";
import { contactDetailsSpec } from "../../components/Contacts/contactDetailsFormHelpers";
import { useMutation } from "@apollo/client";
import { Mutation, MutationPrivateCreateLeadArgs } from "../../generated/nest-graphql";
import { CREATE_LEAD } from "../../graphql/mutations/createLead";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";

const AddContactPage: React.FC = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [privateCreateLead] = useMutation<Mutation, MutationPrivateCreateLeadArgs>(CREATE_LEAD);
  const onSubmit = async (values, formikHelpers) => {
    // @ts-ignore
    const updates = pipe(
      contactDetailsSpec,
      // @ts-ignore
      cleanObject
    )(values);
    if (keys(updates).length) {
      const { data } = await privateCreateLead({
        variables: {
          // @ts-ignore
          createLeadInput: updates,
        },
      });
      const id = data.privateCreateLead.id;
      showSuccess({ message: "Successfully Added Contact" });
      await history.push(`/contacts/${id}`);
    }
  };
  return (
    <div>
      <Container>
        <div className="mt-16" />
        <div>Add Contacts Page</div>
        <ContactDetailsForm
          initialValues={{
            zip: "",
            phoneNumber: "",
            market: "",
            make: "",
            outreachMedium: "Inbound Call",
            hearAboutUs: "",
            lastName: "",
            firstName: "",
            email: "",
            address: "",
            extraInfo: "",
            model: "",
            source: "",
            symptoms: "",
            year: "",
            additionalNotes: "",
            customerExpectation: "",
            timeZone: "",
            frontBrakeSymptoms: [],
            rearBrakeSymptoms: [],
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </div>
  );
};

export default AddContactPage;
