import * as React from "react";
import { InvoicesTable } from "../../components/Invoices/InvoicesTable";
import { InvoicesTableFilterForm } from "../../components/Invoices/InvoicesTableFilter";
import { useQuery } from "@apollo/client";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { applySpec, defaultTo, find, path, pathOr, pipe, prop, propEq, propOr } from "ramda";
import { Box } from "@material-ui/core";
import { useInvoicesFilter, InvoicesFilterState } from "../../contexts/invoices-filter-context";

const InvoicesPage = () => {
  const { state, updateInvoicesFilter } = useInvoicesFilter();
  const { data } = useQuery(GET_TECHNICIANS);
  if (!data) return null;
  const filters = {
    technician: propOr(null, "technician", state),
    market: propOr(null, "market", state),
    status: propOr(null, "status", state),
    issuedDate:
      state.issuedDateStartDate || state.issuedDateEndDate
        ? {
            $gte: propOr(null, "issuedDateStartDate", state),
            $lte: propOr(null, "issuedDateEndDate", state),
          }
        : null,
  };
  const techToPassIntoForm = pipe(
    find(propEq("id", prop("technician", filters))),
    defaultTo(null)
  )(data.getTechnicians);
  // @ts-ignore
  const filtersToValuesSpec: InvoicesFilterState = applySpec({
    technician: (...args) => techToPassIntoForm,
    issuedDateStartDate: path(["issuedDate", "$gte"]),
    issuedDateEndDate: path(["issuedDate", "$lte"]),
    status: prop("status"),
    market: prop("market"),
  })(filters);
  return (
    <div className="px-8 py-4 overflow-hidden">
      <div className="overflow-auto">
        <Box marginBottom={5} />
        <InvoicesTableFilterForm
          initialValues={filtersToValuesSpec}
          onSubmit={(values: InvoicesFilterState) => {
            updateInvoicesFilter({
              ...values,
              technician: pathOr(null, ["technician", "id"], values),
            });
          }}
        />
        <Box marginBottom={5} />
        <InvoicesTable filters={filters} />
      </div>
    </div>
  );
};

export default InvoicesPage;
