import React from "react";
import { PricingToolForm } from "../components/Forms/PricingToolForm";

interface Props {}

export const PricingToolPage = (props: Props) => {
  return (
    <div className="px-2">
      <PricingToolForm />
    </div>
  );
};
