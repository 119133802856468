import React from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetTechniciansArgs, Technician } from "../../generated/nest-graphql";
import { useField } from "formik";
import { defaultTo, equals, map, pipe, prop, propOr } from "ramda";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";

type TechnicianOptionType = {
  label: string | null | undefined;
  value: Technician | null | undefined;
};
export const TechnicianSelect: React.FC<{
  name: string;
  market: string | undefined;
  label: string;
  required?: boolean;
  postOnChange?: any;
}> = ({ name, required, label, postOnChange, market }) => {
  const marketFilter = equals("", market) ? { $exists: true } : market;
  const [field, meta, helpers] = useField(name);
  const { data } = useQuery<Query, QueryGetTechniciansArgs>(GET_TECHNICIANS, {
    variables: {
      filter: {
        isActive: true,
        market: marketFilter,
      },
    },
  });
  const technicianToOptions = (technician: Technician) => {
    const { firstName, lastName } = technician;
    return {
      label: `${firstName} ${lastName}`,
      value: technician,
    };
  };

  const technicianOptions = pipe(propOr([], "getTechnicians"), map(technicianToOptions))(data);
  const filterOptions = createFilterOptions<TechnicianOptionType>({
    matchFrom: "start",
    stringify: (option) => {
      return option.label!;
    },
  });
  const onChange = (_: object, value: any) => {
    helpers.setValue(prop("value", value));
    postOnChange && postOnChange(value);
  };
  if (!data || technicianOptions.length === 0) return null;
  return (
    <Autocomplete<TechnicianOptionType>
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return defaultTo("", option.label);
      }}
      value={field.value ? technicianToOptions(field.value) : { value: null, label: "" }}
      options={technicianOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
        />
      )}
    />
  );
};
