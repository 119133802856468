import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f04e23",
    },
    action: {
      disabled: "white",
      disabledBackground: "rgba(255, 65, 17, 0.49)",
    },
  },
});

export const SubmitButton: React.FC<{
  isSubmitting: boolean;
  isValid: boolean;
}> = ({ isSubmitting, isValid, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        style={{ fontWeight: "bolder" }}
        color={"primary"}
        variant={"contained"}
        size={"large"}
        disabled={isSubmitting || !isValid}
        type={"submit"}
        id={"submit-btn"}
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        ) : (
          children
        )}
      </Button>
    </ThemeProvider>
  );
};
