import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Mutation, MutationHoldStripeCardArgs } from "../../generated/nest-graphql";
import { HOLD_STRIPE_CARD } from "../../graphql/mutations/holdStripeCard";
import { equals, find, prop, propEq } from "ramda";
import Typography from "@material-ui/core/Typography";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { Button } from "../Buttons/Button";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { cleanCurrency } from "../../lib/functions";
import { StripeCardDisplay } from "./JobStripeCardDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import Radio from "@material-ui/core/Radio";

export const JobStripeCardCapture: React.FC<{
  jobId: string;
  contactId: string;
  total: number;
  paymentMethods: any[];
  hasAppointment: boolean;
  refetch: any;
  noHeldCards?: boolean;
  title?: string;
}> = ({
  jobId,
  contactId,
  total,
  paymentMethods,
  hasAppointment,
  refetch,
  noHeldCards = true,
  title = "Hold Credit Card",
}) => {
  const [paymentToUse, setPaymentToUse] = useState(find(propEq("default", true))(paymentMethods));
  const [holdStripeCard, { loading }] = useMutation<Mutation, MutationHoldStripeCardArgs>(HOLD_STRIPE_CARD);
  const showSuccess = useShowSuccess();
  const onSubmit = async () => {
    await holdStripeCard({
      variables: {
        createPaymentInputHoldCard: {
          job: jobId,
          status: "on hold",
          contact: contactId,
          // @ts-ignore
          paymentMethod: prop("id", paymentToUse),
          // @ts-ignore
          amount: cleanCurrency(String(total)),
        },
      },
    });
    await refetch({
      variables: {
        filter: {
          job: jobId,
          status: "on hold",
        },
      },
    });
    showSuccess({ message: "Successfully Captured Card Amount" });
  };
  const cleanedAmount = cleanCurrency(String(total));
  return (
    <>
      {noHeldCards && (
        <Typography variant="h6" display="block" gutterBottom>
          No Payments on hold
        </Typography>
      )}
      {hasAppointment ? (
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <DetailViewContainer title={title}>
              <div className="grid grid-cols-1 gap-1">
                <Typography variant="h6" display="block" gutterBottom>
                  Total Amount: {cleanedAmount}
                </Typography>
                <Typography variant="h6" display="block" gutterBottom>
                  Credit Card To Use
                </Typography>
                <div className="grid grid-cols-4 gap-2 mb-2">
                  {paymentMethods.map((paymentMethod, index) => (
                    <StripeCardSelectOption
                      selected={paymentToUse}
                      setSelected={setPaymentToUse}
                      key={index}
                      paymentMethod={paymentMethod}
                    />
                  ))}
                </div>
                <Button disabled={Number(total) === 0} onClick={onSubmit}>
                  {loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : "Hold Payment"}
                </Button>
              </div>
            </DetailViewContainer>
          </div>
        </div>
      ) : (
        <Typography variant="h6" display="block" gutterBottom>
          Job Requires an Appointment to Hold Card
        </Typography>
      )}
    </>
  );
};

const StripeCardSelectOption: React.FC<{
  paymentMethod: any;
  selected: any;
  setSelected: (val: any) => void;
}> = ({ paymentMethod, selected, setSelected }) => {
  const checked = equals(paymentMethod.id, selected.id);
  const card = prop("card", paymentMethod);
  const onChange = (_: any) => {
    setSelected(paymentMethod);
  };
  return (
    <div className={checked ? "border border-solid rounded border-red-500" : ""}>
      <Radio checked={checked} onChange={onChange} />
      <StripeCardDisplay
        brand={prop("brand", card)}
        country={prop("country", card)}
        expiration={`${prop("exp_month", card)}/${prop("exp_year", card)}`}
        last4={prop("last4", card)}
      />
    </div>
  );
};
