import React, { useState } from "react";
import { TableCell, TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import { AppointmentSuggestion, GetAppointmentSuggestionsInput } from "../../generated/nest-graphql";
import { ApolloError } from "@apollo/client";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DateTime } from "luxon";

type AppointmentOptionTypes = {
  suggestions: AppointmentSuggestion[];
  error?: ApolloError;
  called?: boolean;
  loading?: boolean;
  onClick?: any;
  suggestionToolInputs: { getAppointmentSuggestionsInput: GetAppointmentSuggestionsInput };
};

type TablePropTypes = {
  suggestions?: AppointmentSuggestion[];
  onClick: any;
};

const columns = [
  { label: "Technician", id: "technician", sortable: true },
  { label: "Date", id: "date", sortable: true },
  { label: "Start Time", id: "startTime", sortable: true },
  { label: "Score", id: "score", sortable: true },
  { label: "Drive Time To", id: "driveTimeTo", sortable: true },
  { label: "Drive Time From", id: "driveTimeFrom", sortable: true },
  { label: "Action", id: "action" },
];

export const AppointmentOptions = ({
  suggestions = [],
  error,
  called,
  loading,
  onClick,
  suggestionToolInputs,
}: AppointmentOptionTypes) => {
  let showTable = suggestions.length > 0;
  return (
    <div className="my-8 flex flex-col items-center">
      {showTable && <AppointmentOptionsTable suggestions={suggestions} onClick={onClick} />}
      <div className="w-4/5 text-center">
        {error && (
          <div className="mb-4">
            {error.message && error.message === "OutOfServiceAreaError"
              ? "Address is outside of a serviceable market, please try a different address"
              : "An error occurred with your appointment suggestion request. Try again, and if this continues please post to #application-bugs. Sorry about that!"}
          </div>
        )}
        {called && !loading && !showTable && !error && (
          <div className="mb-4">{`No available times for ${
            DateTime.fromJSDate(suggestionToolInputs?.getAppointmentSuggestionsInput?.startTime).toLocaleString() ??
            "the requested date"
          }, please try another.`}</div>
        )}
        {!called && (
          <>
            <div className="mb-4">
              Get appointment suggestions to find the most optimal time and technician for this job.
            </div>
            <div>
              Suggestions are meant to help reduce drive time, fit more jobs in the day, and allocate jobs equally among
              our technicians.
            </div>
          </>
        )}
      </div>
      {called && loading && <LinearProgress className="w-4/5 h-2" />}
    </div>
  );
};

const AppointmentOptionsTable = ({ suggestions = [], onClick }: TablePropTypes) => {
  const [showCount, setShowCount] = useState(15);

  const showMore = () => {
    if (showCount < suggestions.length) {
      setShowCount(showCount + 15);
    }
  };
  return (
    <Paper className="flex flex-col items-center">
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell id={column.id} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {suggestions.slice(0, showCount).map((suggestion, idx) => (
              <TableRow key={idx}>
                <TableCell>{`${suggestion.technician.firstName} ${suggestion.technician.lastName}`}</TableCell>
                <TableCell>
                  {DateTime.fromISO(suggestion.startDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(suggestion.timeWindow?.startTimeWindow).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET
                  )}
                </TableCell>
                <TableCell>{suggestion.score}</TableCell>
                <TableCell>{suggestion.driveTimes.arrival.readableDurationWithTraffic}</TableCell>
                <TableCell>{suggestion.driveTimes.departure.readableDurationWithTraffic}</TableCell>
                <TableCell>
                  <Button onClick={() => onClick(suggestion)}>Use</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showCount < suggestions.length && <Button onClick={() => showMore()}>view more</Button>}
    </Paper>
  );
};
