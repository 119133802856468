import * as React from "react";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateInvoiceArgs } from "../../generated/nest-graphql";
import { pipe } from "ramda";
import { CREATE_INVOICE } from "../../graphql/mutations/createInvoice";
import { InvoiceDetailsForm, InvoiceDetailsFormValues } from "./InvoiceDetailsForm";
import { cleanObject } from "../../lib/functions";
import { invoiceDetailsSpec } from "../specs/invoiceDetailsSpec";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";

export const AddInvoice: React.FC<{
  initialValues?: InvoiceDetailsFormValues;
}> = ({ initialValues }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [creatInvoice] = useMutation<Mutation, MutationCreateInvoiceArgs>(CREATE_INVOICE);
  const onSubmit = async (values: InvoiceDetailsFormValues, helpers) => {
    const input = pipe(invoiceDetailsSpec, cleanObject)(values);
    const result = await creatInvoice({
      variables: {
        // @ts-ignore
        createInvoiceInput: input,
      },
    });
    const id = result.data.createInvoice.id;
    showSuccess({ message: "Successfully Added AddInvoice" });
    await history.push(`/invoices/${id}`);
  };
  return (
    <InvoiceDetailsForm
      initialValues={
        initialValues
          ? initialValues
          : {
              status: "Draft",
              contact: null,
              market: "",
              technician: null,
              serviceLocation: "",
              items: [],
              taxable: true,
              issuedDate: new Date(),
              dueDate: new Date(),
            }
      }
      amountPaid={"0.00"}
      balanceDue={"0.00"}
      onSubmit={onSubmit}
    />
  );
};
