import React, { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { default as ProductErrorIcon } from "@material-ui/icons/NewReleases";
import { TableRow, TableCell, Collapse } from "@material-ui/core";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";
import ProductsExpansionSection from "./ProductsExpansionSection";
import { Field } from "formik";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import { isNil, has } from "ramda";
import { pipe } from "fp-ts/lib/function";
import { serviceRowStyles } from "../../material-ui-styles";
import { cleanedServices } from "./ProductRow";
import { cleanedDiscounts } from "./ServicesReceiptSection";

type ServiceTableRowProps = {
  row: any;
  serviceIdx: number;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
};

const ServiceRow = ({ row, serviceIdx, partsStores, ejiType, calculatePossibleEJIPriceInfo }: ServiceTableRowProps) => {
  const classes = serviceRowStyles();
  const [expanded, setExpanded] = useState(false);
  return (
    <Field name={`services[${serviceIdx}]`}>
      {({ field: { value }, meta: { error }, form: { values } }) => {
        return (
          <Fragment>
            <TableRow className={classes.serviceRowBottomBorders}>
              <TableCell>
                {ejiType !== "ESTIMATE" ? (
                  <div>{value.inEstimate ? "Y" : "N"}</div>
                ) : (
                  <CheckBoxField
                    name={`services[${serviceIdx}].inEstimate`}
                    postOnChange={(checked) => {
                      let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      servicesCopy[serviceIdx].inEstimate = checked;
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            services: cleanedServices(servicesCopy),
                            discounts: cleanedDiscounts(values.discounts) ?? [],
                            marketName: values.market,
                            taxable: values.taxable,
                            calculateAllServices: false, //always an Estimate here
                          },
                        },
                      });
                    }}
                  />
                )}
              </TableCell>
              <TableCell padding={"none"}>
                {/*Only show error icon if there is an error at the product level by checking for the products key*/}
                <ProductErrorIcon color={"primary"} className={`${!has("items", error) && "invisible"}`} />
              </TableCell>
              <TableCell>
                <DebouncedTextField name={`services[${serviceIdx}].name`} required />
              </TableCell>
              <TableCell style={{ fontSize: "1rem" }}>
                {isNil(row.customerPrice) ? "-- --" : row.customerPrice}
              </TableCell>
              <TableCell>
                <Field name={`services[${serviceIdx}].technicianPay`}>
                  {({ field: { value, name }, meta: { error }, form: { setFieldValue } }) => (
                    <DebouncedCurrencyInputV2
                      name={name}
                      label=""
                      value={value}
                      required
                      error={error}
                      onChange={(val) => setFieldValue(name, val)}
                    />
                  )}
                </Field>
              </TableCell>
              <TableCell>
                <div className="px-4">
                  <Field name={`services`}>
                    {({ form: { values } }) => (
                      <IconButton
                        onClick={() => {
                          const serviceRemoved = values.services.filter((_, i) => serviceIdx !== i);
                          calculatePossibleEJIPriceInfo({
                            variables: {
                              calculatePossibleEJIPriceInfoInput: {
                                services: cleanedServices(serviceRemoved),
                                discounts: cleanedDiscounts(values.discounts) ?? [],
                                marketName: values.market,
                                taxable: values.taxable,
                                calculateAllServices: ejiType === "INVOICE",
                              },
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Field>
                </div>
              </TableCell>
              <TableCell>
                <div className="px-4">
                  <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
                colSpan={7}
              >
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <ProductsExpansionSection
                    serviceIdx={serviceIdx}
                    partsStores={partsStores}
                    ejiType={ejiType}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      }}
    </Field>
  );
};

export default ServiceRow;
