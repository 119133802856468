import { Form, Formik } from "formik";
import React from "react";
import { SearchField } from "../FormFields/SearchField";
import { useHistory } from "react-router-dom";

type SearchFormValues = {
  text: string;
};

export const SearchForm: React.FC = () => {
  const router = useHistory();
  return (
    <Formik<SearchFormValues>
      initialValues={{ text: "" }}
      onSubmit={async (values) => {
        await router.push(`/search?q=${encodeURI(values.text)}`);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <SearchField name={"text"} placeholder={"Search Records"} />
        </Form>
      )}
    </Formik>
  );
};
