import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { cond, gt, lt, always, T, __, prop, omit } from "ramda";
import { Field } from "formik";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";
import { PartsStoreSelectField } from "../FormFields/PartsStoreSelectField";
import { TableRow, TableCell, TextField, InputAdornment } from "@material-ui/core";
import { EjiItem, PossibleEjiService, PossibleEjiServiceInput } from "../../generated/nest-graphql";
import { cleanedDiscounts } from "./ServicesReceiptSection";
import { pipe } from "fp-ts/lib/function";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  numberInput: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const maxUnits = 5;

type ProductRowProps = {
  product: EjiItem;
  prodIdx: number;
  serviceIdx: number;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
};

const ProductRow = ({
  product,
  prodIdx,
  serviceIdx,
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
}: ProductRowProps) => {
  const classes = useStyles();
  const fitToUnitsRange = cond([
    [gt(__, maxUnits), always(maxUnits)],
    [lt(__, 0), always(0)],
    [T, (unitsInput) => Number(unitsInput)],
  ]);

  const DisabledField = () => <div className="pl-4">--</div>;
  return (
    <TableRow key={product.id}>
      <TableCell style={{ fontSize: "1rem" }}>{product.type}</TableCell>
      <TableCell>
        {product.category === "Labor" ? (
          <DisabledField />
        ) : (
          <DebouncedTextField
            name={`services[${serviceIdx}].items[${prodIdx}].productSelection.name`}
            required={product.category === "Part"}
          />
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: "3px" }}>
        {product.category !== "Fee" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].units`}>
            {({ field: { value }, meta: { error }, form: { values } }) => {
              const endAdornment = (
                <InputAdornment position="end" className="text-xs pr-0">
                  {product.category === "Labor" ? "hrs" : "qty"}
                </InputAdornment>
              );
              return (
                <TextField
                  value={value}
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => {
                    const newUnits = fitToUnitsRange(Number(e.target.value));
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].units = newUnits;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: cleanedServices(servicesCopy, {
                            serviceIdx: serviceIdx,
                            prodIdx: prodIdx,
                            overridden: false,
                          }),
                          discounts: cleanedDiscounts(values.discounts) ?? [],
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  error={error}
                  type="number"
                  className={classes.numberInput}
                  InputProps={{
                    inputProps: { min: 0, max: maxUnits, step: "any" },
                    endAdornment: endAdornment,
                  }}
                />
              );
            }}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        <Field name={`services[${serviceIdx}].items[${prodIdx}].customerPrice`}>
          {({ field: { value, name }, meta: { error }, form: { values, setFieldValue } }) => {
            const markupText = values.services[serviceIdx].items[prodIdx].marketVendorUnitCostMultiplierUsed
              ? `${values.services[serviceIdx].items[prodIdx].marketVendorUnitCostMultiplierUsed}x`
              : "";
            const endAdornment = (
              <InputAdornment position="end" className="text-xs pr-0">
                {markupText}
              </InputAdornment>
            );
            return (
              <Fragment>
                <DebouncedCurrencyInputV2
                  name={name}
                  value={value}
                  error={error}
                  onChange={(newCustomerPrice, values) => {
                    setFieldValue(name, newCustomerPrice);
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].customerPrice = newCustomerPrice;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: cleanedServices(
                            servicesCopy,
                            newCustomerPrice !== null
                              ? { serviceIdx: serviceIdx, prodIdx: prodIdx, overridden: true }
                              : null
                          ),
                          discounts: cleanedDiscounts(values.discounts) ?? [],
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  label=""
                  endAdornment={endAdornment}
                />
              </Fragment>
            );
          }}
        </Field>
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].vendorUnitCost`}>
            {({ field: { value, name }, meta: { error } }) => {
              return (
                <DebouncedCurrencyInputV2
                  name={name}
                  value={value}
                  error={error}
                  onChange={(newVendorUnitCost, values) => {
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[prodIdx].vendorUnitCost = newVendorUnitCost;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: cleanedServices(servicesCopy, {
                            serviceIdx: serviceIdx,
                            prodIdx: prodIdx,
                            overridden: false,
                          }),
                          discounts: cleanedDiscounts(values.discounts) ?? [],
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  label=""
                />
              );
            }}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <DebouncedTextField name={`services[${serviceIdx}].items[${prodIdx}].orderItem.partNumber`} label="" />
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        {product.category === "Part" ? (
          <Field name={`services[${serviceIdx}].items[${prodIdx}].orderItem.partsStore`}>
            {({ field: { name, value }, meta: { error }, form: { setFieldValue } }: any) => (
              <PartsStoreSelectField
                name={name}
                label={""}
                value={value}
                required={false}
                onChange={(_: any, newValue: any) => {
                  setFieldValue(name, prop("value", newValue));
                }}
                error={error}
                options={partsStores}
              />
            )}
          </Field>
        ) : (
          <DisabledField />
        )}
      </TableCell>
      <TableCell>
        <DebouncedTextField name={`services[${serviceIdx}].items[${prodIdx}].notes`} label="" />
      </TableCell>
      <TableCell>
        <Field name={`services[${serviceIdx}].items`}>
          {({ form: { values } }) => (
            <IconButton
              onClick={() => {
                let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                servicesCopy[serviceIdx].items = servicesCopy[serviceIdx].items.filter((_, idx) => idx !== prodIdx);
                calculatePossibleEJIPriceInfo({
                  variables: {
                    calculatePossibleEJIPriceInfoInput: {
                      services: cleanedServices(servicesCopy),
                      discounts: cleanedDiscounts(values.discounts) ?? [],
                      marketName: values.market,
                      taxable: values.taxable,
                      calculateAllServices: ejiType === "INVOICE",
                    },
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Field>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;

export const cleanedServices = (
  newServices: PossibleEjiService[],
  customerPriceEdited?: { serviceIdx: number; prodIdx: number; overridden: boolean }
): PossibleEjiServiceInput[] =>
  newServices?.map((targetService, serviceIdx) => {
    const { __typename, customerPrice, technicianPay, service, id, updatedAt, createdAt, ...rest } = targetService;
    const cleanedItems = rest?.items?.map((item, prodIdx) => {
      const {
        __typename,
        marketVendorUnitCostMultiplierUsed,
        overriddenCustomerPrice,
        productSelection,
        serviceProductId,
        id,
        orderItem,
        ...restItem
      } = item;
      const { __typename: _, ...restProductSelection } = productSelection;
      const cleanedOrderItem = omit(["__typename"], orderItem);
      return {
        ...restItem,
        overriddenCustomerPrice:
          customerPriceEdited !== undefined &&
          customerPriceEdited !== null &&
          customerPriceEdited.serviceIdx === serviceIdx &&
          customerPriceEdited.prodIdx === prodIdx
            ? customerPriceEdited.overridden
            : overriddenCustomerPrice,
        productSelection: restProductSelection,
        serviceProductId: serviceProductId ?? id,
        orderItem: { ...cleanedOrderItem, partsStore: cleanedOrderItem?.partsStore?.id },
      };
    });
    return { ...rest, technicianPay: technicianPay ?? "", items: cleanedItems, service: service ?? id };
  });
