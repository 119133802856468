import * as React from "react";
import { useEffect, useRef } from "react";
import { VehicleInfoFormSection, VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import {
  Appointment,
  Contact,
  EjiDiscount,
  EjiService,
  PartsStore,
  PossibleEjiService,
  Query,
} from "../../generated/nest-graphql";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { AppointmentFormValues } from "./AppointmentForm";
import { FormikTouched, FormikErrors } from "formik";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { handleNoDecimal } from "../../lib/functions";
import {
  anyPass,
  append,
  isEmpty,
  isNil,
  join,
  keys,
  omit,
  path,
  pipe,
  prop,
  reject,
  remove,
  startsWith,
  and,
  propOr,
  pathOr,
  allPass,
  equals,
  pluck,
} from "ramda";
import { add } from "date-fns/fp";
import { SelectField } from "../FormFields/SelectField";
import { ActionsSelect } from "../FormFields/ActionsSelect";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { TextField } from "../FormFields/TextField";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { AppointmentFormWithModal } from "./AppointmentFormWithModal";
import { AppointmentFormSection } from "./AppointmentFormSection";
import Box from "@material-ui/core/Box";
import { Button } from "../Buttons/Button";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { SaveCancelRow } from "../SaveCancelRow";
import { CopyJobButton } from "../Buttons/CopyJobButton";
import { NuModal } from "../NuModal";
import { ItemForm, ItemFormValues } from "./ItemForm";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { AddItemSetButton } from "../Buttons/AddItemSetButton";
import { Space } from "../Space";
import { Typography } from "@material-ui/core";
import { formatDateTime } from "../../lib/functions";
import { useAuth0 } from "@auth0/auth0-react";
import { JobPartsStoreSelectField } from "../FormFields/JobPartsStoreSelectField";
import { flow } from "fp-ts/lib/function";
import { JobDetailsFormValues } from "./JobDetailsForm";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { useField } from "formik";
import { usePartsStores } from "../../hooks/usePartsStores";
import { WITHDRAW_REASONS } from "../../lib/constants";
import ServicesSection from "../Services/ServicesSection";

export const JobDetailsInternalForm: React.FC<{
  values: VehicleInfoValues & {
    appointmentId: string;
    appointmentInfo?: AppointmentFormValues;
    contact: Contact;
    description?: string;
    estimate?: string;
    items: ItemsTableType[];
    serviceCatalogueUsed?: string;
    services: PossibleEjiService[] | EjiService[];
    discounts: EjiDiscount[];
    jobName: string;
    market: string;
    partsLocation?: string;
    partsOrdered?: boolean;
    partsOrderedTimestamp?: Date;
    partsOrderNumber?: string;
    partsStore?: PartsStore;
    privateNotes?: string;
    serviceLocation: string;
    serviceLocationNotes?: string;
    status: string;
    type: string;
    withdrawExtraInfo?: string;
    withdrawReason?: string;
    withdrawnAt?: Date;
    taxable?: boolean;
  };
  touched: FormikTouched<
    VehicleInfoValues & {
      contact: Contact;
      estimate?: string;
      serviceLocation: string;
      status: string;
      type: string;
      appointmentId: string;
      jobName: string;
      privateNotes?: string;
      serviceLocationNotes?: string;
      description?: string;
      items: ItemsTableType[];
      partsOrderNumber?: string;
      partsLocation?: string;
      partsStore?: PartsStore;
      partsOrdered?: boolean;
      partsOrderedTimestamp?: Date;
      appointmentInfo?: AppointmentFormValues;
    }
  >;
  setValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  jobNumber?: string;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment: boolean;
  toggleItemModal: () => void;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  validateForm: (values?: any) => Promise<FormikErrors<JobDetailsFormValues>>;
  timeZone: string;
  resetForm: any;
  initialValues: VehicleInfoValues & {
    contact: Contact;
    estimate?: string;
    serviceLocation: string;
    status: string;
    type: string;
    appointmentId: string;
    jobName: string;
    privateNotes?: string;
    serviceLocationNotes?: string;
    description?: string;
    items: ItemsTableType[];
    partsOrderNumber?: string;
    partsLocation?: string;
    partsStore?: PartsStore;
    partsOrdered?: boolean;
    appointmentInfo?: AppointmentFormValues;
    serviceCatalogueUsed?: string;
    services?: PossibleEjiService[] | EjiService[];
  };
  isSubmitting: boolean;
  isValid: boolean;
  itemModalIsOpen: boolean;
}> = ({
  isValid,
  isSubmitting,
  setValues,
  setFieldValue,
  initialValues,
  jobId,
  submitForm,
  values,
  jobNumber,
  timeZone,
  appointment,
  showAddAppointment,
  itemModalIsOpen,
  validateForm,
  resetForm,
  toggleItemModal,
  touched,
}) => {
  const { user } = useAuth0();
  const isUserISAManager =
    user && ["matthew@nubrakes.com", "cole@nubrakes.com", "will@nubrakes.com"].includes(user.email);

  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const partsStoresData = usePartsStores();
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const rejectBlank = (collection: string[]) => reject(anyPass([isNil, isEmpty]))(collection);
  useEffect(() => {
    const { make, model, year } = values;
    const computedJobName = join(" ", rejectBlank([year!, make!, model!]));
    if (!touched.jobName) {
      if (values.jobName !== computedJobName) {
        setFieldValue("jobName", computedJobName);
      }
    }
  }, [values.type, values.make, values.model, values.year, values, touched.jobName, setFieldValue]);
  const market = values.market;
  const partsStores = anyPass([isNil, isEmpty])(market)
    ? partsStoresData
    : partsStoresData.filter((x) => equals(path(["market", "name"], x)!, market!));
  const defaultStartTime = new Date();
  const defaultEndTime = add({ hours: 1 }, defaultStartTime);
  const onDeleteItem = (idx: number) => () => {
    const result = remove(idx, 1, values.items);
    setFieldValue("items", result, true);
  };
  const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
    const selectedValue: any = prop("value", value);
    const valueKeys = pipe(omit(["id"]), keys)(selectedValue);
    valueKeys.forEach((key: any) => {
      const val = selectedValue[key];
      const keyWithPrefix = `${prefix}.${key}`;
      setFieldValue(keyWithPrefix, val ? val : "");
    });
  };
  const partsOrdered = prop("partsOrdered", values);
  const partsOrderedTimestamp = prop("partsOrderedTimestamp", values);
  const taxable: boolean = propOr(false, "taxable", values);
  const [field] = useField("appointmentInfo");
  const { value: appointmentValue } = field;
  return (
    <div ref={currentRef as any}>
      <div className="mb-4">{jobNumber && <div>Job Number: {jobNumber}</div>}</div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <SelectField
          name={"status"}
          options={[
            "New",
            "New: Scheduled",
            "Open",
            "Open: En Route",
            "Open: In-Progress",
            "Open: Ready For Billing",
            "Closed",
            "Withdrawn",
            "Withdrawn: Reschedule",
          ]}
          label={"Status"}
        />
        {jobId && (
          <ActionsSelect
            entityName={"Job"}
            entityId={jobId}
            isDisabled={!path(["technician"], appointment)}
            disabledReason={"Must Have Technician Assigned"}
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4">
        {flow(pathOr("", ["status"]), startsWith("Withdrawn"))(values) && (
          <SelectField
            name={"withdrawReason"}
            label={"Withdraw Reason"}
            options={WITHDRAW_REASONS}
            helperText={
              prop("withdrawnAt", values) ? `Withdrawn at ${formatDateTime(prop("withdrawnAt", values))}` : undefined
            }
          />
        )}
        {and(
          startsWith("Withdrawn", pathOr("", ["status"], values)),
          startsWith("Other", pathOr("", ["withdrawReason"], values))
        ) && <TextField name={"withdrawExtraInfo"} label={"Additional Withdraw Details"} />}
      </div>
      <DetailViewContainer title={"Contact info"}>
        <div className="grid grid-cols-3 gap-4">
          <ContactsSelect name={"contact"} label={"Contact"} required={true} />
          <TextField name={"email"} label={"Contact Email"} type={"email"} helperText={"Updates contact email"} />
          <div className="row-span-2">
            <TextField name={"serviceLocationNotes"} label={"Service Location Notes"} multiline={true} rows={4} />
          </div>
          <MarketFieldSelect name={"market"} required={true} />
          <LocationSearchInput name={"serviceLocation"} label={"Service Location"} required={true} />
        </div>
      </DetailViewContainer>
      <DetailViewContainer title={"Job Details"}>
        <div className="grid grid-cols-2 gap-4">
          <SelectField
            name={"type"}
            required={true}
            options={["Brake Repair Service", "Diagnostic", "Service Call", "Warranty Check"]}
            label={"Type"}
          />
          <TextField name={"jobName"} label={"Job Name"} required={true} />
          <TextField name={"description"} label={"Description"} multiline={true} rows={4} />
          <TextField
            name={"privateNotes"}
            label={"Private Notes"}
            multiline={true}
            rows={4}
            helperText="Internal notes used by ops and technicians."
          />
          {isUserISAManager && <TextField name={"estimate"} label={"Estimate ID"} />}
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <TextField required={true} name={"jobCausedWarrantyCheck"} label={"Job Prior to Warranty Check"} />
          )}
          {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
            <SelectField
              name={"warrantyCheckReason"}
              label={"Warranty Check Reason"}
              options={[
                "Part defect",
                "Technician did not install parts correctly",
                "Technician misdiagnosed repair",
                "Customer expectations set incorrectly",
                "Abnormal customer driving behavior",
                "Other",
              ]}
            />
          )}
          {allPass([
            flow(pathOr("", ["type"]), startsWith("Warranty")),
            flow(pathOr("", ["warrantyCheckReason"]), startsWith("Other")),
          ])(values) && <TextField name={"warrantyCheckReasonExtraInfo"} label={"Additional Warranty Check Details"} />}
        </div>
      </DetailViewContainer>
      {showAddAppointment && (
        <DetailViewContainer title={"Appointment Info"}>
          <AppointmentFormWithModal
            appointment={appointmentValue}
            itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
            services={values?.services}
            partsOrdered={values.partsOrdered}
            parentValidateForm={validateForm}
            parentSetFieldValue={setFieldValue}
            market={market}
            serviceLocation={values.serviceLocation}
            timeZone={timeZone}
            contactId={values.contact?.id}
            estimateId={values.estimate}
            initialValues={{
              allDay: false,
              endDate: defaultEndTime,
              subject: values.jobName,
              startDate: defaultStartTime,
              startTimeWindow: defaultStartTime,
              endTimeWindow: add({ minutes: 30 }, defaultStartTime),
              technician: null,
              timeZone,
              overrideOutsideServiceZone: false,
            }}
          />
        </DetailViewContainer>
      )}
      {jobId && (
        <DetailViewContainer title={"Assign & Schedule"}>
          <AppointmentFormSection
            services={values?.services}
            parentValidateForm={validateForm}
            partsOrdered={values.partsOrdered!}
            itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
            jobNumber={jobNumber!}
            market={market}
            parentSubmit={submitForm}
            parentSetFieldValue={setFieldValue}
            appointment={appointment}
            jobName={values.jobName}
            jobId={jobId}
            timeZone={timeZone}
            contactId={values.contact?.id}
            estimateId={values.estimate}
            serviceLocation={values.serviceLocation}
          />
        </DetailViewContainer>
      )}
      {values?.serviceCatalogueUsed === "Services" ? (
        <DetailViewContainer title={"Services"}>
          {!market || market === "ZipUnknown" || market === "" ? (
            "Please select a market first."
          ) : (
            <ServicesSection
              showTable={values?.services?.length > 0}
              partsStores={partsStores}
              values={values}
              setValues={setValues}
            />
          )}
        </DetailViewContainer>
      ) : (
        <DetailViewContainer title={"Items"}>
          <div className="flex flex-row justify-start mb-4">
            <Box mr={2}>
              <Button onClick={toggleItemModal} type={"button"}>
                + Add Item
              </Button>
            </Box>
            <AddItemSetButton
              name={"items"}
              defaultPartsStore={
                !values.partsOrdered
                  ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                  : null
              }
            />
          </div>
          {!loading && data?.getProducts && (
            <ItemsTable
              products={data.getProducts}
              items={values.items}
              onDeleteItem={onDeleteItem}
              postItemSelect={postSelectProduct}
              partsStores={partsStores}
            />
          )}
          <ItemsReceiptSection
            items={prop("items", values) as ItemFormValues[]}
            market={values.market}
            taxable={taxable}
          />
        </DetailViewContainer>
      )}
      <VehicleInfoFormSection />
      <DetailViewContainer title={"Parts Info"}>
        <div className="grid grid-cols-3 gap-4">
          <TextField name={"partsOrderNumber"} label={"Parts Order Number"} />
          <LocationSearchInput name={"partsLocation"} label={"Parts Location"} />
          <JobPartsStoreSelectField
            homeStore={pathOr(null, ["technician", "homePartsStore"], appointment)!}
            name={"partsStore"}
            label={"Parts Store"}
            postOnChange={(partsStore) => {
              console.log({ partsStore });
              setFieldValue("partsLocation", propOr(null, "address", partsStore));
              return true;
            }}
          />
          <div>
            <CheckBoxField
              name={"partsOrdered"}
              label={"Parts Ordered"}
              postOnChange={(value: boolean) => {
                value
                  ? setFieldValue("partsOrderedTimestamp", new Date())
                  : setFieldValue("partsOrderedTimestamp", null);
              }}
            />
            {partsOrdered && <Typography>{formatDateTime(partsOrderedTimestamp)}</Typography>}
          </div>
          <TextField name={"partsNotes"} label={"Parts Notes"} multiline={true} rows={4} />
          <SelectField
            name={"partsOrderingIssue"}
            required={false}
            options={[
              "Did not clarify vehicle details",
              "Incorrect Data - Advance",
              "Wrong parts - mis-boxed by Advance",
              "Parts availability issue",
              "Missing part(s)",
              "Parts not ordered",
              "Parts ordered to wrong store",
            ]}
            label={"Parts Ordering Issue"}
            noneValue={"none"}
          />
        </div>
      </DetailViewContainer>
      <Space />
      <SaveCancelRow
        width={width}
        offsetLeft={left}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onCancel={() => resetForm(initialValues)}
        midExtra={<>{jobId && <CopyJobButton values={values} />}</>}
      />
      <NuModal isOpen={itemModalIsOpen} title="Add Item">
        {!loading && data?.getProducts && (
          <ItemForm
            defaultPartsStore={
              !values.partsOrdered
                ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                : null
            }
            products={data.getProducts}
            onSubmit={async (itemFormValues: ItemFormValues) => {
              const oldItems: ItemsTableType[] = values.items;
              const {
                product,
                name,
                description,
                partNumber,
                partsCost,
                laborCost,
                amount,
                isInEstimate,
                partsStore,
              } = itemFormValues;
              const cleanedItemFormValues: ItemFormValues = {
                amount: handleNoDecimal(amount),
                laborCost: handleNoDecimal(laborCost),
                partsCost: handleNoDecimal(partsCost),
                isInEstimate,
                partNumber,
                description,
                name,
                product,
                partsStore,
              };
              const result = append(cleanedItemFormValues, oldItems as any[]);
              setFieldValue("items", result, false);
              toggleItemModal();
            }}
            onCancel={toggleItemModal}
          />
        )}
      </NuModal>
    </div>
  );
};
