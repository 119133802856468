import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetContactArgs,
  QueryGetDefaultServicesArgs,
  QueryGetEstimatesPaginatedArgs,
} from "../../generated/nest-graphql";
import { Button } from "../Buttons/Button";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { EstimateColumns, estimatesToEstimateColumns } from "../Estimates/EstimatesTable";
import { AddEstimate } from "../Estimates/AddEstimate";
import { cond, path, pathOr, T } from "ramda";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_ESTIMATES_PAGINATED } from "../../graphql/queries/getEstimatesPaginated";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const ContactsEstimates: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [enableAddEstimate, setEnableAddEstimate] = useState(false);
  const limit = 50;
  const { data } = useQuery<Query, QueryGetEstimatesPaginatedArgs>(GET_ESTIMATES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        skip: 0,
        limit,
        filter: {
          contact: contactId,
        },
      },
    },
  });
  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["lead", "vehicleInfo", "year"], contactResult?.data?.getContact ?? {}),
        make: pathOr("", ["lead", "vehicleInfo", "make"], contactResult?.data?.getContact ?? {}),
        model: pathOr("", ["lead", "vehicleInfo", "model"], contactResult?.data?.getContact ?? {}),
      },
    },
  });
  if (!data || !path(["data", "getContact"], contactResult) || !defaultServices) return null;
  const contactData = contactResult?.data?.getContact;
  return (
    <div className={"px-4"}>
      {enableAddEstimate ? (
        <AddEstimate
          initialValues={{
            status: "Draft",
            taxable: true,
            rearPadLife: pathOr(null, ["lead", "vehicleInfo", "rearPadLife"], contactData),
            odometer: pathOr(null, ["lead", "vehicleInfo", "odometer"], contactData),
            licensePlate: pathOr(null, ["lead", "vehicleInfo", "licensePlate"], contactData),
            frontPadLife: pathOr(null, ["lead", "vehicleInfo", "frontPadLife"], contactData),
            symptoms: pathOr(null, ["lead", "vehicleInfo", "symptoms"], contactData),
            vin: pathOr(null, ["lead", "vehicleInfo", "vin"], contactData),
            year: pathOr(null, ["lead", "vehicleInfo", "year"], contactData),
            extraInfo: pathOr(null, ["lead", "vehicleInfo", "extraInfo"], contactData),
            make: pathOr(null, ["lead", "vehicleInfo", "make"], contactData),
            model: pathOr(null, ["lead", "vehicleInfo", "model"], contactData),
            serviceLocation: pathOr("", ["lead", "address"], contactData),
            market: pathOr(null, ["lead", "market"], contactData),
            contact: contactData,
            issuedDate: new Date(),
            items: [],
            serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
            services: cond([
              [
                () => DEFAULT_SERVICE_CATALOGUE_USED === SERVICE_CATALOGUE_USED_SERVICES,
                () =>
                  defaultServices.getDefaultServices.map((service) => {
                    return {
                      ...service,
                      items: service.items.filter(({ behavior }) => behavior === "Default"),
                    };
                  }),
              ],
              [T, () => []],
            ])(null),
            discounts: [],
            estimateNotes: "",
            privateNotes: "",
            serviceLocationNotes: "",
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-end">
            <div className="my-4 mr-0">
              <Button onClick={() => setEnableAddEstimate(true)}>+ Add Estimate</Button>
            </div>
          </div>
          <SelectablePageableEntityTable
            title={"Estimates"}
            queryResult={data}
            columns={EstimateColumns}
            spec={estimatesToEstimateColumns}
            queryKey={"getEstimatesPaginated"}
            numRecords={data.getEstimatesPaginated.edges.length}
            limit={50}
          />
        </>
      )}
    </div>
  );
};
