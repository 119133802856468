import React from "react";
import { ProductDetails } from "../../components/Products/ProductDetails";
import { Container } from "../../components/Container";
import { useEntityId } from "../../hooks/useEntityId";

const ProductDetailsPage: React.FC = () => {
  const productId = useEntityId();
  return (
    <div>
      <div className="mt-16" />
      <Container>
        <ProductDetails productId={productId} />
      </Container>
    </div>
  );
};

export default ProductDetailsPage;
