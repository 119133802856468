import gql from "graphql-tag";
const optionString = `
          brand
          basePrice
          marketAdjustedPrice
          partsCost
          commission`;
export const GET_SERVICE_CATALOGUE_ITEM = gql`
  query getServiceCatalogueItem($year: String!, $make: String!, $model: String!, $subModel: String!, $market: String!) {
    getServiceCatalogueItem(year: $year, make: $make, model: $model, subModel: $subModel, market: $market) {
      id
      year
      make
      model
      subModel
      notes
      rearPads {
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        performanceOption {
${optionString}
        }
        OEMMaterial
        notes
        laborHours
      }
      frontPads {
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        performanceOption {
${optionString}
        }
        OEMMaterial
        notes
        laborHours
      }
      frontRotors {
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        alternativePricing
        notes
        laborHours
      }
      rearRotors {
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        alternativePricing
        notes
        laborHours
      }
      shoes {
        notes
        laborHours
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        performanceOption {
${optionString}
        }
      }
      drums {
        notes
        laborHours
        standardOption {
${optionString}
        }
        premiumOption {
${optionString}
        }
        performanceOption {
          ${optionString}
        }
      }
      daysOfWeek {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      leadTime {
        sameDay
        oneToTwoDays
        twoPlusDays
      }
    }
  }
`;
