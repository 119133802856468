import React from "react";
import { cond, contains, defaultTo, T } from "ramda";
import { EstimateDetails } from "./EstimateDetails";
import { EstimateJob } from "./EstimateJob";
import { EstimateInvoice } from "./EstimateInvoice";
import { useQueryParams } from "../../hooks/useQueryParams";

export const EstimateDetailsContainer: React.FC<{ estimateId: string }> = ({
  estimateId,
}) => {
  const queryParams = useQueryParams();
  const tab = defaultTo("", queryParams.get("tab"));
  return (
    <div className={"p-4"}>
      {cond([
        [
          contains("estimates"),
          () => <EstimateDetails estimateId={estimateId} />,
        ],
        [contains("job"), () => <EstimateJob estimateId={estimateId} />],
        [
          contains("invoice"),
          () => <EstimateInvoice estimateId={estimateId} />,
        ],
        [contains("payment"), () => <div>Payments</div>],
        [T, () => <EstimateDetails estimateId={estimateId} />],
      ])(tab)}
    </div>
  );
};
