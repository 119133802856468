import React from "react";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

export const AddToolbarButton: React.FC<{ link: string; title: string }> = ({ link, title }) => {
  return (
    <Tooltip title={`Add ${title}`}>
      <Link to={link}>
        <IconButton aria-label={`Add ${title}`}>
          <AddIcon />
        </IconButton>
      </Link>
    </Tooltip>
  );
};
