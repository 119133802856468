import { useField } from "formik";
import React from "react";
import { equals, prop } from "ramda";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface FormikAutocompleteProps {
  name: string;
  options: { name: string; value: string | number | null }[];
  label: string;
  postOnChange?: (val: any) => void;
}

export const FormikAutocomplete = ({ name, options, label, postOnChange = () => {} }: FormikAutocompleteProps) => {
  const [{ onChange, ...rest }, , { setValue }] = useField(name);
  return (
    <Autocomplete
      options={options}
      onChange={(event, value: any) => {
        setValue(value);
        postOnChange(value);
      }}
      getOptionSelected={(option, value: any) => {
        if (typeof value === "object") {
          return equals(option, value);
        }
        return option.value === value;
      }}
      autoSelect
      getOptionLabel={(option) => {
        if (option) return prop("name", option);
        return "None";
      }}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      {...rest}
    />
  );
};
