import React from "react";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { EstimateColumns } from "../Estimates/EstimatesTable";

export const EstimatesSearchResults: React.FC<{ estimates: any[] }> = ({
  estimates,
}) => {
  return (
    <div className={"m-6"}>
      <h4>Contacts</h4>
      <SelectableEntityTable
        data={estimates}
        title={"Estimates"}
        columns={EstimateColumns}
        numRecords={estimates.length}
      />
    </div>
  );
};
