import gql from "graphql-tag";

export const paymentDetailsFields = `
        id
        paymentNumber
        type
        source
        status
        invoice {
          id
          amountPaid
          balanceDue
        }
        receivedDate
        payer
        amount
        invoicePrice
        tip
        paymentMethod
        paymentIntentId
        createdBy
        createdAt
        updatedAt
      `;

export const GET_PAYMENT = gql`
  query getPayment($id: ID!) {
    getPayment(id: $id) {
      ${paymentDetailsFields}
    }
  }
`;
