import React from "react";
import { ErrorMessage } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";

const ErrorField: React.FC<{ fieldName: string }> = ({ fieldName }) => {
  return (
    <ErrorMessage name={fieldName}>
      {(errorMessage) => errorMessage}
    </ErrorMessage>
  );
};

export const ErrorTextField: React.FC<{
  fieldName: string;
  displayError?: boolean;
  displayOnSpan?: boolean;
}> = ({ fieldName, displayError = true, displayOnSpan = false }) => {
  if (!displayError) {
    return null;
  }

  return (
    <>
      {displayOnSpan ? (
        <FormHelperText component={"span"}>
          <ErrorField fieldName={fieldName} />
        </FormHelperText>
      ) : (
        <ErrorField fieldName={fieldName} />
      )}
    </>
  );
};
