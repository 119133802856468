import { MarketFormValues } from "../Forms/MarketForm";
import { applySpec, prop, path } from "ramda";
import { CreateMarketInput } from "../../generated/nest-graphql";
import { flow } from "fp-ts/lib/function";
import { Optional } from "monocle-ts";
import * as Option from "fp-ts/Option";

export const marketSpec = (val: MarketFormValues) => {
  return applySpec<CreateMarketInput>({
    name: prop("name"),
    timeZone: prop("timeZone"),
    phoneNumber: prop("phoneNumber"),
    email: prop("email"),
    address: prop("address"),
    businessID: prop("businessID"),
    activeServiceAreaGeoJson: flow(
      Optional.fromNullableProp<MarketFormValues>()("activeServiceAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.toNullable
    ),
    marketAreaGeoJson: flow(
      Optional.fromNullableProp<MarketFormValues>()("marketAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.toNullable
    ),
    activeServiceArea: flow(
      Optional.fromNullableProp<MarketFormValues>()("activeServiceAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.map(path(["features", 0, "geometry"])),
      Option.toNullable
    ),
    marketArea: flow(
      Optional.fromNullableProp<MarketFormValues>()("marketAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.map(path(["features", 0, "geometry"])),
      Option.toNullable
    ),
    variance: {
      pads: {
        standardOption: prop("padsPricingVarianceStandardOption"),
        premiumOption: prop("padsPricingVariancePremiumOption"),
        performanceOption: prop("padsPricingVariancePerformanceOption"),
      },
      rotors: {
        standardOption: prop("rotorsPricingVarianceStandardOption"),
        premiumOption: prop("rotorsPricingVariancePremiumOption"),
        performanceOption: prop("rotorsPricingVariancePerformanceOption"),
      },
      shoes: {
        standardOption: prop("shoesPricingVarianceStandardOption"),
        premiumOption: prop("shoesPricingVariancePremiumOption"),
        performanceOption: prop("shoesPricingVariancePerformanceOption"),
      },
      drums: {
        standardOption: prop("drumsPricingVarianceStandardOption"),
        premiumOption: prop("drumsPricingVariancePremiumOption"),
        performanceOption: prop("drumsPricingVariancePerformanceOption"),
      },
      daysOfWeek: {
        monday: prop("mondayVariance"),
        tuesday: prop("tuesdayVariance"),
        wednesday: prop("wednesdayVariance"),
        thursday: prop("thursdayVariance"),
        friday: prop("fridayVariance"),
        saturday: prop("saturdayVariance"),
        sunday: prop("sundayVariance"),
      },
      leadTime: {
        sameDay: prop("leadTimeSameDayVariance"),
        nextDay: prop("leadTimeNextDayVariance"),
        twoPlusDays: prop("leadTimeTwoPlusDayVariance"),
      },
    },
    salesTaxType: prop("salesTaxType"),
    partsTaxRate: prop("partsTaxRate"),
    laborTaxRate: prop("laborTaxRate"),
  })(val);
};
