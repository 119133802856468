import gql from "graphql-tag";

export const CANCEL_PAYMENT_INTENT = gql`
  mutation cancelPaymentIntent($paymentId: ID!) {
    cancelPaymentIntent(paymentId: $paymentId) {
      id
      paymentNumber
      type
      status
      amount
      paymentMethod
      paymentIntentId
    }
  }
`;
