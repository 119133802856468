import { useEntityId } from "../../hooks/useEntityId";
import { Container } from "../../components/Container";
import React from "react";
import { PartsStoreDetails } from "../../components/PartsStores/PartsStoreDetails";

export const PartsStoresDetailPage = () => {
  const partsStoreId = useEntityId();
  return (
    <div>
      <div className="mt-16" />
      <Container>
        <PartsStoreDetails partsStoreId={partsStoreId} />
      </Container>
    </div>
  );
};
