import React from "react";
import { Column } from "react-table";
import { useQuery } from "@apollo/client";
import { Query, QueryGetEstimatesPaginatedArgs } from "../../generated/nest-graphql";
import { applySpec, mergeDeepRight, path, pipe, prop } from "ramda";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_ESTIMATES_PAGINATED } from "../../graphql/queries/getEstimatesPaginated";
import { AddToolbarButton } from "../Table/AddToolbarButton";

type EstimatesTableType = {
  id: string;
  jobNumber: string;
  estimateNumber: string;
  contact: {
    id: string;
    firstName: string;
    lastName: string;
  };
  issuedDate: string;
  price: string;
  job: {
    id;
    status;
  };
  status: string;
  createdBy: string;
};

export const CurrencyCell = (props) => {
  const val = props.cell.value;
  return <div>${val}</div>;
};
export const EstimateColumns: Column<EstimatesTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/estimates/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Estimate Number", accessor: "estimateNumber" },
  {
    Header: "Contact",
    accessor: "contact",
    Cell: (props) => {
      const { firstName, lastName, id } = props.cell.value;
      return (
        <Link className={"text-primary w-full block text-center"} to={`/contacts/${id}`}>
          {firstName} {lastName}
        </Link>
      );
    },
  },
  { Header: "Issued Date", accessor: "issuedDate" },
  {
    Header: "Price",
    accessor: "price",
    Cell: CurrencyCell,
  },
  {
    Header: "Job",
    accessor: "job",
    Cell: (props) => {
      const { id, status } = props.cell.value;
      if (!id) return null;
      return (
        <Link className={"text-primary"} to={`/jobs/${id}`}>
          {`(${status}) ${id}`}
        </Link>
      );
    },
  },
  { Header: "Status", accessor: "status" },
  { Header: "Created By", accessor: "createdBy" },
];

export const EstimatesTable = () => {
  const limit = 50;
  const { data, fetchMore } = useQuery<Query, QueryGetEstimatesPaginatedArgs>(GET_ESTIMATES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        limit,
        skip: 0,
      },
    },
  });
  if (!data) return null;
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          limit,
          skip: data.getEstimatesPaginated.pageInfo.offset + 50,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEstimatesPaginated: {
            // @ts-ignore
            edges: [...prev.getEstimatesPaginated.edges, ...fetchMoreResult.getEstimatesPaginated.edges],
            // @ts-ignore
            pageInfo: mergeDeepRight(
              prev.getEstimatesPaginated.pageInfo,
              fetchMoreResult.getEstimatesPaginated.pageInfo
            ),
            // @ts-ignore
            __typename: prev.getEstimatesPaginated.__typename,
          },
        };
      },
    });
  };
  if (!data) return null;
  return (
    <>
      <SelectablePageableEntityTable
        title={"Estimates"}
        queryResult={data}
        columns={EstimateColumns}
        loadMore={loadMore}
        queryKey={"getEstimatesPaginated"}
        numRecords={data.getEstimatesPaginated.pageInfo.numRecords}
        limit={limit}
        spec={estimatesToEstimateColumns}
        endAdornments={<AddToolbarButton link="/add/estimates" title="Estimate" />}
      />
    </>
  );
};

export const estimatesToEstimateColumns = applySpec({
  id: prop("id"),
  estimateNumber: prop("estimateNumber"),
  contact: {
    id: path(["contact", "id"]),
    firstName: path(["contact", "firstName"]),
    lastName: path(["contact", "lastName"]),
  },
  issuedDate: pipe(prop("issuedDate"), formatDateTime),
  price: prop("price"),
  job: {
    id: path(["job", "id"]),
    status: path(["job", "status"]),
  },
  status: prop("status"),
  createdBy: prop("createdBy"),
});
