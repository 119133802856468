import * as React from "react";
import { Container } from "../../components/Container";
import { AddProductSet } from "../../components/ProductSets/AddProductSet";

const AddProductSetPage: React.FC<{}> = () => {
  return (
    <div>
      <Container>
        <div className="mt-16" />
        <h3>Create a Product Set</h3>
        <AddProductSet />
      </Container>
    </div>
  );
};

export default AddProductSetPage;
