import gql from "graphql-tag";

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $updateProductInput: UpdateProductInput!) {
    updateProduct(id: $id, updateProductInput: $updateProductInput) {
      amount
      description
      id
      laborCost
      name
      partsCost
    }
  }
`;
