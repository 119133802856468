import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { TextField } from "../FormFields/TextField";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { adminSources } from "../adminSources";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { PhoneNumberField } from "../FormFields/PhoneNumberField";
import * as Yup from "yup";
import { VehicleInfoFormSection, VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { SaveCancelRow } from "../SaveCancelRow";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import PlaceType from "../FormFields/location/PlaceType.interface";
import { setPostalCode } from "../FormFields/location/functions";
import TimeZoneSelect from "../FormFields/TimeZoneSelect";
import Link from "@material-ui/core/Link";
import { Space } from "../Space";
import { getFieldValueFromMarkets } from "../FormFields/MarketFieldSelect";
import { useMarkets } from "../../hooks/useMarkets";
import { isEmpty, prop } from "ramda";

export const ContactDetailValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  email: Yup.string().email("Must be in email format").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  market: Yup.string().required("Required"),
  hearAboutUs: Yup.string(),
  zip: Yup.string(),
  address: Yup.string(),
  source: Yup.string().required("Required"),
  outreachMedium: Yup.string(),
  make: Yup.string(),
  model: Yup.string(),
  year: Yup.string(),
  symptoms: Yup.string(),
  extraInfo: Yup.string(),
});

type ContactDetailsFormValues = VehicleInfoValues & {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  market: string;
  hearAboutUs: string;
  zip: string;
  address: string;
  source: string;
  outreachMedium;
  timeZone: string;
};

export const ContactDetailsForm: React.FC<{
  initialValues: ContactDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
}> = ({ initialValues, onSubmit }) => {
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const markets = useMarkets();
  return (
    <Formik<ContactDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ContactDetailValidationSchema}
    >
      {({ isSubmitting, isValid, resetForm, setFieldValue, values: { email } }) => {
        return (
          <Form ref={currentRef}>
            <DetailViewContainer title={"General Information"}>
              <div className="grid grid-cols-2 gap-4">
                <TextField name={"firstName"} label={"First Name"} required={true} />
                <TextField name={"lastName"} label={"Last Name"} />
                <TextField
                  name={"email"}
                  label={"Email"}
                  type={"email"}
                  required={true}
                  helperText={
                    isEmpty(email) && (
                      <>
                        {email !== "noemail@email.com" && (
                          <Link
                            style={{ color: "#fff" }}
                            href="#"
                            onClick={() => setFieldValue("email", "noemail@email.com", false)}
                          >
                            Couldn't Collect?
                          </Link>
                        )}
                      </>
                    )
                  }
                />
                <PhoneNumberField name={"phoneNumber"} label={"Phone Number"} required={true} />
                <LocationSearchInput
                  name={"address"}
                  label={"Address"}
                  postOnChange={(value: PlaceType | null) => {
                    if (value === null) {
                      return;
                    }
                    const placeId = value.place_id;
                    setPostalCode(placeId, "zip", setFieldValue);
                  }}
                />
                <TextField name={"zip"} label={"Zip"} />
                <SelectField name={"source"} options={adminSources} label={"Source"} required={true} />
                <SelectField
                  name={"outreachMedium"}
                  options={[
                    "Inbound Call",
                    "Outbound Call",
                    "Yelp",
                    "Facebook",
                    "Text",
                    "Chat Widget",
                    "Email",
                    "Other",
                  ]}
                  label={"Outreach Medium"}
                />
                <TextField name={"hearAboutUs"} label={"Hear About Us"} />
                <MarketFieldSelect
                  name={"market"}
                  required={true}
                  postOnChange={(value: any) => {
                    if (value === null) return;
                    setFieldValue("timeZone", prop(value, getFieldValueFromMarkets(markets, "timeZone", value)));
                  }}
                />
                <TimeZoneSelect name={"timeZone"} label={"Time Zone"} />
              </div>
            </DetailViewContainer>
            <VehicleInfoFormSection />
            <Space />
            <SaveCancelRow
              width={width}
              offsetLeft={left}
              onCancel={() => resetForm({ values: initialValues })}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
