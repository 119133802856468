import * as React from "react";
import { useField } from "formik";
import { always, concat, equals, includes, reject } from "ramda";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: { paddingTop: 2, paddingBottom: 2 },
});

export const MultiSelectCheckBoxField: React.FC<{
  name: string;
  label: string;
  value: any;
  postOnClick?: any;
  indeterminate?: boolean;
}> = ({ name, label, value, postOnClick, indeterminate }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const currentValArr = field.value;
  const inValues = includes(value, currentValArr);
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          className={classes.root}
          checked={inValues}
          indeterminate={inValues && indeterminate}
          size="small"
          onClick={() => {
            if (inValues) {
              helpers.setValue(reject(equals(value), currentValArr));
            } else {
              helpers.setValue(concat([value], currentValArr));
            }
            postOnClick && postOnClick(value, inValues);
          }}
          onChange={always("")}
        />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
};
