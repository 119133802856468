import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { SummaryTabPanel } from "./SummaryTabPanel";

const useStyles = makeStyles({
  root: {
    maxWidth: 750,
    margin: 3,
  },
  header: {
    borderBottom: "0.1em solid grey",
  },
  tabs: {
    padding: 0,
    borderBottom: "0.1em solid grey",
  },
});

export const Summary: React.FC = () => {
  const classes = useStyles();
  const [activeTabValue, setActiveTabValue] = useState(1);

  const handleChange = (_: any, val: any) => {
    setActiveTabValue(val);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={"Summary"}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent className={classes.tabs}>
        <Tabs
          value={activeTabValue}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Yesterday" />
          <Tab label="Today" />
          <Tab label="This Month" />
        </Tabs>
      </CardContent>
      <CardContent>
        <SummaryTabPanel
          dateRange={"YESTERDAY"}
          activeTabValue={activeTabValue}
          index={0}
        />
        <SummaryTabPanel
          dateRange={"TODAY"}
          activeTabValue={activeTabValue}
          index={1}
        />
        <SummaryTabPanel
          dateRange={"CURRENT_MONTH"}
          activeTabValue={activeTabValue}
          index={2}
        />
      </CardContent>
    </Card>
  );
};
