import { Technician } from "../../generated/nest-graphql";
import { omit, path, prop } from "ramda";

export const technicianCopySpec = (technician: Technician) => ({
  _id: prop("id", technician),
  ...omit(["role"], technician),
  role: path(["role", "id"], technician),
  roleCopy: {
    _id: path(["role", "id"], technician),
    ...prop("role", technician),
  },
  homePartsStore: path(["homePartsStore", "id"], technician),
});
