import { EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { applySpec, path, prop } from "ramda";
import { itemsSpec } from "../specs/jobDetailsSpec";
import { flow } from "fp-ts/lib/function";
import { cleanedServices } from "../Services/ProductRow";
import { cleanedDiscounts } from "../Services/ServicesReceiptSection";

export const estimateDetailSpec = (formData: EstimateDetailsFormValues) =>
  applySpec({
    contact: path(["contact", "id"]),
    estimateNotes: prop("estimateNotes"),
    issuedDate: prop("issuedDate"),
    items: (data) => prop("items")(data) && flow(prop("items"), itemsSpec)(data),
    privateNotes: prop("privateNotes"),
    serviceLocation: prop("serviceLocation"),
    serviceLocationNotes: prop("serviceLocationNotes"),
    status: prop("status"),
    taxable: prop("taxable"),
    market: prop("market"),
    vehicleInfo: {
      frontPadLife: prop("frontPadLife"),
      licensePlate: prop("licensePlate"),
      make: prop("make"),
      model: prop("model"),
      odometer: prop("odometer"),
      rearPadLife: prop("rearPadLife"),
      vin: prop("vin"),
      year: prop("year"),
      symptoms: prop("symptoms"),
      extraInfo: prop("extraInfo"),
      customerExpectation: prop("customerExpectation"),
      additionalNotes: prop("additionalNotes"),
      frontBrakeSymptoms: prop("frontBrakeSymptoms"),
      rearBrakeSymptoms: prop("rearBrakeSymptoms"),
    },
    services: flow(prop("services"), cleanedServices),
    discounts: flow(prop("discounts"), cleanedDiscounts),
  })(formData);
