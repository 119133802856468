import React from "react";
import { Button, ThemeProvider } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red["800"],
    },
  },
});

export const CancelButton: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, disabled, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        style={{ fontWeight: "bolder" }}
        size={"large"}
        disabled={disabled}
        variant={"contained"}
        color={"primary"}
        onClick={rest.onClick}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
};
