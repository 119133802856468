import React from "react";
import { TechniciansTable } from "../../components/Technician/TechniciansTable";

const TechnicianPage = () => {
  return (
    <>
      <div className="px-8 py-4">
        <div>
          <TechniciansTable />
        </div>
      </div>
    </>
  );
};

export default TechnicianPage;
