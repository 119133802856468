import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetTechnicianArgs,
  Technician,
} from "../../generated/nest-graphql";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import React from "react";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { AddToolbarButton } from "../Table/AddToolbarButton";

const TechnicianColumns: Column<Technician>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link
          className={"text-primary"}
          to={`/technicians/${props.cell.value}`}
        >
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "First Name", accessor: "firstName" },
  { Header: "Last Name", accessor: "lastName" },
  { Header: "Market", accessor: "market" },
  // @ts-ignore
  { Header: "Role", accessor: "role.name" },
  { Header: "Email", accessor: "email" },
  { Header: "Phone Number", accessor: "phoneNumber" },
];

export const TechniciansTable = () => {
  const { data } = useQuery<Query, QueryGetTechnicianArgs>(GET_TECHNICIANS);
  if (!data) return null;
  return (
    <SelectableEntityTable<Technician>
      title={"Technicians"}
      data={data.getTechnicians}
      columns={TechnicianColumns}
      numRecords={data.getTechnicians.length}
      endAdornments={
        <AddToolbarButton link="/add/technicians" title="Technician" />
      }
    />
  );
};
