import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useField } from "formik";
import { map, prop } from "ramda";
import React from "react";
import { ErrorTextField } from "./ErrorTextField";
import { AddableItem } from "../PricingTool/AddableItemFixtures";

export interface AddableItemSelectProps {
  items: AddableItem[];
  name: string;
  postOnChange?: (val: any) => void;
}

export const AutocompleteAddableItemSelect = ({ items, name, postOnChange = () => {} }: AddableItemSelectProps) => {
  const options = map((val: AddableItem) => ({ label: val.name, value: val }))(items);
  const [field, meta, helper] = useField(name);
  return (
    <Autocomplete
      options={options}
      getOptionLabel={prop("label")}
      getOptionSelected={(option, value) => {
        return value.label === option.label;
      }}
      onChange={(event, value: any) => {
        helper.setValue(value);
        postOnChange(value);
      }}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={"Item"}
          variant="outlined"
          helperText={<ErrorTextField displayOnSpan={true} fieldName={field.name} displayError={!!meta.error} />}
        />
      )}
    />
  );
};
