import gql from "graphql-tag";

export const GET_APPOINTMENTS_CONFLICTS = gql`
  query getAppointmentConflicts($startRange: Date!, $endRange: Date!, $filter: JSON) {
    getAppointmentConflicts(startRange: $startRange, endRange: $endRange, filter: $filter) {
      id
      allDay
      endDate
      startDate
      subject
      technician {
        id
        firstName
        lastName
        market
        isActive
        homePartsStore {
          id
          name
          vendor
        }
      }
      job {
        id
        status
        jobName
        type
        market
      }
      timeZone
    }
  }
`;
