import currency from "currency.js";
import * as Option from "fp-ts/Option";
import { useMemo } from "react";
import { Optional } from "monocle-ts";
import { Query, ServiceCatalogueItem } from "../../generated/nest-graphql";
import { always, defaultTo, isNil, none, pathOr, prop } from "ramda";
import { flow, pipe } from "fp-ts/lib/function";
import { OptionType } from "../FormFields/AutocompleteVehicleSelect";
import { ItemOptions, ItemOption } from "../Forms/PricingToolForm";

export type PricingTableData = {
  fees?: string;
  discounts?: string;
  rows?: PricingTableDataRow[];
};

export type PricingTableDataRow = {
  item: string;
  price?: string;
  basePrice?: string;
  marketAdjustedPrice?: string;
  discounts?: string;
  fees?: string;
  notes?: string;
  brand?: string;
};

type usePricingToolTableDataInput = {
  percentageDiscount: { name: string; value: number };
  make: OptionType;
  model: OptionType;
  year: OptionType;
  subModel: OptionType;
  market: string;
  dayOfWeek: OptionType;
  leadTime: OptionType;
  options: ItemOptions;
  serviceCatalogueItemData: Query;
};
export const usePricingToolTableData = ({
  percentageDiscount,
  make,
  model,
  year,
  subModel,
  market,
  dayOfWeek,
  leadTime,
  options,
  serviceCatalogueItemData,
}: usePricingToolTableDataInput): PricingTableData => {
  return useMemo(() => {
    const extractMarketAdjustedPrice = (
      option: ItemOption,
      partType: string,
      serviceCatalogueItem: ServiceCatalogueItem
    ) => {
      return pathOr(null, [partType, prop("value", option), "marketAdjustedPrice"], serviceCatalogueItem);
    };
    const extractPartsCost = (option: ItemOption, partType: string, serviceCatalogueItem: ServiceCatalogueItem) =>
      pathOr(null, [partType, prop("value", option), "partsCost"], serviceCatalogueItem);
    const extractLaborCost = (option: ItemOption, partType: string, serviceCatalogueItem: ServiceCatalogueItem) => {
      return pipe(
        extractPartsCost(option, partType, serviceCatalogueItem),
        Option.fromNullable,
        Option.map(calcLabor(option, partType, serviceCatalogueItem)),
        Option.toNullable
      );
    };
    const calcLabor = (option: ItemOption, partType: string, serviceCatalogueItem: ServiceCatalogueItem) => (val) => {
      return pipe(
        extractMarketAdjustedPrice(option, partType, serviceCatalogueItem),
        Option.fromNullable,
        Option.map((marketPrice) => currency(marketPrice).subtract(currency(val)).toString()),
        Option.toNullable
      );
    };
    const percentageDiscountValue = defaultTo(0)(percentageDiscount?.value);
    const applyDiscount = (val) => {
      return currency(defaultTo(0)(val)).multiply(1 - percentageDiscountValue).value;
    };
    const calcDiscountForMarketAdjustedPrice = (val) => currency(val).multiply(percentageDiscountValue).toString();
    const extractDiscount = (val: ServiceCatalogueItem, selector: string, option: ItemOption) =>
      pipe(
        extractMarketAdjustedPrice(option, selector, val),
        Option.fromNullable,
        Option.map(calcDiscountForMarketAdjustedPrice),
        Option.toNullable
      );
    const extractFeesAndDiscounts = (val: ServiceCatalogueItem, day: OptionType, leadTime: OptionType) => {
      const dayOfWeekFee = flow(pathOr(0, ["daysOfWeek", prop("value", day)]), currency)(val);
      const leadTimeFee = flow(pathOr(0, ["leadTime", prop("value", leadTime)]), currency)(val);
      const isFee = (x) => x >= 0;
      const isDiscount = (x) => x < 0;
      const fee = currency(0)
        .add(isFee(dayOfWeekFee) ? dayOfWeekFee : currency(0))
        .add(isFee(leadTimeFee) ? leadTimeFee : currency(0));
      const discount = currency(0)
        // discount still needs to be positive value to calculate total properly
        .add(isDiscount(dayOfWeekFee) ? dayOfWeekFee.multiply(-1) : currency(0))
        .add(isDiscount(leadTimeFee) ? leadTimeFee.multiply(-1) : currency(0));
      return { fees: fee.toString(), discounts: discount.toString() };
    };
    const calcPriceFromMarketAdjustedPrice = flow(applyDiscount, (val) => currency(val).toString());
    const extractFinalPrice = (option: ItemOption, selector: string, val: ServiceCatalogueItem) => {
      return pipe(
        extractMarketAdjustedPrice(option, selector, val),
        Option.fromNullable,
        Option.map(calcPriceFromMarketAdjustedPrice),
        Option.toNullable
      );
    };
    const extractBrand = (option: ItemOption, selector: string, serviceCatalogueItem: ServiceCatalogueItem) => {
      return pathOr(null, [selector, prop("value", option), "brand"])(serviceCatalogueItem);
    };
    return flow(
      (val) => {
        if (none(isNil, [make, model, year, subModel, options, market])) {
          return val;
        }
        return null;
      },
      Option.fromNullable,
      Option.chain(Optional.fromNullableProp<Query>()("getServiceCatalogueItem").getOption),
      Option.map((val) => {
        const { frontPads, frontRotors, rearRotors, rearPads, drums, shoes } = val;
        const { fees, discounts } = extractFeesAndDiscounts(val, dayOfWeek, leadTime);
        return {
          fees: fees,
          discounts: discounts,
          rows: [
            {
              item: "Front Pads",
              option: options.frontPads,
              finalPrice: extractFinalPrice(options.frontPads, "frontPads", val),
              basePrice: extractMarketAdjustedPrice(options.frontPads, "frontPads", val),
              parts: extractPartsCost(options.frontPads, "frontPads", val),
              labor: extractLaborCost(options.frontPads, "frontPads", val),
              notes: extractFinalPrice(options.frontPads, "frontPads", val) && frontPads.notes,
              discount: extractDiscount(val, "frontPads", options.frontPads),
              brand: extractBrand(options.frontPads, "frontPads", val),
            },
            {
              item: "Front Rotors",
              option: options.frontRotors,
              finalPrice: extractFinalPrice(options.frontRotors, "frontRotors", val),
              basePrice: extractMarketAdjustedPrice(options.frontRotors, "frontRotors", val),
              parts: extractPartsCost(options.frontRotors, "frontRotors", val),
              labor: extractLaborCost(options.frontRotors, "frontRotors", val),
              notes: extractFinalPrice(options.frontRotors, "frontRotors", val) && frontRotors.notes,
              discount: extractDiscount(val, "frontRotors", options.frontRotors),
              brand: extractBrand(options.frontRotors, "frontRotors", val),
            },
            {
              item: "Rear Pads",
              option: options.rearPads,
              finalPrice: extractFinalPrice(options.rearPads, "rearPads", val),
              basePrice: extractMarketAdjustedPrice(options.rearPads, "rearPads", val),
              parts: extractPartsCost(options.rearPads, "rearPads", val),
              labor: extractLaborCost(options.rearPads, "rearPads", val),
              notes: extractFinalPrice(options.rearPads, "rearPads", val) && rearPads.notes,
              discount: extractDiscount(val, "rearPads", options.rearPads),
              brand: extractBrand(options.rearPads, "rearPads", val),
            },
            {
              item: "Rear Rotors",
              option: options.rearRotors,
              finalPrice: extractFinalPrice(options.rearRotors, "rearRotors", val),
              basePrice: extractMarketAdjustedPrice(options.rearRotors, "rearRotors", val),
              parts: extractPartsCost(options.rearRotors, "rearRotors", val),
              labor: extractLaborCost(options.rearRotors, "rearRotors", val),
              discount: extractDiscount(val, "rearRotors", options.rearRotors),
              notes: extractFinalPrice(options.rearRotors, "rearRotors", val) && rearRotors.notes,
              brand: extractBrand(options.rearRotors, "rearRotors", val),
            },
            {
              item: "Rear Shoes",
              option: options.shoes,
              finalPrice: extractFinalPrice(options.shoes, "shoes", val),
              basePrice: extractMarketAdjustedPrice(options.shoes, "shoes", val),
              parts: extractPartsCost(options.shoes, "shoes", val),
              labor: extractLaborCost(options.shoes, "shoes", val),
              discount: extractDiscount(val, "shoes", options.shoes),
              notes: extractFinalPrice(options.shoes, "shoes", val) && shoes.notes,
              brand: extractBrand(options.shoes, "shoes", val),
            },
            {
              item: "Rear Drums",
              option: options.drums,
              finalPrice: extractFinalPrice(options.drums, "drums", val),
              basePrice: extractMarketAdjustedPrice(options.drums, "drums", val),
              parts: extractPartsCost(options.drums, "drums", val),
              labor: extractLaborCost(options.drums, "drums", val),
              discount: extractDiscount(val, "drums", options.drums),
              notes: extractFinalPrice(options.drums, "drums", val) && drums.notes,
              brand: extractBrand(options.drums, "drums", val),
            },
          ],
        };
      }),
      Option.getOrElse(
        always({
          fees: null,
          discounts: null,
          rows: [
            { item: "Front Pads" },
            { item: "Front Rotors" },
            { item: "Rear Pads" },
            { item: "Rear Rotors" },
            { item: "Rear Shoes" },
            { item: "Rear Drums" },
          ],
        })
      )
    )(serviceCatalogueItemData);
  }, [
    make,
    model,
    percentageDiscount?.value,
    serviceCatalogueItemData,
    subModel,
    year,
    market,
    dayOfWeek,
    leadTime,
    options,
  ]);
};
