import React from "react";
import "date-fns";
import { LuxonTZUtils } from "../DatePickerUtil";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DATE_TIME_FORMAT } from "../../lib/constants";
import { FieldError } from "react-hook-form";

export const DateTimeInputRHF: React.FC<{
  value: any;
  label: string;
  required?: boolean;
  readOnly?: boolean;
  error?: FieldError;
  onChange: any;
  errorMessage?: string;
}> = ({ value, readOnly = false, label, required, error, onChange, errorMessage = "Please enter a valid Date" }) => {
  return (
    <MuiPickersUtilsProvider utils={LuxonTZUtils}>
      <KeyboardDateTimePicker
        error={!!error}
        value={value instanceof Date ? value : null}
        showTodayButton
        readOnly={readOnly}
        inputVariant="outlined"
        fullWidth
        label={label}
        required={required}
        // @ts-ignore
        onChange={onChange}
        format={DATE_TIME_FORMAT}
        mask={"__/__/____ __:__ __"}
        refuse={/[^\d|a|p|m]+/gi}
        helperText={<>{error && <div>{errorMessage}</div>}</>}
        disablePast
      />
    </MuiPickersUtilsProvider>
  );
};
