export interface AddableItem {
  name: string;
  finalPrice?: number;
  price?: number;
  partsCost: number;
  labor: number;
  discount: number;
  laborHours: number;
}

export const addableItems: Omit<AddableItem, "price">[] = [
  {
    name: "Dealership Pads",
    partsCost: 0,
    discount: 0.0,
    labor: 85,
    laborHours: 1,
  },
  {
    name: "Dealership Rotors",
    partsCost: 0,
    discount: 0.0,
    labor: 42.5,
    laborHours: 0.5,
  },
  {
    name: "Drums",
    partsCost: 0,
    labor: 85,
    discount: 0.0,
    laborHours: 1,
  },
  {
    name: "Shoes",
    partsCost: 0,
    labor: 85,
    discount: 0,
    laborHours: 1,
  },
  {
    name: "Pads",
    partsCost: 0,
    labor: 85,
    discount: 0,
    laborHours: 1,
  },
  {
    name: "Rotors",
    partsCost: 0,
    labor: 42.5,
    discount: 0,
    laborHours: 0.5,
  },
  {
    name: "Other",
    partsCost: 0,
    labor: 0,
    discount: 0,
    laborHours: 0,
  },
  {
    name: "Military Discount",
    partsCost: 0,
    labor: 0,
    discount: 20,
    laborHours: 0,
  },
  {
    name: "Student Discount",
    partsCost: 0,
    labor: 0,
    discount: 20,
    laborHours: 0,
  },
];
