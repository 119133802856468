import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "./Alert";
import React from "react";
import { snackbarClose } from "../redux/slices/snackbar";
import { useAppDispatch, useAppSelector } from "../redux/util";

export const GlobalSnackBarV2 = () => {
  const { showSnackBar, snackBarMessage } = useAppSelector((state) => state.snackbar);
  const dispatch = useAppDispatch();
  const handleClose = (event: any, reason?: any) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackbarClose());
  };
  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          handleClose(event, reason);
        }}
      >
        <Alert onClose={handleClose} severity={"success"}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
