import * as React from "react";
import { NuModal } from "../NuModal";
import { EstimateDetailsForm, EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateEstimateArgs } from "../../generated/nest-graphql";
import { CREATE_ESTIMATE } from "../../graphql/mutations/createEstimate";
import { pipe, prop, propOr } from "ramda";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { cleanObject } from "../../lib/functions";
import { estimateDetailSpec } from "../specs/estimateDetailSpec";

export const JobAddEstimateModal: React.FC<{
  open: boolean;
  toggle: () => void;
  values: JobDetailsFormValues;
}> = ({ open, toggle, values }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createEstimate] = useMutation<Mutation, MutationCreateEstimateArgs>(CREATE_ESTIMATE);

  const initialValues: EstimateDetailsFormValues = {
    status: "Draft",
    issuedDate: new Date(),
    contact: prop("contact", values),
    items: propOr([], "items", values),
    market: prop("market", values),
    taxable: prop("taxable", values),
    serviceLocation: prop("serviceLocation", values),
    serviceLocationNotes: propOr("", "serviceLocationNotes", values),
    privateNotes: propOr("", "privateNotes", values),
    year: propOr("", "year", values),
    make: propOr("", "make", values),
    model: propOr("", "model", values),
    odometer: propOr("", "odometer", values),
    vin: propOr("", "vin", values),
    extraInfo: propOr("", "extraInfo", values),
    licensePlate: propOr("", "licensePlate", values),
    symptoms: propOr("", "symptoms", values),
    frontPadLife: propOr("", "frontPadLife", values),
    rearPadLife: propOr("", "rearPadLife", values),
    additionalNotes: propOr("", "additionalNotes", values),
    customerExpectation: propOr("", "customerExpectation", values),
    frontBrakeSymptoms: propOr([], "frontBrakeSymptoms", values),
    rearBrakeSymptoms: propOr([], "rearBrakeSymptoms", values),
  };

  const onSubmit = async (values: EstimateDetailsFormValues, helpers) => {
    const input = pipe(estimateDetailSpec, cleanObject)(values);
    const result = await createEstimate({
      variables: {
        // @ts-ignore
        createEstimateInput: input,
      },
    });
    const id = result.data.createEstimate.id;
    showSuccess({ message: "Successfully Added Contact" });
    await history.push(`/estimates/${id}`);
  };
  return (
    <NuModal isOpen={open} title="Add Estimate" maxWidth="lg">
      <EstimateDetailsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitRowNotFixed={true}
        onCancel={toggle}
      />
    </NuModal>
  );
};
