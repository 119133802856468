import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationUpdatePartsStoreArgs, Query, QueryGetPartsStoreArgs } from "../../generated/nest-graphql";
import React from "react";
import { cleanObject, objectDiff } from "../../lib/functions";
import { keys, path } from "ramda";
import { PartsStoreForm, PartsStoreFormValues } from "../Forms/PartsStoreForm";
import { flow } from "fp-ts/lib/function";
import { UPDATE_PARTS_STORE } from "../../graphql/mutations/updatePartsStore";
import { GET_PARTS_STORE } from "../../graphql/queries/getPartsStore";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { partsStoreSpec } from "../specs/partsStoreSpec";

export const PartsStoreDetails = ({ partsStoreId }: { partsStoreId: string }) => {
  const { data } = useQuery<Query, QueryGetPartsStoreArgs>(GET_PARTS_STORE, {
    variables: {
      id: partsStoreId,
    },
  });
  const [updatePartsStore] = useMutation<Mutation, MutationUpdatePartsStoreArgs>(UPDATE_PARTS_STORE);
  const showSuccess = useShowSuccess();
  if (!data) return null;
  const initialValues: PartsStoreFormValues = {
    storeNumber: path(["getPartsStore", "storeNumber"], data),
    name: path(["getPartsStore", "name"], data),
    address: path(["getPartsStore", "address"], data),
    vendor: path(["getPartsStore", "vendor"], data),
    market: {
      label: path(["getPartsStore", "market", "name"], data),
      value: path(["getPartsStore", "market"], data),
    },
  };

  const onSubmit = async (values: PartsStoreFormValues) => {
    const diff = objectDiff(values, initialValues);
    const updates = flow(partsStoreSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updatePartsStore({
        variables: {
          id: partsStoreId,
          // @ts-ignore
          partsStoreInput: {
            ...updates,
            storeNumber: values.storeNumber,
            name: values.name,
            address: values.address,
          },
        },
      });
      showSuccess({ message: "Successfully Updated Parts Store" });
    }
  };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <PartsStoreForm partsStoreName={data.getPartsStore.name} onSubmit={onSubmit} initialValues={initialValues} />
      </div>
    </div>
  );
};
