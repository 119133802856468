import gql from "graphql-tag";
import { partsStoreDetailsFields } from "./getPartsStore";

export const ejiPriceInfoFields = `
    partsTotal
    laborTotal
    feesTotal
    subTotal
    discounts {
        type
        amount
        reason
        total
    }
    finalPartsTotal
    finalLaborTotal
    finalFeesTotal
    discountTotal
    finalSubTotal
    partsTax
    laborTax
    totalTax
    amountDue`;

const calculationReturnFields = `
            ejiPriceInfo {
                ${ejiPriceInfoFields}
            }
            ejiServices {
                service
                name
                inEstimate
                customerPrice
                technicianPay
                items {
                    serviceProductId
                    productSelection {
                         name
                    }
                    overriddenCustomerPrice
                    type
                    units
                    customerPrice
                    vendorUnitCost
                    behavior
                    category
                    notes
                    orderItem {
                        partsStore {
                            ${partsStoreDetailsFields}
                        }
                        isOrdered
                        partNumber
                    }
                    marketVendorUnitCostMultiplierUsed
                }
            }
        `;

export const CALCULATE_POSSIBLE_EJI_PRICE_INFO = gql`query calculatePossibleEJIPriceInfo($calculatePossibleEJIPriceInfoInput: CalculatePossibleEJIPriceInfoInput!) {
    calculatePossibleEJIPriceInfo(calculatePossibleEJIPriceInfoInput: $calculatePossibleEJIPriceInfoInput) {
        ${calculationReturnFields}
    }
}`;
