import gql from "graphql-tag";
import { marketFields } from "./getMarket";

export const GET_MARKETS = gql`
  query getMarketsV2 {
    getMarketsV2 {
      ${marketFields}
    }
  }
`;
