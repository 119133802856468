import gql from "graphql-tag";

export const jobListFields = `
            id
            jobName
            market
            type
            contact {
                id
                firstName
                lastName
            }
            serviceLocation
            partsInfo {
                partsOrdered
            }
            createdBy
            createdAt
            updatedAt
            status
            jobNumber
            appointment {
                id
                subject
                timeZone
                allDay
                technician {
                    firstName
                    lastName
                    id
                }
                startDate
                endDate
            }
`;

export const GET_JOBS_PAGINATED_V2 = gql`query getJobsPaginatedV2($paginatedQueryInput: PaginatedQueryInput!) {
    getJobsPaginatedV2(paginatedQueryInput: $paginatedQueryInput) {
        edges {
            ${jobListFields}
        }
        pageInfo {
            hasNextPage
            offset
            numRecords
        }
    }
}`;
