import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";

// Sets the timezone globally
export class LuxonTZUtils extends LuxonUtils {
  timeZone: string;
  constructor(props) {
    super();
  }

  format(date, format) {
    return date.setZone(this.timeZone).toFormat(format);
  }

  parse(value, formatString) {
    if (value === "") {
      return null;
    }

    return DateTime.fromFormat(value, formatString, { zone: this.timeZone });
  }

  date(value) {
    if (typeof value === "undefined") {
      return DateTime.local().setZone(this.timeZone);
    }

    if (value === null) {
      return null;
    }

    if (typeof value === "string") {
      return DateTime.fromJSDate(new Date(value), { zone: this.timeZone });
    }

    if (value instanceof DateTime) {
      return value.setZone(this.timeZone);
    }

    return DateTime.fromJSDate(value, { zone: this.timeZone });
  }
}
