import React from "react";
import { FormikAutocomplete } from "./FormikAutocomplete";

const options = [
  {
    name: "None",
    value: 0,
  },
  {
    name: "Radio 15%",
    value: 0.15,
  },
  {
    name: "Radio 10%",
    value: 0.1,
  },
  {
    name: "Student / Teacher Discount 10%",
    value: 0.1,
  },
];
interface PromoAutocompleteProps {
  name: string;
}

export const AutocompletePromo = ({ name }: PromoAutocompleteProps) => {
  return <FormikAutocomplete label="Promo" name={name} options={options} />;
};
