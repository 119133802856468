import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useMemo } from "react";
import { Column, useRowSelect, useTable } from "react-table";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import { CellRenderer } from "../TableViewsPages/CellRenderer";
import { HeaderRenderer } from "../TableViewsPages/HeaderRenderer";
import { AddableItem, addableItems } from "./AddableItemFixtures";
import { AutocompleteAddableItemSelect } from "../FormFields/AutocompleteAddableItemSelect";
import { initialAddableItemRows } from "../Forms/PricingToolForm";
import { pathOr, startsWith } from "ramda";
import DebouncedCurrencyInput from "../FormFields/DebouncedCurrencyInput";
import currency from "currency.js";
import { valToString } from "../../lib/functions";

export interface AddableItemRow extends AddableItem {
  item: AddableItem;
}
interface Props {
  data: AddableItemRow[];
  onRowSelected: (rows) => void;
  resetCount: number;
  setFieldValue: any;
}

const LABOR_BASE = 85;
const DEALERSHIP_RATE = 0.5;
const NON_DEALERSHIP_RATE = 1;

export const AddablePricingTable = ({ data, onRowSelected, resetCount, setFieldValue }: Props) => {
  /* 
  The performance of the table is pretty bad due to formik large form performance which should be fixed in v3 https://github.com/formium/formik/issues/671
  We are betting on v3, which should be released in short tiem, to solve the problem for us. If not, we need to take the state managemnt of the table form in our own hands.
  and then update the formik state to mirror it. This will disable our ability to manually update state via formik, like when we rest, so we likely need to implement some sort of 2 way sink functionality to make it work. what a pain
  Example for controlling table state ourseleves: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/editable-data?file=/src/App.js
  */
  const columns: Column<AddableItemRow>[] = useMemo(
    () => [
      {
        Header: "Item Select",
        accessor: "item",
        width: 125,
        Cell: ({ row: { index } }) => {
          return (
            <AutocompleteAddableItemSelect
              items={addableItems}
              name={`addableItemRows[${index}].item`}
              postOnChange={(item) => {
                if (item) {
                  const setObj = {
                    name: pathOr("", ["value", "name"], item),
                    labor: pathOr("", ["value", "labor"], item),
                    partsCost: pathOr("", ["value", "partsCost"], item),
                    laborHours: pathOr("", ["value", "laborHours"], item),
                    discount: pathOr("", ["value", "discount"], item),
                  };
                  setFieldValue(`addableItemRows[${index}]`, setObj);
                } else {
                  const nullObj = {
                    name: "",
                    parts: "",
                    labor: "",
                    partsCost: "",
                    discount: "",
                    laborHours: "",
                  };
                  setFieldValue(`addableItemRows[${index}]`, nullObj);
                }
              }}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        width: 200,
        Cell: ({ row: { index } }) => (
          <DebouncedTextField name={`addableItemRows[${index}].name`} label="" required={true} />
        ),
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
        width: 65,
      },
      {
        Header: "Base Price",
        accessor: "price",
        width: 65,
      },
      {
        Header: "Parts",
        accessor: "parts",
        width: 65,
      },
      {
        Header: "Labor",
        accessor: "labor",
        width: 65,
        Cell: ({ value }) => <p>{valToString(currency(value))}</p>,
      },
      {
        Header: "Parts Cost",
        accessor: "partsCost",
        width: 65,
        Cell: ({ row }) => {
          const { index } = row;
          return (
            <DebouncedCurrencyInput
              name={`addableItemRows[${index}].partsCost`}
              label=""
              postOnChange={(value: string) => {
                const name = pathOr("", ["values", "name"], row);
                const baseValue = currency(value);
                const parts = startsWith("Dealership", name)
                  ? baseValue.add(baseValue.multiply(DEALERSHIP_RATE))
                  : baseValue.add(baseValue.multiply(NON_DEALERSHIP_RATE));
                setFieldValue(`addableItemRows[${index}].parts`, parts.toString());
              }}
            />
          );
        },
      },
      {
        Header: "Labor Hours",
        accessor: "laborHours",
        width: 50,
        Cell: ({ row: { index } }) => (
          <DebouncedCurrencyInput
            name={`addableItemRows[${index}].laborHours`}
            label=""
            required={true}
            postOnChange={(value: string) => {
              setFieldValue(`addableItemRows[${index}].labor`, currency(value).multiply(LABOR_BASE).toString());
            }}
          />
        ),
      },
      {
        Header: "Discount",
        accessor: "discount",
        width: 50,
        Cell: ({ row: { index } }) => <DebouncedCurrencyInput name={`addableItemRows[${index}].discount`} label="" />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, data]
  );
  const useTableProps = useTable<AddableItemRow>(
    {
      columns,
      data,
      // @ts-ignore
      autoResetSelectedRows: false,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: HeaderRenderer,
          width: 25,
          Cell: CellRenderer,
        },
        ...columns,
      ]);
    }
  );
  const {
    getTableProps,
    headerGroups,
    rows,
    getTableBodyProps,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTableProps;
  useEffect(() => {
    if (resetCount) {
      toggleAllRowsSelected(false);
      // TODO: this is broken, the Item drop down WILL NOT reset for some reason
      setFieldValue("addableItemRows", initialAddableItemRows);
    }
  }, [resetCount, setFieldValue, toggleAllRowsSelected]);
  useEffect(() => {
    onRowSelected(selectedFlatRows);
  }, [onRowSelected, selectedFlatRows]);
  return (
    <Paper>
      <TableContainer>
        <Table {...getTableProps()} stickyHeader size={"small"} padding={"none"}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { width } = column;
                  return (
                    <TableCell width={width} {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map((cell) => {
                    const width = cell.column.width;
                    return (
                      <TableCell width={width} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
