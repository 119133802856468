import React from "react";
import { Product } from "../../generated/nest-graphql";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

export const ProductDetailsSection: React.FC<{ product: Product }> = ({
  product,
}) => {
  if (!product) {
    return null;
  }
  const { name, laborCost, partsCost, amount, description } = product;
  const ProductRow = ({ label, value }) => {
    return (
      <div className="flex flex-row">
        <div className="font-bold mr-1">{label}:</div>
        {value}
      </div>
    );
  };
  return (
    <Box paddingRight={6}>
      Product Details:
      <Paper variant="outlined">
        <Box padding={2}>
          <ProductRow label={"Name"} value={name} />
          <ProductRow label={"Labor Cost"} value={laborCost} />
          <ProductRow label={"Parts Cost"} value={partsCost} />
          <ProductRow label={"Amount"} value={amount} />
          <ProductRow label={"Description"} value={description} />
        </Box>
      </Paper>
    </Box>
  );
};
