import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { filterDropDownStyles } from "../../material-ui-styles";
import { useField } from "formik";

type OptionType = {
  value: any;
  label: string;
};
export const FilterSelectFieldV2: React.FC<{
  name: string;
  options: OptionType[];
  label: string;
}> = ({ label, options, name }) => {
  const classes = filterDropDownStyles();
  const [{ value }, , helper] = useField(name);
  return (
    <FormControl className={classes.root} variant="filled">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={(e) => {
          helper.setValue(e.target.value);
        }}
      >
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
        {options.map((val, i) => (
          <MenuItem key={i} value={val.value}>
            {val.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
