import React from "react";
import { GET_PRODUCT } from "../../graphql/queries/getProduct";
import { Mutation, MutationUpdateProductArgs, Query, QueryGetProductArgs } from "../../generated/nest-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { ProductDetailsForm } from "../Forms/ProductDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import { keys } from "ramda";
import { UPDATE_PRODUCT } from "../../graphql/mutations/updateProduct";
import { useShowSuccess } from "../../redux/slices/snackbar";

export const ProductDetails: React.FC<{ productId: string }> = ({ productId }) => {
  const { data } = useQuery<Query, QueryGetProductArgs>(GET_PRODUCT, {
    variables: {
      id: productId,
    },
  });
  const [updateProduct] = useMutation<Mutation, MutationUpdateProductArgs>(UPDATE_PRODUCT);
  const showSuccess = useShowSuccess();
  if (!data) return null;
  const { partsCost, amount, description, laborCost, name } = data.getProduct;
  const initialData = { partsCost, amount, description, laborCost, name };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <ProductDetailsForm
          onSubmit={async (values) => {
            const diff = objectDiff(values, initialData);
            const updates = cleanObject(diff);
            if (keys(updates).length) {
              await updateProduct({
                variables: {
                  id: productId,
                  updateProductInput: updates,
                },
              });
              showSuccess({ message: "Successfully Updated Product" });
            }
          }}
          initialValues={initialData}
        />
      </div>
    </div>
  );
};
