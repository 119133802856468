import React from "react";
import { ProductsTable } from "../../components/Products/ProductsTable";

const ProductsPage = () => {
  return (
    <>
      <div className="px-8 py-4">
        <div>
          <ProductsTable />
        </div>
      </div>
    </>
  );
};
export default ProductsPage;
