import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Modal from "react-modal";
import { NuModal } from "../NuModal";
import { useToggle } from "../../hooks/useToggle";
import { AddCreditCardForm } from "../Forms/AddCreditCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../App";

export const AddCreditCard: React.FC<{ contactId?: string; tableKey: number; setKey: (key: number) => void }> = ({
  contactId,
  tableKey,
  setKey,
}) => {
  Modal.setAppElement("#root");
  const [open, , toggle] = useToggle(false);
  return (
    <>
      <Tooltip title={"Add Credit Card"}>
        <IconButton onClick={toggle}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <NuModal title={"Add Credit Card"} isOpen={open}>
        <Elements stripe={stripePromise}>
          <AddCreditCardForm
            toggle={() => {
              setKey(tableKey + 1);
              toggle();
            }}
            contactId={contactId}
          />
        </Elements>
      </NuModal>
    </>
  );
};
