import React, { ReactElement, useEffect, useMemo } from "react";
import { useRowSelect, useTable } from "react-table";
import { HeaderRenderer } from "../TableViewsPages/HeaderRenderer";
import { CellRenderer } from "../TableViewsPages/CellRenderer";
import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { FormikAutocomplete } from "../FormFields/FormikAutocomplete";
import { partsOptions } from "./PricingToolConstants";
import { PricingTableDataRow } from "./usePricingToolTableData";

export interface PricingTableProps {
  data: PricingTableDataRow[];
  onRowSelected: (rows) => void;
  resetCount: number;
}

export default function PricingTable({ data, onRowSelected, resetCount }: PricingTableProps): ReactElement {
  const columns: any = useMemo(() => {
    // add this so that eslint suggests it as a useMemo dependency because it is needed for the table to work correctly and without it eslint suggests removing from dependency array
    if (data) {
    }
    const itemOptionNames = [
      "options.frontPads",
      "options.frontRotors",
      "options.rearPads",
      "options.rearRotors",
      "options.shoes",
      "options.drums",
    ];
    return [
      {
        accessor: "item",
      },
      {
        Header: "Option",
        accessor: "option",
        Cell: ({ row: { index } }) => {
          return <FormikAutocomplete label={null} name={itemOptionNames[index]} options={partsOptions} />;
        },
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
      },
      {
        Header: "Base Price",
        accessor: "basePrice",
      },
      {
        Header: "Parts",
        accessor: "parts",
      },
      {
        Header: "Labor",
        accessor: "labor",
      },
      {
        Header: "Discounts",
        accessor: "discount",
      },
      { Header: "Notes", accessor: "notes" },
      { Header: "Brand", accessor: "brand" },
    ];
  }, [data]);
  const useTableProps = useTable<PricingTableDataRow>(
    {
      columns,
      data,
      // @ts-ignore
      autoResetSelectedRows: false,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: HeaderRenderer,
          Cell: CellRenderer,
        },
        ...columns,
      ]);
    }
  );
  const {
    getTableProps,
    headerGroups,
    rows,
    getTableBodyProps,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTableProps;
  useEffect(() => {
    if (resetCount) {
      toggleAllRowsSelected(false);
    }
  }, [resetCount, toggleAllRowsSelected]);
  useEffect(() => {
    onRowSelected(selectedFlatRows);
  }, [onRowSelected, selectedFlatRows]);
  return (
    <Paper>
      <TableContainer>
        <Table {...getTableProps()} stickyHeader size={"small"} padding="none">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>{column.render("Header")}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
