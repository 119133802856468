import * as React from "react";
import { TabItem } from "../TabView/TabItem";
import { JobDetailsContainer } from "./JobDetailsContainer";

export const JobsTabView: React.FC<{ jobId: string }> = ({ jobId }) => {
  return (
    <div className={"mt-8"}>
      <ul className={"flex border-b"}>
        <TabItem href={`/jobs/${jobId}`}>Job</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=invoice`}>Invoice</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=files`}>Files</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=contact`}>Contact</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=feedback`}>Feedback</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=checklist`}>Checklist</TabItem>
        <TabItem href={`/jobs/${jobId}?tab=stripeCard`}>Credit Card on hold</TabItem>
      </ul>
      <JobDetailsContainer jobId={jobId} />
    </div>
  );
};
