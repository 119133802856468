import gql from "graphql-tag";

export const GET_FIRST_NAME_LAST_NAME = gql`
  query getFirstNameLastName($id: ID!) {
    getContact(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
