import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationPerformActionArgs, Query, QueryGetActionsArgs } from "../../generated/nest-graphql";
import { GET_ACTIONS } from "../../graphql/queries/getActions";
import { propOr } from "ramda";
import { ButtonThin } from "../Buttons/ButtonThin";
import { PERFORM_ACTION } from "../../graphql/mutations/performAction";
import { useShowSuccess } from "../../redux/slices/snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { path } from "ramda";
import FormHelperText from "@material-ui/core/FormHelperText";

export const ActionsSelect: React.FC<{
  disabledReason?: string;
  entityName: string;
  entityId: string;
  isDisabled?: boolean;
}> = ({ entityName, entityId, isDisabled = false, disabledReason }) => {
  const [isLoading, setIsLoading] = useState(false);
  const showSuccess = useShowSuccess();
  const { data } = useQuery<Query, QueryGetActionsArgs>(GET_ACTIONS, {
    variables: {
      entityName,
    },
  });
  const [performAction] = useMutation<Mutation, MutationPerformActionArgs>(PERFORM_ACTION);
  const onPerformAction = async () => {
    if (value && value !== "") {
      setIsLoading(true);
      await performAction({
        variables: {
          performActionInput: {
            actionName: value,
            entityId: entityId,
            entityName,
          },
        },
      });
      setIsLoading(false);
      showSuccess({ message: "Action Successfully Run" });
    }
  };
  const [value, setValue] = useState("");
  const onChange = (event: object) => {
    const value = path<string>(["target", "value"], event);
    setValue(value);
  };

  if (!data) return null;

  const options = data.getActions.map((action) => {
    const actionName = action?.name;
    const valLabels = {
      sendOnWay: "Send On Way",
      technicianSendOnWay: "Technician Send On Way",
      startJob: "Start Job",
      sendBookingEmail: "Re-send Booking Confirmation Email",
      sendRescheduleBookingEmail: "Re-send Job Rescheduled Email ",
      sendUpdateEstimateBookingEmail: "Send Estimate Updated Email",
      sendInEmail: "Send In Email",
      sendReceipt: "Send Receipt",
    };

    const label = propOr("Default Label", action.name, valLabels);

    return {
      value: actionName,
      label,
    };
  });

  return (
    <div className="flex flex-row items-center mb-3">
      <div className={"flex-1 mr-2"}>
        <FormControl fullWidth variant="outlined" disabled={isDisabled}>
          <InputLabel> Actions </InputLabel>
          <Select label={"Actions"} onChange={onChange} value={value}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {options.map((val: any, i) => (
              <MenuItem key={i} value={val.value}>
                {val.label}
              </MenuItem>
            ))}
          </Select>
          {isDisabled && <FormHelperText>{disabledReason}</FormHelperText>}
        </FormControl>
      </div>
      <div className="mt-0">
        <ButtonThin disabled={isDisabled} onClick={onPerformAction} isSubmitting={isLoading} type={"button"}>
          Do Action
        </ButtonThin>
      </div>
    </div>
  );
};
