import React, { useState } from "react";
import { includes, keys, path } from "ramda";
import Box from "@material-ui/core/Box";
import { useQuery } from "@apollo/client";
import { Query, Scalars } from "../../generated/nest-graphql";
import { GET_JOBS_COUNT_BY_RANGE } from "../../graphql/queries/getJobCountByRange";
import { GET_CONTACTS_COUNT_BY_RANGE } from "../../graphql/queries/getContactCountByRange";
import { GET_INVOICE_SALES_BY_RANGE } from "../../graphql/queries/getInvoiceSalesByRange";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DateInputNonFormik as DateInput } from "../FormFields/DateInput";
import {
  endOfMonth,
  endOfToday,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  startOfDay,
  endOfDay,
} from "date-fns";
export type QueryGetByRangeArgs = {
  startRange: Scalars["Date"];
  endRange: Scalars["Date"];
};
const SummaryDatesRange: any = {
  YESTERDAY: { startRange: startOfYesterday(), endRange: endOfYesterday() },
  TODAY: { startRange: startOfToday(), endRange: endOfToday() },
  CURRENT_MONTH: {
    startRange: startOfMonth(new Date()),
    endRange: endOfMonth(new Date()),
  },
};

export const SummaryTabPanel: React.FC<{
  dateRange: string;
  activeTabValue: number;
  index: number;
}> = ({ dateRange, activeTabValue, index }) => {
  const [startRange, setStartRange] = useState(
    SummaryDatesRange[dateRange].startRange
  );
  const [endRange, setEndRange] = useState(
    SummaryDatesRange[dateRange].endRange
  );
  const queryParams = { variables: { startRange, endRange } };

  const { data: jobData, loading: jobLoading } = useQuery<
    Query,
    QueryGetByRangeArgs
  >(GET_JOBS_COUNT_BY_RANGE, queryParams);
  const { data: contactData, loading: contactLoading } = useQuery<
    Query,
    QueryGetByRangeArgs
  >(GET_CONTACTS_COUNT_BY_RANGE, queryParams);
  const { data: salesData, loading: salesLoading } = useQuery<
    Query,
    QueryGetByRangeArgs
  >(GET_INVOICE_SALES_BY_RANGE, queryParams);

  if (!includes(dateRange, keys(SummaryDatesRange))) {
    return null;
  }
  return (
    <div
      role="tabpanel"
      hidden={activeTabValue !== index}
      id={`summary-tabpanel-${index}`}
      aria-labelledby={`summary-tab-${index}`}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box m={1}>
          <DateInput
            value={startRange}
            label=""
            postChange={(date: Date) => {
              setStartRange(startOfDay(date));
              if (date > endRange) setEndRange(endOfDay(date));
            }}
          />
        </Box>
        <Box m={1}> - </Box>
        <Box m={1}>
          <DateInput
            value={endRange}
            label=""
            postChange={(date: Date) => {
              setEndRange(endOfDay(date));
              if (date < startRange) setStartRange(startOfDay(date));
            }}
          />
        </Box>
      </Box>
      <Box display="flex" p={1} bgcolor="background.paper">
        <Box p={1} flexGrow={1}>
          Jobs Added
        </Box>
        <Box p={1}>
          {jobLoading ? (
            <CircularProgress />
          ) : (
            path(["getJobCountByRange"], jobData)
          )}
        </Box>
      </Box>
      <Box display="flex" p={1} bgcolor="background.paper">
        <Box p={1} flexGrow={1}>
          Customers Added
        </Box>
        <Box p={1}>
          {contactLoading ? (
            <CircularProgress />
          ) : (
            path(["getContactCountByRange"], contactData)
          )}
        </Box>
      </Box>
      <Box display="flex" p={1} bgcolor="background.paper">
        <Box p={1} flexGrow={1}>
          Gross Sales
        </Box>
        <Box p={1}>
          {salesLoading ? (
            <CircularProgress />
          ) : (
            path(["getInvoiceSalesByRange"], salesData)
          )}
        </Box>
      </Box>
    </div>
  );
};
