import React from "react";
import { Box, Typography } from "@material-ui/core";
import CurrencyInput from "./CurrencyInput";

interface Props {
  label: string;
  name: string;
}

export const MarketPricingField = ({ label, name }: Props) => {
  return (
    <Box className="grid grid-cols-2 gap-2 place-items-center bg-gray-900">
      <Typography>{label}</Typography>
      <CurrencyInput name={name} label={"Increase/Decrease"} placeHolder={"-10.00"} />
    </Box>
  );
};
