import React from "react";
import { useField } from "formik";
import { map, prop, find, where, propEq, isNil } from "ramda";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { Product } from "../../generated/nest-graphql";

export type ProductOptionType = {
  label: string;
  value: Product;
};

export const ProductSelectTypeAhead: React.FC<{
  products: Product[];
  postOnChange?: (value: ProductOptionType) => void;
  required: boolean;
  name: string;
  label: string;
}> = ({ name, products, postOnChange, label, required }) => {
  const [field, meta, helper] = useField(name);
  const productToOptions = (product: Product) => {
    return {
      label: product.name,
      value: product,
    };
  };

  const productOptions = map(productToOptions, products);

  const filterOptions = createFilterOptions<ProductOptionType>({
    matchFrom: "start",
    stringify: (option) => {
      return option.label;
    },
  });
  const onChange = (_: object, value: ProductOptionType) => {
    const selectedValue = prop("value", value);
    helper.setValue(prop("id", selectedValue));
    postOnChange && postOnChange(value);
  };
  const currentOption = !isNil(field.value)
    ? find(where({ value: propEq("id", field.value) }), productOptions)
    : null;

  if (productOptions.length === 0) return null;
  return (
    <Autocomplete
      getOptionSelected={(option, value) =>
        prop("label", option) === prop("label", value)
      }
      getOptionLabel={(option) => prop("label", option) || ""}
      value={currentOption}
      options={productOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      disableClearable
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          helperText={
            <ErrorTextField
              displayOnSpan={true}
              fieldName={field.name}
              displayError={!!meta.error}
            />
          }
        />
      )}
    />
  );
};
