import gql from "graphql-tag";

const serviceCatalogueReturnFields = `
                id
                name
                technicianPay
                products {
                    id
                    type
                    defaultUnits
                    behavior
                    category
                    defaultCustomerPrice
                }`;

export const GET_SERVICE_CATALOGUE = gql`query getServiceCatalogue {
    getServiceCatalogue {
        ${serviceCatalogueReturnFields}
    }
}`;
