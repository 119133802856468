import * as React from "react";
import { Form, Formik } from "formik";
import { MaterialTextField } from "../FormFields/MaterialTextField";
import { SubmitButton } from "../Buttons/SubmitButton";
import { Grid } from "@material-ui/core";
import { Mutation, MutationCreateFeedbackArgs } from "../../generated/nest-graphql";
import { useMutation } from "@apollo/client";
import { CREATE_FEEDBACK } from "../../graphql/mutations/createFeedback";
import { useShowSuccess } from "../../redux/slices/snackbar";

export type FeedbackFormValues = {
  appName: string;
  feedback: string;
};
export const FeedbackForm: React.FC<{ toggle: () => void }> = ({ toggle }) => {
  const showSuccess = useShowSuccess();
  const [createFeedback] = useMutation<Mutation, MutationCreateFeedbackArgs>(CREATE_FEEDBACK);
  const onSubmit = async (values: FeedbackFormValues) => {
    await createFeedback({
      variables: {
        createFeedbackInput: values,
      },
    });
    showSuccess({ message: "Success" });
    toggle();
  };
  return (
    <Formik<FeedbackFormValues> initialValues={{ appName: "NuBrakes Ops Frontend", feedback: "" }} onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MaterialTextField multiline variant={"outlined"} fullWidth name={"feedback"} label={"Feedback"} />
            </Grid>
            <Grid item xs={12}>
              <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                Submit Feedback
              </SubmitButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
