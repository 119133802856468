import React, { createContext, useContext, useState } from "react";
import { Technician } from "../generated/nest-graphql";

export type InvoicesFilterState = {
  status: string;
  issuedDateStartDate: Date;
  issuedDateEndDate: Date;
  market: string;
  technician: Technician;
};
const initialState: InvoicesFilterState = {
  status: null,
  issuedDateStartDate: null,
  issuedDateEndDate: null,
  market: null,
  technician: null,
};

const InvoicesFilterContext = createContext<{
  state: InvoicesFilterState;
  updateInvoicesFilter: (invoicesFilterValues: InvoicesFilterState) => void;
}>({
  state: initialState,
  updateInvoicesFilter: (invoicesFilterValues) => {},
});

export const InvoicesFilterProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const updateInvoicesFilter = (invoicesFilterValues) => {
    setState({
      ...invoicesFilterValues,
    });
  };
  return (
    <InvoicesFilterContext.Provider value={{ state, updateInvoicesFilter }}>{children}</InvoicesFilterContext.Provider>
  );
};

export const useInvoicesFilter = () => useContext(InvoicesFilterContext);
