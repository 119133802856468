import * as React from "react";
import { useField } from "formik";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { TextField, Checkbox } from "@material-ui/core";
import { ErrorTextField } from "./ErrorTextField";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutoCompleteCheckboxes: React.FC<{
  name: string;
  options: string[];
  label: string;
}> = ({ name, options, label }) => {
  const [field, meta, helper] = useField(name);
  const { onChange, value, ...rest } = field;
  return (
    <>
      <Autocomplete
        {...rest}
        value={value ?? []}
        multiple
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option: string) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
        onChange={(_, newValue: string[]) => helper.setValue(newValue)}
      />
      <ErrorTextField fieldName={name} displayError={!!meta.error} />
    </>
  );
};

export const NonFormikAutoCompleteCheckboxes: React.FC<{
  value: string[];
  options: string[];
  label: string;
  disabled?: boolean;
}> = ({ value, options, label, disabled = false }) => {
  return (
    <>
      <Autocomplete
        value={value}
        disabled={disabled}
        multiple
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option: string) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
      />
    </>
  );
};
