import * as React from "react";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { useField } from "formik";

export const MaterialTextField: React.FC<TextFieldProps & { name: string }> = ({
  name,
  ...rest
}) => {
  const [field] = useField(name);

  return <TextField {...field} {...rest} />;
};
