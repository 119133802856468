import * as React from "react";
import { cond, contains, defaultTo, path, T } from "ramda";
import { JobDetails } from "./JobDetails";
import { JobInvoices } from "./JobInvoices";
import { JobRating } from "./JobRating";
import { JobStripeCard } from "./JobStripeCard";
import { JobChecklist } from "./JobChecklist";
import { useQuery } from "@apollo/client";
import { GET_JOB } from "../../graphql/queries/getJob";
import { Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ContactsFiles } from "../Contacts/ContactsFiles";
import { ContactDetails } from "../Contacts/ContactDetails";
import { Contact } from "../../generated/nest-graphql";

export const JobDetailsContainer: React.FC<{ jobId: string }> = ({ jobId }) => {
  const { data, loading } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  const queryParams = useQueryParams();
  const tab = defaultTo("", queryParams.get("tab"));
  const contact = path<Contact>(["getJob", "contact"], data);
  const preJobCheckList = path<any>(["getJob", "preJobCheckList"], data);
  const feedback = path<any>(["getJob", "feedback"], data);
  return (
    <div className={"p-4"}>
      {cond([
        [contains("job"), () => <JobDetails jobId={jobId} />],
        [contains("invoice"), () => <JobInvoices jobId={jobId} />],
        [contains("files"), () => !loading && data && <FilesTab contact={contact} />],
        [contains("contact"), () => !loading && data && <ContactTab contact={contact} />],
        [contains("feedback"), () => !loading && data && <JobRating feedback={feedback} />],
        [contains("checklist"), () => !loading && data && <JobChecklist preJobCheckList={preJobCheckList} />],
        [contains("stripeCard"), () => !loading && data && <JobStripeCard jobId={jobId} />],
        [T, () => <JobDetails jobId={jobId} />],
      ])(tab)}
    </div>
  );
};

const FilesTab: React.FC<{ contact: Contact }> = ({ contact }) => {
  if (!contact) {
    return <div>Job must have a contact to upload files</div>;
  }

  return <ContactsFiles contactId={contact.id} />;
};

const ContactTab: React.FC<{ contact: Contact }> = ({ contact }) => {
  if (!contact) {
    return <div>Job does not have a contact</div>;
  }

  return <ContactDetails contactId={contact.id} />;
};
