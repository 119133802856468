import { useMutation } from "@apollo/client";
import { assoc, has, keys, path, pipe, prop } from "ramda";
import { Settings, DateTime } from "luxon";
import { flow } from "fp-ts/lib/function";
import * as React from "react";
import {
  Appointment,
  Mutation,
  MutationCreateAppointmentArgs,
  MutationUpdateAppointmentArgs,
  MutationCreateRescheduleArgs,
  PartsStore,
  MutationCreateRescheduleV2Args,
  PossibleEjiService,
  EjiService,
} from "../../generated/nest-graphql";
import { CREATE_RESCHEDULE_V2 } from "../../graphql/mutations/createRescheduleV2";
import { CREATE_APPOINTMENT } from "../../graphql/mutations/createAppointment";
import { UPDATE_APPOINTMENT } from "../../graphql/mutations/updateAppointment";
import { useToggle } from "../../hooks/useToggle";
import { formatDateTime } from "../../lib/functions";
import { Button } from "../Buttons/Button";
import { ReadOnlyItem } from "../FormFields/ReadOnlyItem";
import { NuModal } from "../NuModal";
import { technicianCopySpec } from "../specs/technicianCopySpec";
import { appointmentDetailsSpec } from "../specs/appointmentDetailsSpec";
import { AppointmentFormValues } from "./AppointmentForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import SuggestionFormSection from "./SuggestionFormSection";

type AppointmentFormSectionProps = {
  parentSubmit: any;
  parentValidateForm: any;
  parentSetFieldValue: any;
  appointment: Appointment;
  jobName: string;
  jobNumber: string;
  jobId?: string;
  market: string;
  timeZone: string;
  serviceLocation: string;
  rescheduleReason?: string;
  itemPartsStores: PartsStore[];
  services: PossibleEjiService[] | EjiService[];
  partsOrdered: boolean;
  contactId?: string;
  estimateId?: string;
};
export const AppointmentFormSection: React.FC<AppointmentFormSectionProps> = ({
  partsOrdered,
  appointment,
  jobName,
  jobNumber,
  jobId,
  parentSetFieldValue,
  parentValidateForm,
  parentSubmit,
  market,
  timeZone,
  itemPartsStores,
  services,
  serviceLocation,
  contactId,
  estimateId,
}) => {
  // Sets the time zone globally for Luxon. Use in conjucction with .toFormat(to render the times correctly)
  Settings.defaultZoneName = timeZone;
  const [createRescheduleV2] = useMutation<Mutation, MutationCreateRescheduleV2Args>(CREATE_RESCHEDULE_V2);
  const [createAppointment] = useMutation<Mutation, MutationCreateAppointmentArgs>(CREATE_APPOINTMENT);
  const [updateAppointment] = useMutation<Mutation, MutationUpdateAppointmentArgs>(UPDATE_APPOINTMENT);
  const [createModalIsOpen, , toggleCreateModal] = useToggle();
  const [updateModalIsOpen, , toggleUpdateModal] = useToggle();

  if (appointment) {
    const initialValues = {
      technician: appointment.technician!,
      allDay: appointment.allDay!,
      id: appointment.id!,
      subject: appointment.subject!,
      endDate: new Date(appointment.endDate),
      startDate: new Date(appointment.startDate),
      timeZone: timeZone,
      startTimeWindow: new Date(path(["timeWindow", "startTimeWindow"], appointment)),
      endTimeWindow: new Date(path(["timeWindow", "endTimeWindow"], appointment)),
      job: jobId ?? null,
      overrideOutsideServiceZone: false,
      startDateChanged: false,
      rescheduleReason: null,
    };
    const onSubmitEdit = async (formValues: AppointmentFormValues) => {
      const diff: any = objectDiff(formValues, initialValues);
      const technicianId = path(["technician", "id"], formValues);
      const updates: any = pipe(appointmentDetailsSpec, cleanObject, assoc("technician", technicianId))(diff);
      if (keys(updates).length) {
        if (has("startDate", updates)) {
          await createRescheduleV2({
            variables: {
              rescheduleInput: {
                appointmentId: appointment.id,
                appointmentUpdates: updates as any,
                jobNumber,
                originalDates: {
                  startDate: prop("startDate", initialValues),
                  endDate: prop("endDate", initialValues),
                },
                newDates: {
                  startDate: prop("startDate", formValues),
                  endDate: prop("endDate", formValues),
                },
                rescheduleReason: prop("rescheduleReason", formValues) ?? "",
              },
            },
          });
        } else {
          await updateAppointment({
            variables: {
              id: appointment.id,
              updateAppointmentInput: updates,
            },
          });
        }
      }
      if (!partsOrdered) {
        // TODO:  might need to do a service catalogue used check here
        itemPartsStores.forEach((partsStore, idx) => {
          parentSetFieldValue(`items.${idx}.partsStore`, formValues?.technician?.homePartsStore);
        });
        services?.forEach((service, serviceIdx) => {
          service.items?.forEach((item, itemIdx) => {
            if (item.category === "Part") {
              parentSetFieldValue(
                `services.${serviceIdx}.items.${itemIdx}.orderItem.partsStore`,
                formValues?.technician?.homePartsStore
              );
            }
          });
        });
      }
      await parentSubmit();
      toggleUpdateModal();
    };
    return (
      <>
        <AppointmentReadOnlySection appointment={appointment} onEditClick={toggleUpdateModal} />
        <NuModal isOpen={updateModalIsOpen} maxWidth="md" title="Edit Appointment">
          <SuggestionFormSection
            appointmentId={appointment?.id}
            scheduledDate={new Date(appointment.startDate)}
            scheduledDuration={Math.round(
              DateTime.fromISO(appointment.endDate).diff(DateTime.fromISO(appointment.startDate), ["minutes"]).minutes
            )}
            scheduledTechnician={appointment.technician}
            onCancel={toggleUpdateModal}
            market={market}
            jobId={jobId}
            serviceLocation={serviceLocation}
            timeZone={timeZone}
            jobName={jobName}
            contactId={contactId}
            estimateId={estimateId}
            onSubmit={(event) => {
              event.preventDefault?.();
              event.stopPropagation?.();
              return onSubmitEdit(event);
            }}
          />
        </NuModal>
      </>
    );
  }
  const createAppointmentOnSubmit = async ({
    allDay,
    technician,
    job,
    endDate,
    startDate,
    startTimeWindow,
    endTimeWindow,
    subject,
  }: AppointmentFormValues) => {
    const result = await createAppointment({
      variables: {
        createAppointmentInput: {
          job,
          technician: prop("id", technician!),
          allDay,
          endDate,
          timeWindow: {
            startTimeWindow,
            endTimeWindow,
          },
          technicianCopy: technicianCopySpec(technician!),
          startDate,
          subject,
          timeZone,
        },
      },
    });
    parentSetFieldValue("appointmentId", result?.data?.createAppointment?.id);
    if (!partsOrdered) {
      // TODO:  might need to do a service catalogue used check here
      itemPartsStores.forEach((partsStore, idx) => {
        parentSetFieldValue(`items.${idx}.partsStore`, technician?.homePartsStore);
      });
      services?.forEach((service, serviceIdx) => {
        service.items?.forEach((item, itemIdx) => {
          if (item.category === "Part") {
            parentSetFieldValue(
              `services.${serviceIdx}.items.${itemIdx}.orderItem.partsStore`,
              technician?.homePartsStore
            );
          }
        });
      });
    }
    await parentSubmit();
    toggleCreateModal();
  };
  const onCreateClick = async () => {
    const parentErrors = await parentValidateForm();
    if (keys(parentErrors).length === 0) {
      toggleCreateModal();
    }
  };
  return (
    <div>
      <Button onClick={onCreateClick}>Add Appointment</Button>
      <NuModal isOpen={createModalIsOpen} maxWidth="md" title="Add Appointment">
        <SuggestionFormSection
          onCancel={toggleCreateModal}
          market={market}
          jobId={jobId}
          serviceLocation={serviceLocation}
          timeZone={timeZone}
          jobName={jobName}
          contactId={contactId}
          estimateId={estimateId}
          onSubmit={(event) => {
            event.preventDefault?.();
            event.stopPropagation?.();
            return createAppointmentOnSubmit(event);
          }}
        />
      </NuModal>
    </div>
  );
};

export const AppointmentReadOnlySection: React.FC<{
  appointment: Appointment;
  onEditClick: any;
}> = ({ appointment, onEditClick }) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <ReadOnlyItem label={"Subject"} value={appointment.subject!} />
      <ReadOnlyItem
        label={"Technician"}
        value={`${path(["technician", "firstName"], appointment)} ${path(["technician", "lastName"], appointment)}`}
      />
      <Button type={"button"} onClick={onEditClick}>
        {" "}
        Edit Appointment{" "}
      </Button>
      <ReadOnlyItem label={"Start Date"} value={formatDateTime(appointment.startDate)!} />
      <ReadOnlyItem label={"End Date"} value={formatDateTime(appointment.endDate)!} />
      <div className="grid grid-cols-2 gap-2">
        <ReadOnlyItem label={"All Day?"} value={String(appointment.allDay)} />
        <ReadOnlyItem label={"Time Zone"} value={prop("timeZone", appointment)!} />
      </div>
      {prop("timeWindow", appointment) && (
        <>
          <ReadOnlyItem
            label={"Start Time Window"}
            value={flow(path(["timeWindow", "startTimeWindow"]), formatDateTime)(appointment)!}
          />
          <ReadOnlyItem
            label={"End Time Window"}
            value={flow(path(["timeWindow", "endTimeWindow"]), formatDateTime)(appointment)!}
          />
        </>
      )}{" "}
    </div>
  );
};
