import React from "react";
import { EntityTable } from "../TableViewsPages/EntityTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { remove } from "ramda";
import { Column } from "react-table";
import { Product } from "../../generated/nest-graphql";

export type ProductsTableType = {
  name: string;
  description?: string;
  amount: string;
  laborCost: string;
  partsCost: string;
};

export const ProductsTable: React.FC<{
  products: Product[];
  setFieldValue: any;
  readOnly?: boolean;
}> = ({ products, setFieldValue, readOnly }) => {
  const ItemsColumns: Column<ProductsTableType>[] = [
    {
      Header: "Name",
      accessor: "name",
      width: 250,
    },
    {
      Header: "Description",
      accessor: "description",
      width: 150,
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 50,
    },
    {
      Header: "Labor Cost",
      accessor: "laborCost",
      width: 50,
    },
    {
      Header: "Parts Cost",
      accessor: "partsCost",
      width: 50,
    },
  ];

  const deleteRow = {
    id: "delete",
    Header: <div>Delete</div>,
    Cell: (props: { row: { index: any } }) => {
      return (
        <div
          className="text-center cursor-pointer"
          onClick={() => {
            const index = props.row.index;
            const result = remove(index, 1, products);
            setFieldValue("products", result);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      );
    },
  };

  return (
    <EntityTable<ProductsTableType>
      title={"Products"}
      data={products}
      columns={ItemsColumns}
      pluginHooks={(hooks) => {
        hooks.visibleColumns.push((columns) => [...columns, ...(readOnly ? [] : [deleteRow])]);
      }}
      numRecords={products.length}
    />
  );
};
