import React from "react";
import { JobsTabView } from "../../components/Jobs/JobsTabView";
import { useEntityId } from "../../hooks/useEntityId";

export const JobDetailsPage = () => {
  const jobId = useEntityId();
  return (
    <div>
      <JobsTabView jobId={jobId} />
    </div>
  );
};
export default JobDetailsPage;
