import * as React from "react";
import { cond, contains, defaultTo, path, T } from "ramda";
import { InvoiceDetails } from "./InvoiceDetails";
import { InvoicesPayments } from "./InvoicesPayments";
import { useQuery } from "@apollo/client";
import { Query, QueryGetInvoiceArgs } from "../../generated/nest-graphql";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ContactsFiles } from "../Contacts/ContactsFiles";
import { ContactDetails } from "../Contacts/ContactDetails";
import { Contact } from "../../generated/nest-graphql";

export const InvoiceDetailsContainer: React.FC<{ invoiceId: string }> = ({
  invoiceId,
}) => {
  const { data, loading } = useQuery<Query, QueryGetInvoiceArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });

  const queryParams = useQueryParams();
  const tab = defaultTo("", queryParams.get("tab"));
  const contact = path<Contact>(["getInvoice", "contact"], data);
  return (
    <div className={"p-4"}>
      {cond([
        [contains("invoice"), () => <InvoiceDetails invoiceId={invoiceId} />],
        [contains("payment"), () => <InvoicesPayments invoiceId={invoiceId} />],
        [
          contains("files"),
          () => !loading && data && <FilesTab contact={contact} />,
        ],
        [
          contains("contact"),
          () => !loading && data && <ContactTab contact={contact} />,
        ],
        [T, () => <InvoiceDetails invoiceId={invoiceId} />],
      ])(tab)}
    </div>
  );
};

const FilesTab: React.FC<{ contact: Contact }> = ({ contact }) => {
  if (!contact) {
    return <div>Invoice must have a contact to upload files</div>;
  }

  return <ContactsFiles contactId={contact.id} />;
};

const ContactTab: React.FC<{ contact: Contact }> = ({ contact }) => {
  if (!contact) {
    return <div>Invoice does not have a contact</div>;
  }

  return <ContactDetails contactId={contact.id} />;
};
