import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { filterDropDownStyles } from "../../material-ui-styles";
import { useFormikContext } from "formik";

type OptionType = {
  value: any;
  label: string;
};
export const QuickRangeSelect: React.FC<{
  name: string;
  options: OptionType[];
  label: string;
}> = ({ label, options, name }) => {
  const classes = filterDropDownStyles();
  const { setValues, values } = useFormikContext();
  return (
    <FormControl className={classes.root} variant="filled">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        onChange={(e) => {
          setValues({
            // @ts-ignore
            ...values,
            ...e.target.value,
          });
        }}
      >
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
        {options.map((val, i) => (
          <MenuItem key={i} value={val.value}>
            {val.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
