import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateOpsManagerArgs, CreateUserInput } from "../../generated/nest-graphql";
import { CREATE_OPS_MANAGER } from "../../graphql/mutations/createOpsManager";
import React from "react";
import { Container } from "../../components/Container";
import { AddOpsManagerForm, UserFormValues } from "../../components/Forms/AddOpsManagerForm";
import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { userDetailsSpec } from "../../components/specs/userDetailsSpec";

const AddOpsManager = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [addOpsManager] = useMutation<Mutation, MutationCreateOpsManagerArgs>(CREATE_OPS_MANAGER);
  const onSubmit = async (values: UserFormValues, formikHelpers: FormikHelpers<any>) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      formikHelpers.setFieldError("password", "Passwords do not match");
      formikHelpers.setFieldError("confirmPassword", "Passwords do not match");
      return;
    }

    await addOpsManager({
      variables: {
        createUserInput: userDetailsSpec(values) as CreateUserInput,
      },
    });

    showSuccess({ message: `Successfully Added Ops Manager` });
    await history.push(`/`);
  };

  return (
    <Container>
      <div className="mt-16" />
      <h3>Create a Ops Manager</h3>
      <AddOpsManagerForm
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          createPassword: "",
        }}
        onSubmit={onSubmit}
      />
    </Container>
  );
};
export default AddOpsManager;
