import { useMutation } from "@apollo/client";
import { Box } from "@material-ui/core";
import { FormikHelpers } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../components/Container";
import { PartsStoreForm, PartsStoreFormValues } from "../../components/Forms/PartsStoreForm";
import { partsStoreSpec } from "../../components/specs/partsStoreSpec";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Mutation, MutationCreatePartsStoreArgs, PartsStoreInput, Query } from "../../generated/nest-graphql";
import { CREATE_PARTS_STORE } from "../../graphql/mutations/createPartsStore";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";

export const AddPartsStorePage = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createPartsStore] = useMutation<Mutation, MutationCreatePartsStoreArgs>(CREATE_PARTS_STORE, {
    update(cache, { data }) {
      const newPartsStoreResponse = data?.createPartsStore;
      const query: any = GET_PARTS_STORES;
      const existingPartsStores = cache.readQuery<Query>({ query });
      if (existingPartsStores && newPartsStoreResponse) {
        cache.writeQuery({
          query: GET_PARTS_STORES,
          data: {
            getPartsStores: [...existingPartsStores?.getPartsStores, newPartsStoreResponse],
          },
        });
      }
    },
  });
  const onSubmit = async (values: PartsStoreFormValues, formikHelpers: FormikHelpers<any>) => {
    const input: PartsStoreInput = partsStoreSpec(values);
    const result = await createPartsStore({
      variables: {
        partsStoreInput: input,
      },
    });
    const id = result.data.createPartsStore.id;
    showSuccess({ message: "Successfully Added Parts Store" });
    history.push(`/parts-stores/${id}`);
  };
  return (
    <Box>
      <Container>
        <div className="mt-16" />
        <h3>Create a Parts Store</h3>
        <PartsStoreForm
          initialValues={{
            storeNumber: "",
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </Box>
  );
};
