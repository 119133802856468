import gql from "graphql-tag";

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($createPaymentInput: CreatePaymentInput!, $idempotencyKey: String) {
    createPaymentIntent(createPaymentInput: $createPaymentInput, idempotencyKey: $idempotencyKey) {
      id
      client_secret
    }
  }
`;
