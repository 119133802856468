import React from "react";
import { ProductSetDetails } from "../../components/ProductSets/ProductSetDetails";
import { Container } from "../../components/Container";
import { useEntityId } from "../../hooks/useEntityId";

const ProductSetDetailsPage: React.FC = () => {
  const productSetId = useEntityId();
  return (
    <Container>
      <div className="mt-16" />
      <h3>Update Product Set</h3>
      <ProductSetDetails productSetId={productSetId} />
    </Container>
  );
};

export default ProductSetDetailsPage;
