import { Form, Formik } from "formik";
import { startsWith, defaultTo, propEq, propOr, find } from "ramda";
import * as React from "react";
import { useState } from "react";
import Modal from "react-modal";
import * as Yup from "yup";
import {
  Appointment,
  Contact,
  Technician,
  PartsStore,
  PossibleEjiService,
  EjiService,
  EjiDiscount,
} from "../../generated/nest-graphql";
import { useToggle } from "../../hooks/useToggle";
import { ItemsTableType } from "../Items/ItemsTable";
import { JobNoEstimateModal } from "../Jobs/JobNoEstimateModal";
import { VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { AppointmentFormValues } from "./AppointmentForm";
import { JobDetailsInternalForm } from "./JobDetailsInternalForm";
import { flow, pipe } from "fp-ts/lib/function";
import { useMarkets } from "../../hooks/useMarkets";
import { servicesSchema } from "../../yupSchemas";

const jobDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("Required"),
  contact: Yup.object().required("Required"),
  serviceLocation: Yup.string().required("Required"),
  market: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  jobName: Yup.string().required("Required"),
  withdrawReason: Yup.string().when("status", {
    is: startsWith("Withdraw"),
    then: Yup.string().required("Must Provide a Withdraw Reason"),
    otherwise: Yup.string().nullable(),
  }),
  withdrawExtraInfo: Yup.string().when("withdrawReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  jobCausedWarrantyCheck: Yup.string().when("type", {
    is: (value) => value === "Warranty Check",
    then: Yup.string()
      .length(24)
      .required(
        "Must Provide a JobID of the job that caused the warranty, the job ID is the url of the job in the format ops.nubrakes.com/jobs/<jobid>"
      ),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Warranty")),
    then: Yup.string().required("Must Provide a Warranty Reason"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReasonExtraInfo: Yup.string().when("warrantyCheckReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  services: servicesSchema,
});

export type JobDetailsFormValues = VehicleInfoValues & {
  id?: string;
  technician?: Technician;
  contact: Contact;
  taxable?: boolean;
  email?: string;
  estimate?: string;
  serviceLocation: string;
  status: string;
  withdrawReason?: string;
  withdrawExtraInfo?: string;
  withdrawnAt?: Date;
  type: string;
  market: string;
  appointmentId: string;
  jobName: string;
  privateNotes?: string;
  serviceLocationNotes?: string;
  description?: string;
  items: ItemsTableType[];
  partsOrderNumber?: string;
  partsNotes?: string;
  partsOrderingIssue?: string;
  partsLocation?: string;
  partsStore?: PartsStore;
  partsOrdered?: boolean;
  partsOrderedTimestamp?: Date;
  appointmentInfo?: AppointmentFormValues;
  jobCausedWarrantyCheck?: string;
  warrantyCheckReason?: string;
  warrantyCheckReasonExtraInfo?: string;
  rescheduleReason?: string;
  rescheduleFiledBy?: string;
  serviceCatalogueUsed?: string;
  services: PossibleEjiService[] | EjiService[];
  discounts: EjiDiscount[];
};

export const JobDetailsForm: React.FC<{
  initialValues: JobDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment?: boolean;
  jobNumber?: string;
  checkEstimate?: boolean;
}> = ({ initialValues, onSubmit, jobId, jobNumber, appointment, showAddAppointment = false, checkEstimate }) => {
  const markets = useMarkets();
  Modal.setAppElement("#root");
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const [noEstimateModalOpen, , toggleNoEstimateModalOpen] = useToggle(false);
  const toggleItemModal = () => {
    setItemModalIsOpen(!itemModalIsOpen);
  };

  return (
    <Formik<JobDetailsFormValues>
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        if (checkEstimate && !values.estimate) {
          toggleNoEstimateModalOpen();
        }
        return onSubmit(values, helpers);
      }}
      enableReinitialize={true}
      validationSchema={jobDetailsFormValidationSchema}
    >
      {({ isSubmitting, submitForm, setValues, isValid, resetForm, values, setFieldValue, touched, validateForm }) => {
        const marketTimeZone = pipe(
          markets as any,
          defaultTo([]),
          find(propEq("name", values.market)),
          propOr("America/Chicago", "timeZone")
        );
        return (
          <Form>
            <JobDetailsInternalForm
              timeZone={marketTimeZone as string}
              setValues={setValues}
              values={values}
              touched={touched}
              setFieldValue={setFieldValue}
              jobNumber={jobNumber}
              jobId={jobId}
              appointment={appointment}
              showAddAppointment={showAddAppointment}
              toggleItemModal={toggleItemModal}
              submitForm={submitForm}
              resetForm={resetForm}
              initialValues={initialValues}
              isSubmitting={isSubmitting}
              validateForm={validateForm}
              isValid={isValid}
              itemModalIsOpen={itemModalIsOpen}
            />
            {checkEstimate && (
              <JobNoEstimateModal open={noEstimateModalOpen} toggle={toggleNoEstimateModalOpen} onSubmit={onSubmit} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
