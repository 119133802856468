import * as React from "react";
import { useMutation } from "@apollo/client";
import { Mutation, Payment } from "../../generated/nest-graphql";
import { CANCEL_PAYMENT_INTENT } from "../../graphql/mutations/cancelPaymentIntent";
import { prop } from "ramda";
import { formatDateTime } from "../../lib/functions";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { Button } from "../Buttons/Button";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

export type StripeCard = {
  brand: string;
  exp_month: number;
  exp_year: number;
  country: string;
  last4: string;
  fingerprint?: string;
  funding?: string;
  generated_form?: any;
  checks?: any;
};

export const JobStripeCardDetails: React.FC<{
  jobId: string;
  card: StripeCard;
  cardOnHold: Payment;
  refetch: any;
}> = ({ card, cardOnHold, refetch, jobId }) => {
  const showSuccess = useShowSuccess();
  const [cancelPaymentIntent, { loading }] = useMutation<Mutation>(CANCEL_PAYMENT_INTENT);
  const { id, amount, receivedDate, paymentNumber, status } = cardOnHold;
  const onCancel = async () => {
    await cancelPaymentIntent({
      variables: { paymentId: id },
    });
    await refetch({
      variables: {
        filter: {
          job: jobId,
          status: "on hold",
        },
      },
    });
    showSuccess({ message: "Successfully Canceled Payment Hold" });
  };
  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="col-span-2">
        <DetailViewContainer title={"Credit Card Hold Details"}>
          <div className="grid grid-cols-2 gap-2">
            <div className="grid grid-cols-1 gap-1">
              <div>Status: {status}</div>
              <div>Payment Number: {paymentNumber}</div>
              <div>Received Date: {formatDateTime(receivedDate)}</div>
              <div>Amount Held: {amount}</div>
              <Button disabled={status === "canceled"} onClick={onCancel}>
                {loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : "Cancel Hold"}
              </Button>
            </div>
            <div>
              {card && (
                <>
                  <Typography variant="h6" display="block" gutterBottom>
                    Credit Card
                  </Typography>
                  <StripeCardDisplay
                    brand={prop("brand", card)}
                    country={prop("country", card)}
                    expiration={`${prop("exp_month", card)}/${prop("exp_year", card)}`}
                    last4={prop("last4", card)}
                  />
                </>
              )}
            </div>
          </div>
        </DetailViewContainer>
      </div>
    </div>
  );
};

export const StripeCardDisplay = ({ brand, country, expiration, last4 }) => {
  return (
    <Paper elevation={2}>
      <div className="p-2">
        <div>Brand: {brand}</div>
        <div>Country: {country}</div>
        <div>Expiration: {expiration}</div>
        <div>last4: {last4}</div>
      </div>
    </Paper>
  );
};
