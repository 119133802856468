import React from "react";
import { useQuery } from "@apollo/client";
import { Product, Query } from "../../generated/nest-graphql";
import { GET_PRODUCT_SETS } from "../../graphql/queries/getProductSets";
import { AddToolbarButton } from "../Table/AddToolbarButton";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { Column } from "react-table";
import { Link } from "react-router-dom";

import { join, pipe, pluck } from "ramda";
type ProductSetsTableType = {
  id: string;
  products: Product[];
  name: string;
  description?: string;
};

export const ProductSetsTable: React.FC<{}> = () => {
  const columns: Column<ProductSetsTableType>[] = [
    {
      Header: "Id",
      accessor: "id",
      Cell: (props) => {
        return (
          <Link className={"text-primary"} to={`/product-sets/${props.cell.value}`}>
            {props.cell.value}
          </Link>
        );
      },
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Products",
      accessor: "products",
      Cell: (props) => {
        //const products = props.cell.value;
        const products = pipe(pluck("name"), join(", "))(props.cell.value);
        return products;
      },
    },
    { Header: "Description", accessor: "description" },
  ];

  const { data, loading } = useQuery<Query>(GET_PRODUCT_SETS);
  if (!data) return null;
  return (
    <>
      {!loading && (
        <SelectableEntityTable<ProductSetsTableType>
          title={"Product Sets"}
          columns={columns}
          data={data.getProductSets}
          numRecords={data.getProductSets.length}
          endAdornments={<AddToolbarButton link="/add/product-sets" title="Product Set" />}
        />
      )}
    </>
  );
};
