import * as React from "react";
import { Container } from "../../components/Container";
import { AddJob } from "../../components/Jobs/AddJob";

const AddJobsPage: React.FC<{}> = () => {
  return (
    <div>
      <Container>
        <div className="mt-16" />
        <h3>Create a Job</h3>
        <AddJob />
      </Container>
    </div>
  );
};

export default AddJobsPage;
