import React from "react";
import { useField } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { ErrorTextField } from "./ErrorTextField";
import { TIME_FORMAT } from "../../lib/constants";
import { DateTime } from "luxon";

export const TimeInput: React.FC<{
  name: string;
  label: string;
  dateModifier?: any;
  required?: boolean;
  postChange?: (val: Date) => void;
}> = ({ name, label, dateModifier, required, postChange }) => {
  const [field, meta, helpers] = useField(name);
  const { value, ...fieldRest } = field;
  const renderValue = () => {
    if (value instanceof Date) return value;
    if (typeof value === "string") return DateTime.fromFormat(value, "h:m:s").toJSDate();
    return null;
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        error={!!meta.error}
        value={renderValue()}
        inputVariant="outlined"
        fullWidth
        format={TIME_FORMAT}
        label={label}
        required={required}
        {...fieldRest}
        onChange={(date) => {
          helpers.setValue(dateModifier ? dateModifier(date) : date);
          postChange && postChange(date);
        }}
        helperText={
          <>
            <ErrorTextField fieldName={field.name} displayError={!!meta.error} />
          </>
        }
      />
    </MuiPickersUtilsProvider>
  );
};
