import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationUpdateContactArgs, Query, QueryGetContactArgs } from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { ContactDetailsForm } from "../Forms/ContactDetailsForm";
import { applySpec, keys, path, pathOr, pipe, propOr } from "ramda";
import { UPDATE_CONTACT } from "../../graphql/mutations/updateContact";
import { cleanObject, objectDiff } from "../../lib/functions";
import { contactDetailsSpec } from "./contactDetailsFormHelpers";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Chip } from "@material-ui/core";
import { ActivityFeedDrawer } from "../ActivityFeed/ActivityFeedDrawer";

export const ContactDetails: React.FC<{ contactId: string }> = ({ contactId }) => {
  const { data } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const showSuccess = useShowSuccess();
  const [updateContact] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT);
  if (!data) {
    return null;
  }
  const spec = {
    address: pathOr("", ["lead", "address"]),
    email: propOr("", "email"),
    firstName: propOr("", "firstName"),
    lastName: propOr("", "lastName"),
    timeZone: propOr("", "timeZone"),
    outreachMedium: propOr("", "outreachMedium"),
    hearAboutUs: pathOr("", ["lead", "hearAboutUs"]),
    make: pathOr("", ["lead", "vehicleInfo", "make"]),
    model: pathOr("", ["lead", "vehicleInfo", "model"]),
    year: pathOr("", ["lead", "vehicleInfo", "year"]),
    frontPadLife: pathOr("", ["lead", "vehicleInfo", "frontPadLife"]),
    licensePlate: pathOr("", ["lead", "vehicleInfo", "licensePlate"]),
    odometer: pathOr("", ["lead", "vehicleInfo", "odometer"]),
    rearPadLife: pathOr("", ["lead", "vehicleInfo", "rearPadLife"]),
    vin: pathOr("", ["lead", "vehicleInfo", "vin"]),
    extraInfo: pathOr("", ["lead", "vehicleInfo", "extraInfo"]),
    symptoms: pathOr("", ["lead", "vehicleInfo", "symptoms"]),
    customerExpectation: pathOr("", ["lead", "vehicleInfo", "customerExpectation"]),
    additionalNotes: pathOr("", ["lead", "vehicleInfo", "additionalNotes"]),
    frontBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "frontBrakeSymptoms"]),
    rearBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "rearBrakeSymptoms"]),
    market: pathOr("", ["lead", "market"]),
    source: pathOr("", ["lead", "source"]),
    phoneNumber: propOr("", "phoneNumber"),
    zip: pathOr("", ["lead", "zip"]),
  };
  const initialValues = applySpec(spec)(data.getContact);
  const onSubmit = async (values, formikHelpers) => {
    // @ts-ignore
    const diff = objectDiff(values, initialValues);
    const updates = pipe(
      contactDetailsSpec,
      // @ts-ignore
      cleanObject
    )(diff);
    if (keys(updates).length) {
      await updateContact({
        variables: {
          id: contactId,
          updateContactInput: updates,
        },
      });
    }
    showSuccess({ message: "Contact Updated" });
  };

  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        {path(["getContact", "oldMongoId"], data) && (
          <div className="my-2 flex flex-row justify-end">
            <Chip label={"Imported from MHelp"} color={"primary"} />
          </div>
        )}
        <ContactDetailsForm
          onSubmit={onSubmit}
          // @ts-ignore
          initialValues={initialValues}
        />
      </div>
      <ActivityFeedDrawer activities={data.getContact.activityFeed} />
    </div>
  );
};
