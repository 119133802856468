import * as React from "react";
import { DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { FeedbackForm } from "../Forms/FeedbackForm";

export const FeedbackDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        What feedback would you recommend to our dev/product team?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Feel free to request features or report bugs. This will notify the dev
          team in the #tech-feedback channel.
        </DialogContentText>
        <FeedbackForm toggle={onClose} />
      </DialogContent>
    </Dialog>
  );
};
