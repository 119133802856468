import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Button } from "../Buttons/Button";
import { GET_SERVICE_CATALOGUE } from "../../graphql/queries/getServiceCatalogue";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PartsStore, Query, QueryCalculatePossibleEjiPriceInfoArgs } from "../../generated/nest-graphql";
import { Service } from "../../generated/nest-graphql";
import { cleanedDiscounts, ServicesReceiptSection } from "./ServicesReceiptSection";
import { Field } from "formik";
import { isNil } from "ramda";
import { ServicesTable } from "./ServicesTable";
import { CALCULATE_POSSIBLE_EJI_PRICE_INFO } from "../../graphql/queries/calculatePossibleEJIPriceInfo";
import { cleanedServices } from "./ProductRow";

type ServicesSectionProps = {
  ejiType?: string;
  showTable: boolean;
  partsStores: PartsStore[];
  setValues: any;
  values: any;
};

type ServiceOption = {
  label: string;
  value: Service;
};

const ServicesSection = ({ showTable, partsStores, values, setValues, ejiType }: ServicesSectionProps) => {
  const [calculatePossibleEjiPriceInfo, { data, loading, called }] = useLazyQuery<
    Query,
    QueryCalculatePossibleEjiPriceInfoArgs
  >(CALCULATE_POSSIBLE_EJI_PRICE_INFO, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (priceData) => {
      setValues({
        ...values,
        services: priceData.calculatePossibleEJIPriceInfo.ejiServices ?? [],
        discounts: priceData.calculatePossibleEJIPriceInfo.ejiPriceInfo.discounts ?? [],
      });
    },
  });
  if (data === undefined && !loading && !called) {
    calculatePossibleEjiPriceInfo({
      variables: {
        calculatePossibleEJIPriceInfoInput: {
          services: cleanedServices(values.services),
          discounts: cleanedDiscounts(values.discounts) ?? [],
          marketName: values.market,
          taxable: values.taxable,
          calculateAllServices: ejiType === "INVOICE",
        },
      },
    });
  }
  return (
    <div>
      {showTable && (
        <ServicesTable
          partsStores={partsStores}
          ejiType={ejiType}
          calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        />
      )}
      <AddService calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo} ejiType={ejiType} />
      <ServicesReceiptSection
        receiptValues={data?.calculatePossibleEJIPriceInfo?.ejiPriceInfo ?? {}}
        calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        ejiType={ejiType}
      />
    </div>
  );
};

const AddService = ({ calculatePossibleEJIPriceInfo, ejiType }) => {
  const [selectedService, setSelectedService] = useState<ServiceOption | null>(null);
  const { data } = useQuery<Query>(GET_SERVICE_CATALOGUE);
  if (!data) return null;
  const serviceList: Service[] = data.getServiceCatalogue;
  const servicesToOptions = (serviceList) => {
    return serviceList.map((service) => {
      return {
        label: service.name,
        value: service,
      };
    });
  };

  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedService}
        onChange={(_, selectedOption: ServiceOption) => {
          setSelectedService(selectedOption);
        }}
        options={servicesToOptions(serviceList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Service" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={"services"}>
          {({ field: { value }, form: { values } }) => {
            return (
              <Button
                onClick={() => {
                  if (!isNil(selectedService)) {
                    const { id, name, technicianPay, products } = selectedService.value;
                    const ejiService = {
                      service: id,
                      inEstimate: false,
                      name,
                      technicianPay,
                      items: products
                        .filter(({ behavior }) => behavior === "Default")
                        .map((product) => {
                          return {
                            serviceProductId: product.id,
                            type: product.type,
                            units: product.defaultUnits,
                            behavior: product.behavior,
                            category: product.category,
                            customerPrice: product.defaultCustomerPrice,
                            overriddenCustomerPrice: false,
                            productSelection: { name: "" },
                            vendorUnitCost: null,
                            orderItem: {
                              partsStore: values?.technician?.homePartsStore ?? null,
                            },
                          };
                        }),
                    };

                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: cleanedServices([...value, ejiService]),
                          discounts: cleanedDiscounts(values.discounts),
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    setSelectedService(null);
                  }
                }}
                type={"button"}
              >
                + Add Service
              </Button>
            );
          }}
        </Field>
      </Box>
    </div>
  );
};

export default ServicesSection;
