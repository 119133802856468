import React from "react";
import { MaterialTopNav } from "./MaterialTopNav";
import { SideDrawer } from "./SideDrawer";
import { Toolbar } from "@material-ui/core";

export const Layout: React.FC = ({ children }) => {
  return (
    <div className={"flex"}>
      <MaterialTopNav />
      <SideDrawer />
      <div className="flex-1 min-h-screen overflow-hidden">
        <Toolbar />
        <div className="overflow-auto h-full">{children}</div>
      </div>
    </div>
  );
};
