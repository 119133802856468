import { UserFormValues } from "../Forms/AddOpsManagerForm";
import { applySpec, prop } from "ramda";

export const userDetailsSpec = (formData: UserFormValues) => {
  return applySpec({
    email: prop("email"),
    firstName: prop("firstName"),
    lastName: prop("lastName"),
    password: prop("password"),
  })(formData);
};
