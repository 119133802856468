import { carDataByYear } from "../data/carDataByYear";
export const getYears = () => {
  return Object.keys(carDataByYear).reverse();
};

export const getMakesByYear = (year: string) => {
  // @ts-ignore
  try {
    const yearData = carDataByYear[`${year}`];
    return Object.keys(yearData).sort();
  } catch (e) {}
  return [];
};

export const getModelsByYearAndMake = (year: string, make: string) => {
  // @ts-ignore
  try {
    const modelData = carDataByYear[`${year}`][`${make}`];
    return Object.keys(modelData).sort();
  } catch (e) {}
  return [];
};
// @ts-ignore
