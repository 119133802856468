import * as React from "react";
import { NuModal } from "../NuModal";
import { Button } from "../Buttons/Button";
import { AppointmentReadOnlySection } from "./AppointmentFormSection";
import { AppointmentFormValues } from "./AppointmentForm";
import { keys } from "ramda";
import { useToggle } from "../../hooks/useToggle";
import { Settings } from "luxon";
import SuggestionFormSection from "./SuggestionFormSection";
import { EjiService, PartsStore, PossibleEjiService } from "../../generated/nest-graphql";
import { DateTime } from "luxon";

type AppointmentFormWithModalProps = {
  appointment: any;
  parentSetFieldValue: any;
  initialValues: AppointmentFormValues;
  market: string;
  parentValidateForm: any;
  timeZone: string;
  serviceLocation: string;
  partsOrdered: boolean;
  itemPartsStores: PartsStore[];
  services: PossibleEjiService[] | EjiService[];
  contactId?: string;
  estimateId?: string;
};
export const AppointmentFormWithModal: React.FC<AppointmentFormWithModalProps> = ({
  appointment,
  parentSetFieldValue,
  initialValues,
  parentValidateForm,
  market,
  timeZone,
  partsOrdered,
  serviceLocation,
  itemPartsStores,
  services,
  contactId,
  estimateId,
}) => {
  Settings.defaultZoneName = timeZone;
  const [modalOpen, , toggleModal] = useToggle();
  // eslint-disable-next-line

  const onClick = async () => {
    const parentErrors = await parentValidateForm();
    if (keys(parentErrors).length === 0) {
      toggleModal();
    }
  };

  const scheduledDurationInitialValue = () => {
    const endDate = DateTime.fromJSDate(appointment.endDate);
    const startDate = DateTime.fromJSDate(appointment.startDate);
    return Math.round(endDate.diff(startDate, ["minutes"]).minutes);
  };
  return (
    <>
      {!appointment && (
        <Button type={"button"} onClick={onClick}>
          Add Appointment
        </Button>
      )}
      {appointment && <AppointmentReadOnlySection appointment={appointment} onEditClick={toggleModal} />}
      <NuModal isOpen={modalOpen} maxWidth="md" title={appointment ? "Edit Appointment" : "Add Appointment"}>
        <SuggestionFormSection
          appointmentId={appointment?.id}
          scheduledDate={appointment?.startDate}
          scheduledDuration={appointment ? scheduledDurationInitialValue() : null}
          scheduledTechnician={appointment?.technician ?? null}
          onCancel={toggleModal}
          market={market}
          serviceLocation={serviceLocation}
          timeZone={timeZone}
          contactId={contactId}
          estimateId={estimateId}
          onSubmit={({
            allDay,
            endDate,
            startDate,
            endTimeWindow,
            startTimeWindow,
            subject,
            technician,
            timeZone,
          }: AppointmentFormValues) => {
            parentSetFieldValue("appointmentInfo", {
              allDay: allDay ?? initialValues.allDay,
              endDate,
              startTimeWindow,
              endTimeWindow,
              startDate,
              subject: subject ?? initialValues.subject,
              technician,
              timeZone,
            });
            if (!partsOrdered) {
              // TODO: might need to do a service catalogue used check here
              itemPartsStores.forEach((partsStore, idx) => {
                parentSetFieldValue(`items.${idx}.partsStore`, technician?.homePartsStore);
              });
              services?.forEach((service, serviceIdx) => {
                service.items?.forEach((item, itemIdx) => {
                  if (item.category === "Part") {
                    parentSetFieldValue(
                      `services.${serviceIdx}.items.${itemIdx}.orderItem.partsStore`,
                      technician?.homePartsStore
                    );
                  }
                });
              });
            }
            toggleModal();
          }}
        />
      </NuModal>
    </>
  );
};
