import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as Integrations from "@sentry/integrations";
import * as Tracing from "@sentry/tracing";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Provider } from "react-redux";
import { store } from "./redux/store";

if (`${process.env.NODE_ENV}` === "production") {
  Sentry.init({
    dsn: "https://0016b1ef6b934c5998476ebe3d9f2333@o93944.ingest.sentry.io/5226282",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ["error"],
      }),
      new Tracing.Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.1,
    attachStacktrace: true,
  });
  LogRocket.init("ijawhc/ops-web-app");
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
