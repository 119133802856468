import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { useMutation } from "@apollo/client";
import { CreateMarketInput, Mutation, MutationCreateMarketArgs } from "../../generated/nest-graphql";
import { CREATE_MARKET } from "../../graphql/mutations/createMarket";
import { FormikHelpers } from "formik";
import { Box } from "@material-ui/core";
import React from "react";
import { Container } from "../../components/Container";
import { MarketForm, MarketFormValues } from "../../components/Forms/MarketForm";
import { marketSpec } from "../../components/specs/marketsSpec";

export const AddMarketPage = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createMarket] = useMutation<Mutation, MutationCreateMarketArgs>(CREATE_MARKET);

  const onSubmit = async (values: MarketFormValues, formikHelpers: FormikHelpers<any>) => {
    const input: CreateMarketInput = marketSpec(values);
    const result = await createMarket({
      variables: {
        createMarketInput: input,
      },
    });
    const id = result.data.createMarket.id;
    showSuccess({ message: "Successfully Added Market" });
    await history.push(`/markets/${id}`);
  };

  return (
    <Box>
      <Container>
        <div className="mt-16" />
        <h3>Create a Market</h3>
        <MarketForm
          initialValues={{
            name: "",
            timeZone: "America/Chicago",
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </Box>
  );
};
