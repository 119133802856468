import gql from "graphql-tag";
import { estimateDetailsFields } from "../queries/getEstimate";
import { contactDetailsFields } from "../queries/getContactDetails";
import { jobDetailsFields } from "../queries/getJob";
import { invoiceDetailsFields } from "../queries/getInvoice";

export const PERFORM_ACTION = gql`mutation PerformAction($performActionInput: PerformActionInput!) {
    performAction(performActionInput: $performActionInput) {
        ... on Estimate {
            ${estimateDetailsFields}
        }
        ... on Contact {
            ${contactDetailsFields}
        }
        ... on Job {
            ${jobDetailsFields}
        }
        ... on Invoice {
            ${invoiceDetailsFields}
        }
    }
}`;
