import React, { useState } from "react";
import { useField } from "formik";
import { applySpec, concat, isNil, map, prop } from "ramda";
import { Box } from "@material-ui/core";
import { Button } from "./Button";
import { useToggle } from "../../hooks/useToggle";
import { NuModal } from "../NuModal";
import { useQuery } from "@apollo/client";
import { PartsStore, Product, ProductSet, Query } from "../../generated/nest-graphql";
import { GET_PRODUCT_SETS } from "../../graphql/queries/getProductSets";
import { ProductSetOptionType, ProductSetSelectTypeAhead } from "../FormFields/ProductSetSelectTypeHead";
import { CancelButton } from "./CancelButton";
import { ProductsTable } from "../ProductSets/ProductTable";
import { ItemFormValues } from "../Forms/ItemForm";

export const AddItemSetButton: React.FC<{ name: string; defaultPartsStore: PartsStore }> = ({
  name,
  defaultPartsStore,
}) => {
  const [field, , helpers] = useField(name);
  const [open, , toggleDialog] = useToggle();
  const { data } = useQuery<Query>(GET_PRODUCT_SETS);
  const [productSet, setProductSet] = useState<ProductSet | null>();
  if (!data) return null;
  const postOnChange = (value: ProductSetOptionType) => {
    setProductSet(prop("value", value));
  };
  const onAdd = () => {
    if (isNil(productSet)) return;
    const oldItems = field.value;
    const products = prop("products", productSet);
    // @ts-ignore
    const itemSet = map<Product, ItemFormValues>(
      // @ts-ignore
      applySpec<ItemFormValues>({
        name: prop("name"),
        amount: prop("amount"),
        description: prop("description"),
        laborCost: prop("laborCost"),
        partsCost: prop("partsCost"),
        product: prop("id"),
        partsStore: (_dontmatter: any) => defaultPartsStore,
      }),
      products
    );
    helpers.setValue(concat(oldItems, itemSet));
    setProductSet(null);
    toggleDialog();
  };
  return (
    <>
      <Button type={"button"} onClick={toggleDialog}>
        + Add Set Items
      </Button>
      <NuModal isOpen={open} title="Add Product Set Items" maxWidth={"md"}>
        <Box mb={2}>
          <ProductSetSelectTypeAhead
            productSets={data.getProductSets}
            label="Product Set"
            postOnChange={postOnChange}
          />
        </Box>
        {productSet && (
          <ProductsTable products={productSet?.products as Product[]} setFieldValue={{}} readOnly={true} />
        )}
        <Box display="flex" marginTop={2}>
          <Box justifyContent="flex-start" flexGrow={1}>
            <CancelButton type={"button"} onClick={toggleDialog}>
              Cancel
            </CancelButton>
          </Box>
          <Box justifyContent="flex-end">
            <Button disabled={isNil(productSet)} type={"button"} onClick={onAdd}>
              + Add Items
            </Button>
          </Box>
        </Box>
      </NuModal>
    </>
  );
};
