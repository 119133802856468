import * as React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { applySpec, equals, identity, map, prop, propOr } from "ramda";
import { Market } from "../../generated/nest-graphql";
import { useMarkets } from "../../hooks/useMarkets";

interface MarketProps {
  name: string;
  value: any;
  onChange: any;
  error: any;
  required?: boolean;
}

export type MarketOption = {
  label: string;
  value: Market;
};
const toOptions = (arr: any) =>
  map<Market, MarketOption>(
    applySpec<MarketOption>({
      label: prop("name"),
      value: identity,
    }),
    arr
  );

export const AustoselectMarketSelectField = ({ required = false, ...rest }: MarketProps) => {
  const markets: any = useMarkets();
  return (
    <>
      {markets && (
        <Autocomplete<MarketOption, boolean, false, true>
          {...rest}
          autoSelect
          getOptionLabel={propOr("", "label")}
          getOptionSelected={equals}
          renderInput={(params) => <TextField {...params} required={required} label={"Market"} variant="outlined" />}
          options={toOptions(markets)}
        />
      )}
    </>
  );
};
