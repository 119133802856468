import React from "react";
import { path } from "ramda";
import { useQuery } from "@apollo/client";
import { Query, QuerySearchArgs } from "../generated/nest-graphql";
import { SEARCH_QUERY } from "../graphql/queries/searchQuery";
import { ContactsSearchResults } from "../components/SearchPage/ContactsSearchResults";
import { EstimatesSearchResults } from "../components/SearchPage/EstimatesSearchResults";
import { useQueryParams } from "../hooks/useQueryParams";

const SearchPage = () => {
  const queryParams = useQueryParams();
  const { data } = useQuery<Query, QuerySearchArgs>(SEARCH_QUERY, {
    variables: {
      query: queryParams.get("q"),
    },
  });
  const contacts: any[] = path(["search", "contacts"], data);
  const estimates: any[] = path(["search", "estimates"], data);
  return (
    <>
      {contacts && <ContactsSearchResults contacts={contacts} />}
      {estimates && <EstimatesSearchResults estimates={estimates} />}
    </>
  );
};

export default SearchPage;
