import { useQuery } from "@apollo/client";
import { Product, Query } from "../../generated/nest-graphql";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import React from "react";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { AddToolbarButton } from "../Table/AddToolbarButton";

export const ProductColumns: Column<Product>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/products/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Name", accessor: "name" },
  { Header: "Description", accessor: "description" },
  { Header: "Technician Pay", accessor: "laborCost" },
  { Header: "Parts Cost", accessor: "partsCost" },
  { Header: "Amount", accessor: "amount" },
];

export const ProductsTable = () => {
  const { data } = useQuery<Query>(GET_PRODUCTS);
  if (!data) return null;
  return (
    <SelectableEntityTable<Product>
      title={"Products"}
      data={data.getProducts}
      columns={ProductColumns}
      numRecords={data.getProducts.length}
      endAdornments={<AddToolbarButton link="/add/products" title="Product" />}
    />
  );
};
