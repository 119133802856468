import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../generated/nest-graphql";
import { CREATE_ONE_TIME_IDENTIFIER_LINK } from "../../graphql/mutations/createOneTimeIdentifierLink";
import { CreditCardsTable } from "./CreditCardsTable";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { TextField, Button } from "@material-ui/core";
import { path } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

export const ContactCreditCards: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [link, setLink] = useState("");
  const [createOneTimeIdentifier, { loading }] = useMutation<Mutation>(CREATE_ONE_TIME_IDENTIFIER_LINK);
  const onGenerate = async () => {
    const data = await createOneTimeIdentifier({ variables: { contactId } });
    setLink(path(["data", "createOneTimeIdentifierLink"], data));
  };
  return (
    <>
      <CreditCardsTable contactId={contactId} />
      <DetailViewContainer title={"Customer Add credit card link"}>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-1 pt-2">
            <Button fullWidth variant="contained" color="primary" size="large" onClick={onGenerate} disabled={loading}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : "Generate"}
            </Button>
          </div>
          <div className="col-span-5">
            <TextField fullWidth value={link} variant={"outlined"} />
          </div>
        </div>
      </DetailViewContainer>
    </>
  );
};
