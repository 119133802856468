import * as React from "react";
import { Container } from "../../components/Container";
import { TechnicianDetails } from "../../components/Technician/TechnicianDetails";
import { useEntityId } from "../../hooks/useEntityId";

const TechnicianDetailsPage: React.FC = () => {
  const technicianId = useEntityId();
  return (
    <>
      <Container>
        <div className="mt-16" />
        <h3>Update Technician</h3>
        <TechnicianDetails technicianId={technicianId} />
      </Container>
    </>
  );
};

export default TechnicianDetailsPage;
