import { useStateWithSessionStorage } from "./useStateWithSessionStorage";

export const useScheduling = () => {
  const [techniciansToInclude, setTechniciansToInclude] = useStateWithSessionStorage("technicians", []);
  const [marketsToInclude, setMarketsToInclude] = useStateWithSessionStorage("markets", []);
  const [currentMarket, setCurrentMarket] = useStateWithSessionStorage("currentMarket", {
    name: "Austin",
    timeZone: "America/Chicago",
  });
  const [calendarView, setCalendarView] = useStateWithSessionStorage("calendarView", "day");
  const [includeIsActive, setIncludeIsActive] = useStateWithSessionStorage("includeIsActive", true);
  return {
    techniciansToInclude,
    setTechniciansToInclude,
    marketsToInclude,
    setMarketsToInclude,
    currentMarket,
    setCurrentMarket,
    calendarView,
    setCalendarView,
    includeIsActive,
    setIncludeIsActive,
  };
};
