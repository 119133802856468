import { Box, Typography } from "@material-ui/core";
import { add, isAfter, isBefore, sub } from "date-fns/fp";
import { DateTime } from "luxon";
import React from "react";
import { SchedulingDay as SchedulingDayGQL } from "../../generated/nest-graphql";
import { TimeInput } from "./TimeInput";

export const SchedulingDayField: React.FC<{
  schedulingDay: SchedulingDayGQL;
  startTimeName: string;
  endTimeName: string;
  dateModifier?: any;
  className: string;
  setFieldValue: any;
}> = ({ schedulingDay, startTimeName, endTimeName, dateModifier, className, setFieldValue }) => {
  const startTimePostChange = (date: Date) => {
    const endDate = DateTime.fromFormat(schedulingDay.endTime, "h:m:s").toJSDate();
    if (!endDate || isAfter(endDate, date)) {
      setFieldValue(endTimeName, add({ hours: 1 }, date));
    }
  };
  const endTimePostChange = (date: Date) => {
    const startDate = DateTime.fromFormat(schedulingDay.startTime, "h:m:s").toJSDate();
    if (!startDate || isBefore(startDate, date)) {
      setFieldValue(startTimeName, sub({ hours: 1 }, date));
    }
  };

  return (
    <Box className={className}>
      <Typography>{schedulingDay.dayOfWeek}</Typography>
      <TimeInput
        name={startTimeName}
        label={"Start Time"}
        required={true}
        dateModifier={dateModifier}
        postChange={startTimePostChange}
      />
      <TimeInput
        name={endTimeName}
        label={"End Time"}
        required={true}
        dateModifier={dateModifier}
        postChange={endTimePostChange}
      />
    </Box>
  );
};
