import { useEntityId } from "../../hooks/useEntityId";
import { Container } from "../../components/Container";
import React from "react";
import { MarketDetails } from "../../components/Markets/MarketDetails";

export const MarketsDetailPage = () => {
  const marketId = useEntityId();
  return (
    <div>
      <div className="mt-16" />
      <Container>
        <MarketDetails marketId={marketId} />
      </Container>
    </div>
  );
};
