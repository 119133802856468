import * as React from "react";
import { useField } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { labelStyles } from "../../material-ui-styles";
import { path } from "ramda";

export const CheckBoxField: React.FC<{
  name: string;
  label?: string;
  postOnChange?: (value: boolean) => void;
}> = ({ name, label = "", postOnChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const classes = labelStyles();
  return (
    <label className="md:w-2/3 block text-gray-500 font-bold">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            {...field}
            checked={field.value}
            onChange={(event: object) => {
              const checked = path<boolean>(["target", "checked"], event);

              helpers.setValue(checked);
              postOnChange && postOnChange(checked);
            }}
          />
        }
        label={
          <Typography className={classes.root} variant={"body1"}>
            {label}
          </Typography>
        }
      />
    </label>
  );
};
