import { SchedulingDay } from "../generated/nest-graphql";

export const defaultTechnicianDaySchedule: SchedulingDay[] = [
  {
    dayOfWeek: "Monday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Tuesday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Wednesday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Thursday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Friday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Saturday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
  {
    dayOfWeek: "Sunday",
    startTime: "09:00:00",
    endTime: "17:00:00",
  },
];
