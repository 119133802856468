import React from "react";
import { MarketsTable } from "../../components/Markets/MarketsTable";

const MarketsPage = () => {
  return (
    <>
      <div className="px-8 py-4">
        <div>
          <MarketsTable />
        </div>
      </div>
    </>
  );
};
export default MarketsPage;
