import gql from "graphql-tag";

export const productSetQueryReturnFields = `
        id
        description
        name
        products {
            id
            amount
            description
            name
            laborCost
            partsCost
        }`;

export const GET_PRODUCT_SET = gql`query getProductSet($id: ID!) {
    getProductSet(id: $id) {
        ${productSetQueryReturnFields}
    }
}`;
