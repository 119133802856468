import { useLazyQuery } from "@apollo/client";
import {
  Query,
  QueryGetAppointmentSuggestionsArgs,
  GetAppointmentSuggestionsInput,
} from "../../generated/nest-graphql";
import { GET_APPOINTMENT_SUGGESTIONS } from "../../graphql/queries/getAppointmentSuggestions";
import React from "react";
import { SuggestionForm, SuggestionFormValues } from "../../components/Forms/SuggestionForm";
import { AppointmentOptions } from "./AppointmentOptions";
import { applySpec, prop } from "ramda";
import { BookingDetailsForm } from "../../components/Forms/BookingDetailsForm";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { technicianCopySpec } from "../specs/technicianCopySpec";
import { Technician } from "../../generated/nest-graphql";

const laborTimes = [30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180];

type SuggestionFormSectionTypes = {
  appointmentId?: string;
  scheduledDate?: Date;
  scheduledDuration?: number;
  scheduledTechnician?: Technician;
  onSubmit: any;
  onCancel: any;
  market: string;
  jobId?: any;
  serviceLocation: string;
  timeZone: string;
  jobName?: string;
  contactId?: string;
  estimateId?: string;
};

export const suggestionFormSpec = (formData: GetAppointmentSuggestionsInput) =>
  applySpec({
    address: prop("address"),
    laborTime: prop("laborTime"),
    startTime: prop("startTime"),
    endTime: prop("endTime"),
  })(formData);

const isTodayOrPast = (inputDate: DateTime) => {
  return inputDate.get("day") === DateTime.now().get("day") || inputDate < DateTime.now();
};

const BookingDetailsFormValidationSchema = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  laborTime: Yup.number().required("Required"),
  technician: Yup.object().required("Required"),
  rescheduleReason: Yup.string().nullable(),
});
const SuggestionFormSection = ({
  appointmentId,
  scheduledDate,
  scheduledDuration,
  scheduledTechnician,
  onSubmit,
  onCancel,
  market,
  jobId,
  serviceLocation,
  timeZone,
  jobName,
  contactId,
  estimateId,
}: SuggestionFormSectionTypes) => {
  const [getAppointmentSuggestions, { data, error, called, loading, variables: suggestionToolInputs }] = useLazyQuery<
    Query,
    QueryGetAppointmentSuggestionsArgs
  >(GET_APPOINTMENT_SUGGESTIONS, {
    fetchPolicy: "no-cache",
  });
  const getSuggestions = async ({ address, startTime, laborTime }: SuggestionFormValues) => {
    const luxonStartTime = DateTime.fromJSDate(startTime);
    const adjustedStartTime = isTodayOrPast(luxonStartTime) ? DateTime.now() : luxonStartTime.startOf("day");
    const adjustedInputs: GetAppointmentSuggestionsInput = {
      address,
      laborTime,
      startTime: adjustedStartTime.toJSDate(),
      endTime: adjustedStartTime.endOf("day").toJSDate(),
      contactId: contactId,
      estimateId: estimateId,
    };
    await getAppointmentSuggestions({
      variables: {
        getAppointmentSuggestionsInput: adjustedInputs,
      },
    });
  };
  const { control, handleSubmit, setValue, formState } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      startDate: scheduledDate ?? null,
      laborTime: scheduledDuration ?? 60,
      technician: scheduledTechnician ?? null,
      rescheduleReason: null,
    },
    resolver: yupResolver(BookingDetailsFormValidationSchema),
  });

  const onClickUseSuggestion = (suggestion) => {
    setValue("startDate", DateTime.fromISO(suggestion.startDate).setZone(suggestion.market.timeZone).toJSDate());
    setValue(
      "laborTime",
      DateTime.fromISO(suggestion.endDate).diff(DateTime.fromISO(suggestion.startDate), ["minutes"]).minutes
    );
    setValue("technician", suggestion.technician);
  };
  const submitAppointment = ({ startDate, laborTime, technician, rescheduleReason }) => {
    onSubmit({
      job: jobId ?? null,
      technician,
      allDay: false,
      rescheduleReason,
      startDate,
      endDate: DateTime.fromJSDate(startDate).plus({ minutes: laborTime }).toJSDate(),
      startTimeWindow: startDate,
      endTimeWindow: DateTime.fromJSDate(startDate).plus({ minutes: 30 }).toJSDate(),
      technicianCopy: technicianCopySpec(technician),
      subject: jobName ?? null,
      timeZone,
    });
  };

  return (
    <>
      <div className="border-2 p-4 mb-4">
        <div className="mb-4 text-lg">Appointment Recommendations</div>
        <SuggestionForm
          initialValues={{
            address: serviceLocation,
            laborTime: laborTimes.includes(scheduledDuration) ? scheduledDuration : 60,
            startTime: scheduledDate ?? new Date(),
          }}
          onSubmit={getSuggestions}
          laborTimes={laborTimes}
        />
        <AppointmentOptions
          suggestions={data?.getAppointmentSuggestions ?? []}
          error={error}
          called={called}
          loading={loading}
          onClick={onClickUseSuggestion}
          suggestionToolInputs={suggestionToolInputs}
        />
      </div>
      <BookingDetailsForm
        appointmentId={appointmentId}
        scheduledDate={scheduledDate ?? null}
        jobId={jobId}
        control={control}
        formState={formState}
        setValue={setValue}
        onSubmit={(event) => {
          event.preventDefault?.();
          event.stopPropagation?.();
          return handleSubmit(submitAppointment)(event);
        }}
        onCancel={onCancel}
        market={market}
        laborTimes={laborTimes}
        timeZone={timeZone}
      />
    </>
  );
};
export default SuggestionFormSection;
