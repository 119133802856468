export const normalizePhone = (value: any) => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.match(/^1/)) {
    onlyNums = onlyNums.substr(1);
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
