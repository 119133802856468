import gql from "graphql-tag";
import { contactDetailsFields } from "./getContactDetails";

export const SEARCH_CONTACTS = gql`
  query searchContacts($query: String!) {
    searchContacts(query: $query) {
        ${contactDetailsFields}
    }
  }
`;
