import React from "react";
import "date-fns";
import { LuxonTZUtils } from "../DatePickerUtil";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DATE_FORMAT } from "../../lib/constants";
import { FieldError } from "react-hook-form";
import { DateTime } from "luxon";

export const DateInputRHF: React.FC<{
  value: any;
  onChange: any;
  label: string;
  required?: boolean;
  error?: FieldError;
}> = ({ value, onChange, label, required, error }) => {
  const renderValue = () => {
    if (value instanceof Date) return value;
    if (typeof value === "string") return new Date(value);
    if (value instanceof DateTime) return value.toJSDate();
    return null;
  };
  return (
    <MuiPickersUtilsProvider utils={LuxonTZUtils}>
      <DatePicker
        error={!!error}
        value={renderValue()}
        inputVariant="outlined"
        fullWidth
        format={DATE_FORMAT}
        label={label}
        required={required}
        onChange={onChange}
        helperText={error && <div>{error.message}</div>}
        disablePast
      />
    </MuiPickersUtilsProvider>
  );
};
