import React, { useState } from "react";
import { map, prop } from "ramda";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ProductSet } from "../../generated/nest-graphql";

export type ProductSetOptionType = {
  label: string;
  value: ProductSet;
};

export const ProductSetSelectTypeAhead: React.FC<{
  productSets: ProductSet[];
  postOnChange?: (value: ProductSetOptionType) => void;
  label: string;
}> = ({ productSets, postOnChange, label }) => {
  const [currentOption, setCurrentOption] = useState(null);
  const productSetToOptions = (productSet: ProductSet) => {
    return {
      label: productSet.name,
      value: productSet,
    };
  };

  const productSetOptions = map(productSetToOptions, productSets);
  const onChange = (_: object, value: ProductSetOptionType) => {
    setCurrentOption(value);
    postOnChange && postOnChange(value);
  };
  const filterOptions = createFilterOptions<ProductSetOptionType>({
    matchFrom: "start",
    stringify: (option) => {
      return option.label;
    },
  });
  if (productSetOptions.length === 0) return null;
  return (
    <Autocomplete
      getOptionSelected={(option, value) =>
        prop("label", option) === prop("label", value)
      }
      getOptionLabel={(option) => prop("label", option) || ""}
      value={currentOption}
      options={productSetOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      disableClearable
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
};
