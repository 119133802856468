import React from "react";
import { InvoicesTabView } from "../../components/Invoices/InvoicesTabView";
import { useEntityId } from "../../hooks/useEntityId";

export const InvoiceDetailsPage = () => {
  const invoiceId = useEntityId();
  return (
    <div>
      <InvoicesTabView invoiceId={invoiceId} />
    </div>
  );
};

export default InvoiceDetailsPage;
