import React, { Fragment } from "react";
import { useMarkets } from "../../hooks/useMarkets";
import { Field } from "formik";
import classNames from "classnames";
import { useToggle } from "../../hooks/useToggle";
import { NuModal } from "../NuModal";
import DiscountModal from "./DiscountModal";
import { useShowSuccess } from "../../redux/slices/snackbar";
import currency from "currency.js";
import { EjiDiscount, EjiDiscountInput } from "../../generated/nest-graphql";
import { cleanedServices } from "./ProductRow";
import { EJI_DISCOUNT_TYPE_PERCENT } from "../../lib/constants";
import { default as ProductErrorIcon } from "@material-ui/icons/NewReleases";

export const defaultZero = currency(0, { symbol: "" }).format();

export const ServicesReceiptSection: React.FC<{
  receiptValues: any;
  calculatePossibleEJIPriceInfo: any;
  amountPaid?: string;
  balanceDue?: string;
  ejiType?: string;
}> = ({ receiptValues, amountPaid, balanceDue, calculatePossibleEJIPriceInfo, ejiType }) => {
  const markets: any = useMarkets();
  if (!markets) {
    return null;
  }
  return (
    <div className="border rounded mt-1 mb-8 bg-black-900 px-4 py-2">
      <ReceiptItem
        text={"Subtotal"}
        amount={receiptValues.subTotal ?? "--"}
        bold
        error={
          !receiptValues.subTotal && (
            <div className="flex">
              <ProductErrorIcon color={"primary"} />
              <div className="pl-2 text-primary">Required pricing data is missing</div>
            </div>
          )
        }
      />
      <ReceiptItem text={"Parts Total"} amount={receiptValues.partsTotal ?? "--"} subItem />
      <ReceiptItem text={"Labor Total"} amount={receiptValues.laborTotal ?? "--"} subItem />
      {!!receiptValues.feesTotal && receiptValues.feesTotal !== defaultZero && (
        <ReceiptItem text={"Fee Total"} amount={receiptValues.feesTotal} subItem />
      )}
      <Field name={`discounts`}>
        {({ field: { value = [] } }) => {
          return (
            <Fragment>
              {value.map((_, discountIdx) => (
                <ExistingDiscount
                  discountIdx={discountIdx}
                  calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                  ejiType={ejiType}
                />
              ))}
              <AddDiscount calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo} ejiType={ejiType} />
            </Fragment>
          );
        }}
      </Field>
      <ReceiptItem text={"Total Tax"} amount={receiptValues.totalTax ?? "--"} />
      <ReceiptItem text={"Total"} amount={receiptValues.amountDue ?? "--"} bold />
      {amountPaid && <ReceiptItem text={"Amount Paid"} amount={amountPaid} />}
      {balanceDue && <ReceiptItem text={"Balance Due"} amount={balanceDue} />}
    </div>
  );
};

export const ReceiptItem: React.FC<{
  text: string;
  amount: string;
  subItem?: boolean;
  bold?: boolean;
  error?: any;
}> = ({ text, amount, subItem, bold, error }) => {
  const spacingClass = classNames("flex", "flex-row", "justify-between", "m-4", { "pl-6": subItem });
  const textClass = classNames({ "font-bold": bold, "text-xs": !bold });
  return (
    <div className={spacingClass}>
      <div className={textClass}>{text}</div>
      {error}
      <div className={textClass}>${amount}</div>
    </div>
  );
};

const ExistingDiscount: React.FC<{ discountIdx: number; calculatePossibleEJIPriceInfo: any; ejiType?: string }> = ({
  discountIdx,
  calculatePossibleEJIPriceInfo,
  ejiType,
}) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const show = useShowSuccess();
  const spacingClass = classNames("text-xs", "flex", "flex-row", "m-4");
  const textClass = classNames("flex", "flex-row, w-1/4");

  return (
    <Field name={`discounts[${discountIdx}]`}>
      {({ field: { value }, form: { values } }) => {
        const onSubmit = (discount) => {
          const discountEdited = [...(values.discounts ?? [])];
          discountEdited.splice(discountIdx, 1, discount);
          calculatePossibleEJIPriceInfo({
            variables: {
              calculatePossibleEJIPriceInfoInput: {
                services: cleanedServices(values.services),
                discounts: cleanedDiscounts(discountEdited),
                marketName: values.market,
                taxable: values.taxable,
                calculateAllServices: ejiType === "INVOICE",
              },
            },
          });
          toggleDiscountModal();
          show({ message: "Discount edited successfully" });
        };
        return (
          <Fragment>
            <div className={spacingClass}>
              <div className={textClass}>
                Discount {value.type === EJI_DISCOUNT_TYPE_PERCENT && `(${value.amount}%)`}
                <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer mx-2">
                  Edit
                </div>
                <div
                  onClick={() => {
                    const discountRemoved = [...(values.discounts ?? [])];
                    discountRemoved.splice(discountIdx, 1);
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: cleanedServices(values.services),
                          discounts: cleanedDiscounts(discountRemoved),
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Remove
                </div>
              </div>
              <div className="flex flex-row justify-between flex-grow">
                <div>{value.reason}</div>
                <div>- ${value.total ?? "--"}</div>
              </div>
            </div>
            <NuModal isOpen={discountModalOpen} title="Edit Discount">
              <DiscountModal
                existingDiscount={value}
                discountIdx={discountIdx}
                closeModal={toggleDiscountModal}
                onSubmit={onSubmit}
              />
            </NuModal>
          </Fragment>
        );
      }}
    </Field>
  );
};

export const AddDiscount: React.FC<{ calculatePossibleEJIPriceInfo: any; ejiType?: string }> = ({
  calculatePossibleEJIPriceInfo,
  ejiType,
}) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const spacingClass = classNames("flex", "flex-row", "justify-between", "m-4");
  const textClass = classNames("text-xs", "flex", "flex-row");
  const show = useShowSuccess();
  return (
    <div className={spacingClass}>
      <div className={textClass}>
        Discount (
        <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer">
          Add
        </div>
        )
      </div>
      <Field name="discounts">
        {({ form: { values } }) => {
          const onSubmit = (discount) => {
            const discountAdded = [...(values.discounts ?? [])];
            discountAdded.push(discount);
            calculatePossibleEJIPriceInfo({
              variables: {
                calculatePossibleEJIPriceInfoInput: {
                  services: cleanedServices(values.services),
                  discounts: cleanedDiscounts(discountAdded),
                  marketName: values.market,
                  taxable: values.taxable,
                  calculateAllServices: ejiType === "INVOICE",
                },
              },
            });
            toggleDiscountModal();
            show({ message: "Discount added successfully" });
          };
          return (
            <NuModal isOpen={discountModalOpen} title="Add Discount">
              <DiscountModal closeModal={toggleDiscountModal} onSubmit={onSubmit} />
            </NuModal>
          );
        }}
      </Field>
    </div>
  );
};

export const cleanedDiscounts = (discounts: EjiDiscount[]): EjiDiscountInput[] =>
  discounts?.map(({ type, amount, reason, ...rest }) => ({ type, amount, reason }));
