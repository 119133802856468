import gql from "graphql-tag";

export const GET_INVOICES_PAGINATED = gql`
  query getInvoicesPaginated($paginatedQueryInput: PaginatedQueryInput!) {
    getInvoicesPaginated(paginatedQueryInput: $paginatedQueryInput) {
      edges {
        id
        issuedDate
        balanceDue
        total
        market
        invoiceNumber
        createdBy
        job {
          id
          status
        }
        status
        contact {
          id
          firstName
          lastName
        }
        estimate {
          id
        }
      }
      pageInfo {
        hasNextPage
        offset
        numRecords
      }
    }
  }
`;
