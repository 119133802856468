import gql from "graphql-tag";

export const GET_DRIVE_TIME = gql`
  query getDriveTime($addresses: [String]!) {
    getDriveTime(addresses: $addresses) {
      distance
      duration
      status
    }
  }
`;
