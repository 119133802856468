import { Column, PluginHook, useFlexLayout, useTable } from "react-table";
import { BaseTableUI } from "./BaseTableUI";
import { BaseTableUIEditable } from "./BaseTableUiEditable";
import React from "react";

interface EntityTableProps<T extends object> {
  data: any[];
  columns: Array<Column<T>>;
  numRecords: number;
  pluginHooks: PluginHook<T>;
  title: string;
  loadMore?: () => void;
  editable?: boolean;
  endAdornments?: React.ReactNode;
}

export const EntityTable = <T extends object>({
  data,
  title,
  columns,
  pluginHooks,
  editable,
  endAdornments,
}: EntityTableProps<T>) => {
  const defaultPageSize = 50;
  const useTableProps = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: defaultPageSize,
      },
    },
    useFlexLayout,
    pluginHooks
  );
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow } = useTableProps;
  return (
    <>
      {editable ? (
        <BaseTableUIEditable
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      ) : (
        <BaseTableUI
          title={title}
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
          numSelected={0}
          endAdornments={endAdornments}
        />
      )}
    </>
  );
};
