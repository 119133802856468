import { Container } from "../../components/Container";
import React from "react";
import { ProductDetailsForm } from "../../components/Forms/ProductDetailsForm";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateProductArgs } from "../../generated/nest-graphql";
import { CREATE_PRODUCT } from "../../graphql/mutations/createProduct";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";

const AddProductsPage = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createProduct] = useMutation<Mutation, MutationCreateProductArgs>(CREATE_PRODUCT);
  const onSubmit = async (values, formikHelpers) => {
    const result = await createProduct({
      variables: {
        createProductInput: values,
      },
    });
    const id = result.data.createProduct.id;
    showSuccess({ message: "Successfully Added Product" });
    await history.push(`/products/${id}`);
  };

  return (
    <div>
      <Container>
        <div className="mt-16" />
        <h3>Create a Product</h3>
        <ProductDetailsForm
          initialValues={{
            amount: "",
            description: "",
            laborCost: "",
            name: "",
            partsCost: "",
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </div>
  );
};
export default AddProductsPage;
