import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./pages";
import { Layout } from "./components/Layout";
import AddContactPage from "./pages/add/contacts";
import AddEstimatePage from "./pages/add/estimates";
import AddInvoicesPage from "./pages/add/invoices";
import AddJobsPage from "./pages/add/jobs";
import AddProductsPage from "./pages/add/products";
import AddProductSetPage from "./pages/add/product-sets";
import AddTechniciansPage from "./pages/add/technicians";
import AddOpsManager from "./pages/add/ops-manager";
import ContactsPage from "./pages/contacts";
import ContactsDetailPage from "./pages/contacts/[id]";
import EstimatesPage from "./pages/estimates";
import EstimatesDetailPage from "./pages/estimates/[id]";
import InvoicesPage from "./pages/invoices";
import InvoiceDetailsPage from "./pages/invoices/[id]";
import JobsPage from "./pages/jobs";
import JobDetailsPage from "./pages/jobs/[id]";
import PaymentsPage from "./pages/payments";
import ProductsPage from "./pages/products";
import ProductDetailsPage from "./pages/products/[id]";
import ProductSetsPage from "./pages/product-sets";
import ProductSetDetailsPage from "./pages/product-sets/[id]";
import SchedulingPage from "./pages/scheduling";
import TechniciansPage from "./pages/technicians";
import TechnicianDetailsPage from "./pages/technicians/[id]";
import SearchPage from "./pages/search";
import DriveTimeDistancePage from "./pages/drive-time-distance";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { AddMarketPage } from "./pages/add/markets";
import MarketsPage from "./pages/markets";
import { MarketsDetailPage } from "./pages/markets/[id]";
import { AddPartsStorePage } from "./pages/add/parts-stores";
import PartsStoresPage from "./pages/parts-stores";
import { PartsStoresDetailPage } from "./pages/parts-stores/[id]";
import { authorizedEmails } from "./data/authorizedEmails";
import { includes } from "ramda";
import { PricingToolPage } from "./pages/pricing-tool";

export const Routes: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      Sentry.setUser({ username: user.name });
      LogRocket.identify(user.name);
    }
  }, [isAuthenticated, isLoading, user]);
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
    return null;
  }
  if (!isLoading && isAuthenticated) {
    return (
      <Router>
        <Switch>
          <Layout>
            <Route component={Home} path={"/"} exact />
            <Route path={"/add/contacts"} component={AddContactPage} />
            <Route path={"/add/estimates"} component={AddEstimatePage} />
            <Route path={"/add/markets"} component={AddMarketPage} />
            <Route path={"/add/invoices"} component={AddInvoicesPage} />
            <Route path={"/add/jobs"} component={AddJobsPage} />
            <Route path={"/add/products"} component={AddProductsPage} />
            <Route path={"/add/product-sets"} component={AddProductSetPage} />
            <Route path={"/add/technicians"} component={AddTechniciansPage} />
            <Route path={"/add/parts-store"} component={AddPartsStorePage} />
            {includes(user.email, authorizedEmails) && <Route path={"/add/ops-manager"} component={AddOpsManager} />}
            <Route path={"/contacts"} exact={true} component={ContactsPage} />
            <Route path={"/contacts/:id"} component={ContactsDetailPage} />
            <Route path={"/estimates"} exact={true} component={EstimatesPage} />
            <Route path={"/estimates/:id"} exact={true} component={EstimatesDetailPage} />
            <Route path={"/invoices"} exact={true} component={InvoicesPage} />
            <Route path={"/invoices/:id"} component={InvoiceDetailsPage} />
            <Route path={"/jobs"} exact={true} component={JobsPage} />
            <Route path={"/jobs/:id"} component={JobDetailsPage} />
            <Route path={"/payments"} exact={true} component={PaymentsPage} />
            <Route path={"/products"} exact={true} component={ProductsPage} />
            <Route path={"/products/:id"} component={ProductDetailsPage} />
            <Route path={"/product-sets"} exact={true} component={ProductSetsPage} />
            <Route path={"/product-sets/:id"} component={ProductSetDetailsPage} />
            <Route path={"/scheduling"} exact={true} component={SchedulingPage} />
            <Route path={"/technicians"} exact={true} component={TechniciansPage} />
            <Route path={"/technicians/:id"} component={TechnicianDetailsPage} />
            <Route path={"/markets/:id"} component={MarketsDetailPage} />
            <Route path={"/markets"} component={MarketsPage} exact={true} />
            <Route path={"/parts-stores/:id"} component={PartsStoresDetailPage} />
            <Route path={"/parts-stores"} component={PartsStoresPage} exact={true} />
            <Route path={"/search"} component={SearchPage} />
            <Route path={"/drive_time_distance"} exact={true} component={DriveTimeDistancePage} />
            <Route path={"/pricing-tool"} exact={true} component={PricingToolPage} />
          </Layout>
        </Switch>
      </Router>
    );
  }
  return null;
};
