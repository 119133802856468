import React from "react";
import NumberFormat from "react-number-format";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}

const PayMultiplierInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
}> = ({ name, label, required = false, readOnly = false }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, meta, helpers] = useField(name);
  return (
    <TextField
      fullWidth
      error={!!meta.error}
      variant={"outlined"}
      name={name}
      disabled={readOnly}
      label={label}
      required={required}
      helperText={<ErrorTextField fieldName={name} displayError={!!meta.error} />}
      value={Number(value).toFixed(2)}
      onChange={(e) => {
        const value = e.target.value;
        helpers.setValue(value);
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

export default PayMultiplierInput;
