import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetContactArgs,
  QueryGetDefaultServicesArgs,
  QueryGetInvoicesPaginatedArgs,
} from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { path, pathOr, cond, T } from "ramda";
import { Button } from "../Buttons/Button";
import { AddInvoice } from "../Invoices/AddInvoice";
import { InvoiceColumns, invoicesToInvoiceColumns } from "../Invoices/InvoicesTable";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";

export const ContactsInvoices: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [enableAddInvoice, setEnableAddInvoice] = useState(false);
  const limit = 50;
  const { data } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          contact: contactId,
        },
        limit,
        skip: 0,
      },
    },
  });
  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["lead", "vehicleInfo", "year"], contactResult?.data?.getContact ?? {}),
        make: pathOr("", ["lead", "vehicleInfo", "make"], contactResult?.data?.getContact ?? {}),
        model: pathOr("", ["lead", "vehicleInfo", "model"], contactResult?.data?.getContact ?? {}),
      },
    },
  });
  if (!data || !path(["data", "getContact"], contactResult) || !defaultServices) return null;
  const contactData = contactResult.data.getContact;
  const vehicleInfo = path(["lead", "vehicleInfo"], contactData);
  const serviceLocation = path(["lead", "address"], contactData);
  const market = path(["lead", "market"], contactData);
  return (
    <div className={"px-4"}>
      {enableAddInvoice ? (
        <AddInvoice
          initialValues={{
            status: "Draft",
            taxable: true,
            contact: contactData,
            serviceLocation,
            market,
            items: [],
            serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
            services: cond([
              [
                () => DEFAULT_SERVICE_CATALOGUE_USED === SERVICE_CATALOGUE_USED_SERVICES,
                () =>
                  defaultServices.getDefaultServices.map((service) => {
                    return {
                      ...service,
                      items: service.items.filter(({ behavior }) => behavior === "Default"),
                    };
                  }),
              ],
              [T, () => []],
            ])(null),
            discounts: [],
            issuedDate: new Date().toISOString(),
            dueDate: new Date().toISOString(),
            // @ts-ignore
            ...vehicleInfo,
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-end">
            <div className="my-4 mr-0">
              <Button onClick={() => setEnableAddInvoice(true)}>+ Add Invoice</Button>
            </div>
          </div>
          <SelectablePageableEntityTable
            title={"Invoices"}
            queryResult={data}
            columns={InvoiceColumns}
            queryKey={"getInvoicesPaginated"}
            numRecords={data.getInvoicesPaginated.pageInfo.numRecords}
            limit={50}
            spec={invoicesToInvoiceColumns}
          />
        </>
      )}
    </div>
  );
};
