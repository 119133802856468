import gql from "graphql-tag";

export const CREATE_PRODUCT = gql`
  mutation createProduct($createProductInput: CreateProductInput!) {
    createProduct(createProductInput: $createProductInput) {
      id
      name
      amount
      laborCost
      partsCost
      description
    }
  }
`;
