import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { SubmitButton } from "../Buttons/SubmitButton";
import { TextField } from "../FormFields/TextField";
import { NumberInput } from "../FormFields/NumberInput";
import { SelectField } from "../FormFields/SelectField";
import { vendors } from "../PartsStores/PartsStoreConstants";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { AustoselectMarketSelectField, MarketOption } from "../FormFields/AutocompleteMarketSelectField";

export type PartsStoreFormValues = {
  storeNumber?: string;
  name?: string;
  address?: string;
  vendor?: string;
  market?: MarketOption;
};

export const partsStoreSchema = Yup.object().shape({
  storeNumber: Yup.string().required("Required").trim().min(1),
  name: Yup.string().required("Required").trim().min(1),
  market: Yup.string().required("Required"),
  vendor: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
});

type PartsStoreFormProps = {
  initialValues: PartsStoreFormValues;
  onSubmit: any;
  partsStoreName?: string;
};

export const PartsStoreForm = ({ initialValues, onSubmit, partsStoreName }: PartsStoreFormProps) => {
  return (
    <Formik<PartsStoreFormValues> initialValues={initialValues} onSubmit={onSubmit} validationSchema={partsStoreSchema}>
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Box className="grid grid-cols-2 gap-4">
              {partsStoreName && (
                <Box className={"col-span-2"}>
                  <Typography variant={"h3"}>{partsStoreName}</Typography>
                </Box>
              )}
              <NumberInput name={"storeNumber"} label={"Store Number"} required={true} />
              <TextField name={"name"} label={"Store Name"} required={true} />
              <Box className={"col-span-2"}>
                <LocationSearchInput name={"address"} label={"Address"} required={true} />
              </Box>
              <Field name="market">
                {({ field, meta, form }) => (
                  <AustoselectMarketSelectField
                    name={"market"}
                    value={field.value}
                    required={true}
                    onChange={(event, newValue: MarketOption) => {
                      form.setFieldValue("market", newValue);
                    }}
                    error={meta.error}
                  />
                )}
              </Field>
              <SelectField name={"vendor"} options={vendors} required={true} label={"Vendor"} />
              <div className="flex flex-row justify-between">
                <div>
                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                    Submit
                  </SubmitButton>
                </div>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
