import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useQuery } from "@apollo/client";
import { Query } from "../../generated/nest-graphql";
import { GET_DRIVE_TIME } from "../../graphql/queries/getDriveTime";
import { formatMetersToMiles, isPresent, secondsToTime } from "../../lib/functions";
import { filter, join, map, path, pipe, prop, propOr, propEq, sum } from "ramda";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    background: "#000",
    minWidth: 200,
    border: "2px solid #a9a9a9",
  },
  googleMapsButton: {
    marginTop: 3,
    fontSize: 10,
  },
});

export const DriveTimeDistanceTableHeaderCell: React.FC<{
  technicians: any[];
}> = ({ technicians }) => {
  const classes = useStyles();
  return (
    <>
      {isPresent(technicians) ? (
        technicians.map((technician, index) => {
          const { firstName, lastName, appointments } = technician;
          return (
            <TableCell key={index} className={classes.root}>
              <Typography variant="body1" gutterBottom>
                {`${firstName} ${lastName}`}
              </Typography>
              {appointments.length < 2 ? (
                <>
                  <Typography variant="caption" display="block">
                    Total Travel Distance: 0
                  </Typography>
                  <Typography variant="caption" display="block">
                    Total Travel Duration: 0
                  </Typography>
                </>
              ) : (
                <TravelDistanceTotals technician={technician} />
              )}
            </TableCell>
          );
        })
      ) : (
        <TableCell className={classes.root}></TableCell>
      )}
    </>
  );
};

const TravelDistanceTotals: React.FC<{ technician: any }> = ({ technician }) => {
  const classes = useStyles();
  const appointmentLocations = pipe(prop("appointments"), map(path(["job", "serviceLocation"])))(technician);

  const { data } = useQuery<Query>(GET_DRIVE_TIME, {
    variables: {
      addresses: appointmentLocations,
    },
  });
  if (!data) {
    return null;
  }

  // Filter: only google-api determined status: OK, else ignore row
  // Else possible status results: [NOT_FOUND, ZERO_RESULTS, MAX_ROUTE_LENGTH_EXCEEDED]
  const driveTimeDistance = pipe(
    propOr([], "getDriveTime"),
    // @ts-ignore
    filter(propEq("status", "OK"))
    // @ts-ignore
  )(data);

  const totalDistance = sum(
    // @ts-ignore
    map(path(["distance", "value"]))(driveTimeDistance)
  );
  const totalDuration = sum(
    // @ts-ignore
    map(path(["duration", "value"]))(driveTimeDistance)
  );
  return (
    <>
      <Typography variant="caption" display="block">
        Total Distance: {totalDistance ? formatMetersToMiles(totalDistance) : 0}
      </Typography>
      <Typography variant="caption" display="block">
        Total Time: {totalDuration ? secondsToTime(totalDuration) : 0}
      </Typography>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://google.com/maps/dir/${join("/", appointmentLocations)}?mode=driving`}
      >
        <Button className={classes.googleMapsButton} variant="contained" size="small" color="primary">
          View in Google Maps
        </Button>
      </a>
    </>
  );
};
