/* eslint-disable react-hooks/rules-of-hooks */
import currency from "currency.js";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { OptionType } from "../FormFields/AutocompleteVehicleSelect";
import { PricingToolInnerForm } from "../PricingTool/PricingToolInnerForm";
import PricingTable from "../PricingTool/PricingTable";
import { Alert } from "@material-ui/lab";
import { length, complement, either, find, filter, isNil, pluck, propEq, propOr, defaultTo, add } from "ramda";
import { flow } from "fp-ts/lib/function";
import { PricingTableData, PricingTableDataRow, usePricingToolTableData } from "../PricingTool/usePricingToolTableData";
import { GET_SERVICE_CATALOGUE_ITEM } from "../../graphql/queries/getServiceCatalogueItem";
import { Query, QueryGetServiceCatalogueItemArgs } from "../../generated/nest-graphql";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { valToString, currencyTotalFromObjectPath } from "../../lib/functions";
import { AddableItemRow, AddablePricingTable } from "../PricingTool/AddablePricingTable";
import { Row } from "react-table";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { CartLineItem } from "../PricingTool/CartLineItem";
import { useAddableData } from "../../hooks/useAddableData";
import { useMarkets } from "../../hooks/useMarkets";

type PromoOption = {
  name: string;
  value: number;
};

export type ItemOption = {
  name: string;
  value: string;
};

export type ItemOptions = {
  frontPads: ItemOption;
  rearPads: ItemOption;
  frontRotors: ItemOption;
  rearRotors: ItemOption;
  shoes: ItemOption;
  drums: ItemOption;
};

export interface PricingToolFormValues {
  market: string;
  year: OptionType;
  make: OptionType;
  model: OptionType;
  subModel: OptionType;
  percentageDiscount: PromoOption;
  options: ItemOptions;
  addableItemRows: any[];
  dayOfWeek: OptionType;
  leadTime: OptionType;
}

const pricingToolSchema = Yup.object().shape({
  market: Yup.string().required("Required"),
  year: Yup.string().required("Required").min(1),
  make: Yup.string().required("Required").min(1),
  model: Yup.string().required("Required").min(1),
  subModel: Yup.string().required("Required").min(1),
  percentageDiscount: Yup.object({
    name: Yup.string().required("Required"),
    value: Yup.number().required("Required"),
  }).nullable(),
  dayOfWeek: Yup.object().shape({
    name: Yup.string(),
    value: Yup.string(),
  }),
  leadTime: Yup.object().shape({
    name: Yup.string(),
    value: Yup.string(),
  }),
});

export const initialAddableItemRows = [
  {
    item: "",
    name: "",
    partsCost: "",
    parts: "",
    labor: "",
    laborHours: "",
    discount: "",
  },
  {
    item: "",
    name: "",
    partsCost: "",
    parts: "",
    labor: "",
    laborHours: "",
    discount: "",
  },
  {
    item: "",
    name: "",
    partsCost: "",
    parts: "",
    labor: "",
    laborHours: "",
    discount: "",
  },
  {
    item: "",
    name: "",
    partsCost: "",
    parts: "",
    labor: "",
    laborHours: "",
    discount: "",
  },
];

const initialValues = {
  market: null,
  year: null,
  make: null,
  model: null,
  subModel: null,
  percentageDiscount: {
    name: "None",
    value: 0,
  },
  addableItemRows: initialAddableItemRows,
  dayOfWeek: null,
  leadTime: null,
  options: {
    frontPads: { name: "Premium", value: "premiumOption" },
    rearPads: { name: "Premium", value: "premiumOption" },
    frontRotors: { name: "Standard", value: "standardOption" },
    rearRotors: { name: "Standard", value: "standardOption" },
    shoes: { name: "Standard", value: "standardOption" },
    drums: { name: "Standard", value: "standardOption" },
  },
};
export const PricingToolForm = () => {
  const [selectedStandardRows, setSelectedStandardRows] = useState<Row<PricingTableDataRow>[]>([]);
  const onStandardRowsSelected = (rows) => {
    setSelectedStandardRows(rows);
  };
  const [selectedAddableRows, setSelectedAddableRows] = useState<Row<AddableItemRow>[]>([]);
  const onAddableRowsSelected = (rows) => {
    setSelectedAddableRows(rows);
  };

  const [resetCount, setResetCount] = useState(0);
  const markets: any = useMarkets();

  return (
    <>
      <Formik<PricingToolFormValues>
        enableReinitialize
        validationSchema={pricingToolSchema}
        initialValues={initialValues}
        onSubmit={() => null}
      >
        {({ values, setFieldValue, resetForm }) => {
          const addableData = useAddableData(values);
          const [queryServiceCatalogueItem, { data: serviceCatalogueItemData }] = useLazyQuery<
            Query,
            QueryGetServiceCatalogueItemArgs
          >(GET_SERVICE_CATALOGUE_ITEM, { fetchPolicy: "network-only" });
          const { make, model, year, subModel, percentageDiscount, market, dayOfWeek, leadTime, options } = values;
          const tableData: PricingTableData = usePricingToolTableData({
            percentageDiscount,
            make,
            model,
            year,
            subModel,
            market,
            dayOfWeek,
            leadTime,
            options,
            serviceCatalogueItemData,
          });
          // get market tax rates
          const currentMarket = flow(defaultTo([]), find(propEq("name", market)))(markets);
          const partsTaxRate: number = flow(propOr(0, "partsTaxRate"))(currentMarket);
          const laborTaxRate: number = flow(propOr(0, "laborTaxRate"))(currentMarket);

          // get repair totals
          const standardRepairTotal = currencyTotalFromObjectPath(["values", "finalPrice"], selectedStandardRows);
          const addableRepairTotal = currencyTotalFromObjectPath(["values", "finalPrice"], selectedAddableRows);

          // get discounts
          const discounts = currencyTotalFromObjectPath(["values", "discount"], selectedAddableRows)
            .add(currencyTotalFromObjectPath(["values", "discount"], selectedStandardRows))
            .add(tableData.discounts ? currency(tableData.discounts) : currency(0));

          // get standard and manually added labor and partscosts
          const laborCost = currencyTotalFromObjectPath(["values", "labor"], selectedStandardRows)
            .add(currencyTotalFromObjectPath(["values", "labor"], selectedAddableRows))
            .subtract(discounts);
          const partsCost = currencyTotalFromObjectPath(["values", "parts"], selectedStandardRows).add(
            currencyTotalFromObjectPath(["values", "parts"], selectedAddableRows)
          );

          // calculate taxes from parts and labor cost against parts and labor tax rates
          const laborTax = laborCost.multiply(currency(laborTaxRate, { precision: 5 }).divide(100));
          const partsTax = partsCost.multiply(currency(partsTaxRate, { precision: 5 }).divide(100));

          // total calculations
          const tax = laborTax.add(partsTax);
          const combinedRepairTotal = addableRepairTotal.add(standardRepairTotal);
          const fees = tableData.fees ? currency(tableData.fees) : currency(0);
          const total = combinedRepairTotal.add(fees).add(tax);

          const hasDrumsAndShoes = flow(
            filter(either(propEq("item", "Rear Drums"), propEq("item", "Rear Shoes"))),
            (val) => pluck("price", val as any[]),
            filter(complement(isNil)),
            length,
            (val: number | null) => !!val
          )(tableData.rows);
          return (
            <Form>
              <div className="h-screen flex -mt-24">
                <div className="flex-1 flex overflow-hidden pr-3">
                  <div className="flex-1 overflow-y-scroll no-scrollbar px-3 mt-24">
                    <h3>Pricing Tool</h3>
                    <PricingToolInnerForm
                      values={values}
                      setFieldValue={setFieldValue}
                      queryServiceCatalogueItem={queryServiceCatalogueItem}
                    />
                    <div>
                      {hasDrumsAndShoes && (
                        <Alert severity="warning">ASK ABOUT DRUMS/SHOE vs PAD/ROTOR -- Andrew</Alert>
                      )}
                      <PricingTable
                        data={tableData.rows}
                        onRowSelected={onStandardRowsSelected}
                        resetCount={resetCount}
                      />
                      <Box mt={3} mb={1}>
                        <Typography variant={"h4"}>Manually Add Items</Typography>
                      </Box>
                      <AddablePricingTable
                        data={addableData as any}
                        resetCount={resetCount}
                        onRowSelected={onAddableRowsSelected}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="py-4 w-1/5 mt-24">
                  <Paper elevation={3} variant="outlined">
                    <Box p={2}>
                      <CartLineItem title={"Repair Total"} value={valToString(combinedRepairTotal)} />
                      <CartLineItem subItem title={"Parts"} value={valToString(partsCost)} />
                      <CartLineItem subItem title={"Labor"} value={valToString(laborCost)} />
                      <CartLineItem title={"Fees"} value={valToString(fees)} />
                      <CartLineItem title={"Tax"} value={valToString(tax)} />
                      <CartLineItem
                        title={<Box fontWeight="fontWeightBold">Total</Box>}
                        value={<Box fontWeight="fontWeightBold">{valToString(total)}</Box>}
                      />
                    </Box>
                  </Paper>
                  <Box display="flex" mt={4} justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        resetForm();
                        setResetCount(add(1));
                      }}
                    >
                      Reset Form
                    </Button>
                  </Box>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
