import React from "react";
import { PartsStore } from "../../generated/nest-graphql";
import { defaultTo, isNil, map } from "ramda";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";

export type PartsStoreOption = {
  label: string | null;
  value: PartsStore | null;
};

export type PartsStoreFieldProps = {
  name: string;
  label: string;
  value: any;
  error: any;
  onChange: any;
  options: any;
  required?: boolean;
};

export const partsStoresToOption = (partsStore: PartsStore) => {
  const { vendor, name } = partsStore;
  return {
    label: `${vendor} - ${name}`,
    value: partsStore,
  };
};

export const PartsStoreSelectField = ({
  name,
  label,
  value,
  error,
  onChange,
  options,
  required = false,
}: PartsStoreFieldProps) => {
  const partsStoresOptions = map(partsStoresToOption)(options);
  const filterOptions = createFilterOptions<PartsStoreOption>({
    matchFrom: "any",
    stringify: (option) => {
      return option.label || "";
    },
  });
  const currentOption = isNil(value) ? { value: null, label: "" } : partsStoresToOption(value);
  if (!options || partsStoresOptions.length === 0) return null;
  return (
    <Autocomplete<PartsStoreOption>
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return defaultTo("", option.label);
      }}
      value={currentOption}
      options={partsStoresOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          error={!!error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          helperText={<>{error && <ErrorTextField displayOnSpan={true} fieldName={name} />}</>}
        />
      )}
    />
  );
};
