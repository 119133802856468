import React, { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ErrorTextField } from "./ErrorTextField";
import { flow } from "fp-ts/lib/function";
import { prop } from "ramda";
import { debounce } from "lodash";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      isAllowed={(values: any) => {
        const value = flow(prop("value"), Number)(values);
        return value >= 0 && value <= 10; // allow only 0 - 10 %
      }}
    />
  );
}

export const TaxInput: React.FC<{
  name: string;
  label: string;
  value: string;
  error: boolean;
  onChange: any;
  required?: boolean;
  readOnly?: boolean;
}> = ({ error, name, label, value, onChange, required = false, readOnly = false }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(onChange, 500), []);
  const [textVal, setTextVal] = useState(value ?? "");
  return (
    <TextField
      fullWidth
      error={error}
      variant={"outlined"}
      name={name}
      disabled={readOnly}
      label={label}
      onChange={(e) => {
        const value = e.target.value;
        setTextVal(value);
        handler(value);
      }}
      required={required}
      helperText={<ErrorTextField fieldName={name} displayError={error} />}
      value={textVal}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};
