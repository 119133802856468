import * as React from "react";
import { JobFeedback } from "../../generated/nest-graphql";
import { Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";
import { NonFormikAutoCompleteCheckboxes as AutoCompleteCheckboxes } from "../FormFields/AutoCompleteCheckboxes";
import { prop, propOr } from "ramda";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";

const doneBetterOptions = [
  "Incorrect parts ordered",
  "Incomplete parts ordered",
  "Error in preliminary diagnosis",
  "Distance from previous job",
  "Expectation setting with customer",
  "Customer preparation",
  "Customer timeliness",
  "Payment collection",
  "Technician support timeliness",
];

export const JobRating: React.FC<{ feedback: JobFeedback | null }> = ({ feedback }) => {
  if (feedback) {
    return (
      <div className="w-3/6">
        <DetailViewContainer title="Technician feedback">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <Typography>Job Rating</Typography>
              <Rating size="large" value={prop("rating", feedback)} disabled={true} />
            </div>
            <AutoCompleteCheckboxes
              value={propOr([], "doneBetter", feedback)}
              label="What could have been done better?"
              options={doneBetterOptions}
              disabled={true}
            />
            <TextField
              disabled={true}
              fullWidth
              variant={"outlined"}
              multiline={true}
              rows={3}
              label="Additional Comments"
              value={prop("additionalComments", feedback)}
            />
          </div>
        </DetailViewContainer>
      </div>
    );
  } else {
    return (
      <div>
        <Typography variant="h6">No Feedback</Typography>
      </div>
    );
  }
};
