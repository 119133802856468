import gql from "graphql-tag";

export const marketVarianceString = `
    pads {
      standardOption
      premiumOption
      performanceOption
    }
    rotors {
      standardOption
      premiumOption
      performanceOption
    }
    shoes {
      standardOption
      premiumOption
      performanceOption
    }
    drums {
      standardOption
      premiumOption
      performanceOption
    }
    daysOfWeek {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    leadTime {
      sameDay
      nextDay
      twoPlusDays
    }
  `;

export const marketFields = `
      id
      name
      timeZone
      phoneNumber
      email
      address
      businessID
      activeServiceArea
      activeServiceAreaGeoJson
      marketArea
      marketAreaGeoJson
      variance {
        ${marketVarianceString}
      }
      salesTaxType
      partsTaxRate
      laborTaxRate
`;

export const GET_MARKET = gql`
  query getMarket($id: ID!) {
    getMarket(id: $id) {
      ${marketFields}
    }
  }
`;
