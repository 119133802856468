import * as React from "react";
import { CancelButton } from "./Buttons/CancelButton";
import { SubmitButton } from "./Buttons/SubmitButton";

type SaveCancelRowProps = {
  width: number;
  onCancel: any;
  isSubmitting: boolean;
  isValid: boolean;
  midExtra?: React.ReactNode;
  offsetLeft?: number;
  notFixed?: boolean;
};

export const SaveCancelRow: React.FC<SaveCancelRowProps> = ({
  isValid,
  isSubmitting,
  onCancel,
  width,
  midExtra,
  offsetLeft = 256,
  notFixed = false,
}) => {
  return (
    <div
      className="bg-black"
      style={
        notFixed
          ? {}
          : {
              position: "fixed",
              width,
              left: offsetLeft,
              bottom: 0,
              zIndex: 10,
            }
      }
    >
      <div className="flex flex-row justify-between">
        <div>
          <CancelButton onClick={onCancel} type={"reset"}>
            Cancel
          </CancelButton>
        </div>
        <div className="flex">{midExtra}</div>
        <div className="flex justify-end">
          <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};
