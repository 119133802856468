import * as React from "react";
import { Container } from "../../components/Container";
import { AddInvoice } from "../../components/Invoices/AddInvoice";

export const AddInvoicesPage: React.FC<{}> = () => {
  return (
    <div>
      <Container>
        <div className="mt-16" />
        <h3>Create an Invoice</h3>
        <AddInvoice />
      </Container>
    </div>
  );
};

export default AddInvoicesPage;
