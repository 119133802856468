import * as React from "react";
import { Form, Formik } from "formik";
import { SelectField } from "../FormFields/SelectField";
import { cond, equals, add, defaultTo } from "ramda";
import { CashAndCheckFields } from "./CashAndCheckFields";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationCreatePaymentArgs, Query, QueryGetInvoiceArgs } from "../../generated/nest-graphql";
import { CREATE_PAYMENT } from "../../graphql/mutations/createPayment";
import { CreditCardFields } from "./CreditCardFields";
import { Elements } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { useHistory } from "react-router-dom";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { cleanCurrency } from "../../lib/functions";
import { stripePromise } from "../../App";
import { flow } from "fp-ts/lib/function";

export type AddPaymentFormValues = {
  paymentMethod: string;
  type: string;
  receivedDate: Date;
  amount: string;
  invoicePrice?: string;
  refNumber?: string;
  memo?: string;
  tip?: string;
  sendReceipt: boolean;
  payer: string;
  source: string;
  paymentIntentId?: string;
};
const validationSchema = Yup.object().shape({
  payer: Yup.string().required("Required").min(1),
  amount: Yup.number().required("Required").min(1),
});

export const AddPaymentForm: React.FC<{
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  subTotal: string;
  totalTax: string;
  partsTotal: string;
  laborTotal: string;
  feesTotal: string;
  discountTotal: string;
  finalPartsTotal: string;
  finalLaborTotal: string;
  finalFeesTotal: string;
  finalSubTotal: string;
  amount: string;
  invoiceId: string;
  contactId: string;
  defaultPayer: string;
}> = ({
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  totalTax,
  partsTotal,
  laborTotal,
  feesTotal,
  discountTotal,
  finalPartsTotal,
  finalLaborTotal,
  finalFeesTotal,
  finalSubTotal,
  amount,
  invoiceId,
  contactId,
  defaultPayer,
}) => {
  const [createPayment] = useMutation<Mutation, MutationCreatePaymentArgs>(CREATE_PAYMENT);
  const showSuccess = useShowSuccess();
  const { client, data } = useQuery<Query, QueryGetInvoiceArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });
  const { push } = useHistory();
  return (
    <Formik<AddPaymentFormValues>
      validationSchema={validationSchema}
      initialValues={{
        source: "",
        paymentMethod: "",
        type: "Invoice",
        amount: amount,
        receivedDate: new Date(),
        sendReceipt: true,
        tip: "",
        payer: "",
      }}
      onSubmit={async (
        {
          amount,
          payer,
          source,
          sendReceipt,
          type,
          memo,
          tip,
          paymentMethod,
          receivedDate,
          refNumber,
          paymentIntentId,
        },
        helpers
      ) => {
        // @ts-ignore
        const amountWithTip = flow(defaultTo(0), add(amount), String)(tip);
        await createPayment({
          variables: {
            createPaymentInput: {
              laborCost,
              partsCost,
              partsTax,
              laborTax,
              subTotal,
              totalTax,
              partsTotal,
              laborTotal,
              feesTotal,
              discountTotal,
              finalPartsTotal,
              finalLaborTotal,
              finalFeesTotal,
              finalSubTotal,
              paymentIntentId,
              amount: cleanCurrency(amountWithTip),
              invoicePrice: cleanCurrency(amount),
              payer,
              paymentMethod,
              receivedDate,
              type,
              status: "succeeded",
              source,
              invoice: invoiceId,
              contact: contactId,
              memo,
              tip: cleanCurrency(tip),
              refNumber,
              sendReceipt,
            },
          },
        });
        showSuccess({ message: "Successfully Added Payment" });
        window.location.reload();
      }}
    >
      {({ values }) => {
        return (
          <Form>
            <div className="grid gap-4">
              <SelectField name={"paymentMethod"} options={["Cash", "Check", "Credit Card"]} label={"Type"} />
              {cond([
                [equals("Cash"), () => <CashAndCheckFields payer={defaultPayer} />],
                [equals("Check"), () => <CashAndCheckFields payer={defaultPayer} />],
                [
                  equals("Credit Card"),
                  () => (
                    <Elements stripe={stripePromise}>
                      <CreditCardFields
                        laborCost={laborCost}
                        partsCost={partsCost}
                        partsTax={partsTax}
                        laborTax={laborTax}
                        subTotal={subTotal}
                        totalTax={totalTax}
                        partsTotal={partsTotal}
                        laborTotal={laborTotal}
                        feesTotal={feesTotal}
                        discountTotal={discountTotal}
                        finalPartsTotal={finalPartsTotal}
                        finalLaborTotal={finalLaborTotal}
                        finalFeesTotal={finalFeesTotal}
                        finalSubTotal={finalSubTotal}
                        invoiceId={invoiceId}
                        contactId={contactId}
                        postCreditCard={async () => {
                          client.writeQuery<Query, QueryGetInvoiceArgs>({
                            variables: {
                              id: invoiceId,
                            },
                            // @ts-ignore
                            data: {
                              getInvoice: {
                                ...data.getInvoice,
                                status: "Paid",
                              },
                            },
                            query: GET_INVOICE,
                          });
                          push(`/invoices/${invoiceId}`);
                        }}
                      />
                    </Elements>
                  ),
                ],
              ])(values.paymentMethod)}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
