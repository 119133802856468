import * as React from "react";
import { PreJobCheckList } from "../../generated/nest-graphql";
import { List, Paper, Typography, ListItem, ListItemText } from "@material-ui/core";

export const JobChecklist: React.FC<{ preJobCheckList: PreJobCheckList }> = ({ preJobCheckList }) => {
  const checklist = preJobCheckList
    ? Object.keys(preJobCheckList)
        .filter((item) => preJobCheckList[item] && preJobCheckList[item].partCondition)
        .map((item) => [item, preJobCheckList[item].partCondition, preJobCheckList[item].additionalInfo])
    : [];
  return (
    <>
      <Paper className="mt-2 mb-3" elevation={3} square>
        <List dense>
          {checklist.length === 0 ? (
            <EmptyList />
          ) : (
            checklist.map(([label, value, other], index) => (
              <ListItem button key={index}>
                <ListItemText
                  primary={
                    <Typography className={"block"} component={"div"} style={{ textTransform: "capitalize" }}>
                      {label}
                    </Typography>
                  }
                  secondary={other}
                />{" "}
                {value}
              </ListItem>
            ))
          )}
        </List>
      </Paper>
    </>
  );
};

const EmptyList = () => (
  <div className="flex content-center justify-center p-4">
    <Typography variant="body2">No Checklist Available</Typography>
  </div>
);
