import { useMutation } from "@apollo/client";
import { pipe } from "ramda";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Mutation, MutationCreateEstimateArgs } from "../../generated/nest-graphql";
import { CREATE_ESTIMATE } from "../../graphql/mutations/createEstimate";
import { cleanObject } from "../../lib/functions";
import { EstimateDetailsForm, EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { estimateDetailSpec } from "../specs/estimateDetailSpec";

export const AddEstimate: React.FC<{
  initialValues?: EstimateDetailsFormValues;
}> = ({ initialValues }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createEstimate] = useMutation<Mutation, MutationCreateEstimateArgs>(CREATE_ESTIMATE);
  const onSubmit = async (values: EstimateDetailsFormValues, helpers) => {
    const input = pipe(estimateDetailSpec, cleanObject)(values);
    const result = await createEstimate({
      variables: {
        // @ts-ignore
        createEstimateInput: input,
      },
    });
    const id = result.data.createEstimate.id;
    showSuccess({ message: "Successfully Added Contact" });
    await history.push(`/estimates/${id}`);
  };
  return (
    <>
      <div className="mt-16" />
      <h3>Create an Estimate</h3>
      <EstimateDetailsForm
        initialValues={
          initialValues
            ? initialValues
            : {
                status: "Draft",
                issuedDate: new Date(),
                items: [],
                market: "",
                taxable: true,
                estimateNotes: "",
                privateNotes: "",
                serviceLocationNotes: "",
                additionalNotes: "",
                customerExpectation: "",
                frontBrakeSymptoms: [],
                rearBrakeSymptoms: [],
              }
        }
        onSubmit={onSubmit}
      />
    </>
  );
};
