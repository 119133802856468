import React from "react";
import "./styles/styles.generated.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Routes } from "./routes";
import { CustomApolloProvider } from "./components/CustomApolloProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme } from "./material-ui-styles";
import AuthWrapper from "./components/AuthWrapper";
import { ErrorBoundary } from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import { JobsFilterProvider } from "./contexts/jobs-filter-context";
import { InvoicesFilterProvider } from "./contexts/invoices-filter-context";
import { Helmet } from "react-helmet";
import { GlobalSnackBarV2 } from "./components/GlobalSnackBar";

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

function App() {
  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      redirectUri={window.location.origin}
      scope={"openid profile email"}
      leeway={7776000} // 90 days
      audience={"https://auth0.nubrakes.com"}
      useRefreshTokens={true}
    >
      <Helmet>
        <title>NuBrakes</title>
      </Helmet>
      <AuthWrapper>
        <CustomApolloProvider>
          <ThemeProvider theme={darkTheme}>
            <JobsFilterProvider>
              <InvoicesFilterProvider>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </InvoicesFilterProvider>
            </JobsFilterProvider>
            <GlobalSnackBarV2 />
          </ThemeProvider>
        </CustomApolloProvider>
      </AuthWrapper>
    </Auth0Provider>
  );
}

export default App;
