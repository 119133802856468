import gql from "graphql-tag";
import { itemsFields } from "./getEstimate";
import { partsStoreDetailsFields } from "../queries/getPartsStore";

export const activityFeedFields = `activityFeed {
            actor
            id
            objectId
            verb
            createdAt
        }`;

export const jobDetailsFields = `
        ${activityFeedFields}
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
            symptoms
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            extraInfo
        }
        invoice {
            id
            balanceDue
        }
        appointment {
            id
            allDay
            endDate
            startDate
            timeZone
            timeWindow {
                startTimeWindow
                endTimeWindow
            }
            subject
            technician {
                id
                firstName
                lastName
                homePartsStore {
                    id
                    storeNumber
                    name
                    vendor
                }
            }
            ${activityFeedFields}
        }
        contact {
            id
            firstName
            lastName
            email
            timeZone
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        description
        id
        jobNumber
        ${itemsFields}
        jobName
        type
        contact {
            firstName
            lastName
        }
        serviceLocation
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsOrderedTimestamp
            partsNotes
            partsOrderingIssue
        }
        estimate {
            id
        }
        partsStore {
            id
            name
            storeNumber
        }
        withdrawReason 
        withdrawExtraInfo
        withdrawnAt
        mHelpId
        jobCausedWarrantyCheck {
            id
        }
        warrantyCheckReason
        warrantyCheckReasonExtraInfo
        market
        taxable
        privateNotes
        serviceLocationNotes
        preJobCheckList {
            frontPads {
                partCondition
                additionalInfo
            }
            frontRotors {
                partCondition
                additionalInfo
            }
            rearPads {
                partCondition
                additionalInfo
            }
            rearRotors {
                partCondition
                additionalInfo
            }
            brakeFluid {
                partCondition
                additionalInfo
            }
            brakeShoes {
                partCondition
                additionalInfo
            }
            brakeDrums {
                partCondition
                additionalInfo
            }
            calipers {
                partCondition
                additionalInfo
            }
            shocksStruts {
                partCondition
                additionalInfo
            }
            controlArms {
                partCondition
                additionalInfo
            }
            wheelHubs {
                partCondition
                additionalInfo
            }
            sparkPlugs {
                partCondition
                additionalInfo
            }
            otherBrakeParts {
                partCondition
                additionalInfo
            }
            dashboardLights {
                partCondition
                additionalInfo
            }
            odometerReading {
                partCondition
                additionalInfo
            }
        }
        createdAt
        updatedAt
        status
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
            }
            updatedAt
            createdAt
        }
        priceInfo {
            discounts {
                type
                amount
                reason
                total
            }
        }
        `;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;
