import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationUpdateInvoiceArgs,
  Query,
  QueryGetDefaultServicesArgs,
  QueryGetJobArgs,
} from "../../generated/nest-graphql";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { InvoiceDetailsForm, InvoiceDetailsFormValues } from "./InvoiceDetailsForm";
import { cond, keys, path, pathOr, pipe, prop, T } from "ramda";
import { cleanObject, objectDiff } from "../../lib/functions";
import { invoiceDetailsSpec } from "../specs/invoiceDetailsSpec";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Chip } from "@material-ui/core";
import { ActivityFeedDrawer } from "../ActivityFeed/ActivityFeedDrawer";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const InvoiceDetails: React.FC<{ invoiceId: string }> = ({ invoiceId }) => {
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["getInvoice", "vehicleInfo", "year"], data),
        model: pathOr("", ["getInvoice", "vehicleInfo", "model"], data),
        make: pathOr("", ["getInvoice", "vehicleInfo", "make"], data),
      },
    },
  });
  const showSuccess = useShowSuccess();
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  if (!data || !defaultServices) return null;
  const results = data.getInvoice;
  const invoiceNumber = prop("invoiceNumber", results);
  const initialValues: InvoiceDetailsFormValues = {
    status: results.status,
    dueDate: new Date(results.dueDate),
    issuedDate: new Date(results.issuedDate),
    items: results.items.map((item) => {
      return {
        name: prop("name", item),
        description: prop("description", item),
        amount: prop("amount", item),
        laborCost: prop("laborCost", item),
        partNumber: prop("partNumber", item),
        partsCost: prop("partsCost", item),
        vendorPartsCost: prop("vendorPartsCost", item),
        isInEstimate: prop("isInEstimate", item),
        product: prop("product", item),
        partsStore: prop("partsStore", item),
      };
    }),
    market: results.market,
    contact: results.contact,
    serviceLocation: results.serviceLocation,
    customerMessage: results.customerMessage,
    privateNotes: results.privateNotes,
    technician: results.technician,
    taxable: results?.taxable,
    year: pathOr("", ["vehicleInfo", "year"], results),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], results),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], results),
    vin: pathOr("", ["vehicleInfo", "vin"], results),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], results),
    odometer: pathOr("", ["vehicleInfo", "odometer"], results),
    model: pathOr("", ["vehicleInfo", "model"], results),
    make: pathOr("", ["vehicleInfo", "make"], results),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], results),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], results),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], results),
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], results),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], results),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], results),
    serviceCatalogueUsed: pathOr(DEFAULT_SERVICE_CATALOGUE_USED, ["serviceCatalogueUsed"], results),
    services: cond([
      [
        (_val) => results?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && results?.services?.length > 0,
        () => results?.services,
      ],
      [
        () => results?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES,
        () =>
          defaultServices.getDefaultServices.map((service) => {
            return {
              ...service,
              items: service.items.filter(({ behavior }) => behavior === "Default"),
            };
          }),
      ],
      [T, () => []],
    ])(null),
    discounts: results?.priceInfo?.discounts ?? [],
  };
  const onSubmit = async (values: InvoiceDetailsFormValues, formikHelkpers) => {
    const diff = objectDiff(values, initialValues);
    // @ts-ignore
    const updates = pipe(invoiceDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateInvoice({
        variables: {
          id: invoiceId,
          updateInvoiceInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Invoice" });
    }
  };

  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        {path(["getInvoice", "mHelpId"], data) && (
          <div className="my-2 flex flex-row justify-end">
            <Chip label={"Imported from MHelp"} color={"primary"} />
          </div>
        )}
        <InvoiceDetailsForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          invoiceId={invoiceId}
          amountPaid={results.amountPaid}
          balanceDue={results.balanceDue}
          invoiceNumber={invoiceNumber}
        />
      </div>
      <ActivityFeedDrawer activities={data.getInvoice.activityFeed} />
    </div>
  );
};
