import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export const IndeterminateCheckbox = React.forwardRef<
  { indeterminate: any },
  any
>((props, ref) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = React.useRef({ indeterminate: null });
  const resolvedRef = ref ? ref : defaultRef || defaultRef;

  React.useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});
