import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { prop } from "ramda";
import { useQuery } from "@apollo/client";
import { Product, Query } from "../../generated/nest-graphql";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { Button } from "../Buttons/Button";
import { CancelButton } from "../Buttons/CancelButton";
import { ProductSelectTypeAhead } from "../FormFields/ProductSelectTypeHead";
import { ProductDetailsSection } from "../ProductSets/ProductDetailsSection";

export const AddProductForm: React.FC<{ onSubmit: any; onCancel: any }> = ({
  onSubmit,
  onCancel,
}) => {
  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const postOnChange = (value: { label: string; value: Product }) => {
    const selectedValue = prop("value", value);
    setSelectedProduct(selectedValue);
  };
  return (
    <div className="grid grid-cols-1 gap-4">
      {!loading && data.getProducts && (
        <ProductSelectTypeAhead
          products={data.getProducts}
          required={true}
          name={"product"}
          label={"Product"}
          postOnChange={postOnChange}
        />
      )}
      <ProductDetailsSection product={selectedProduct} />
      <Box display="flex" marginTop={2}>
        <Box justifyContent="flex-start" flexGrow={1}>
          <CancelButton onClick={onCancel} type={"button"}>
            {" "}
            Cancel{" "}
          </CancelButton>
        </Box>
        <Box justifyContent="flex-end">
          <Button onClick={() => onSubmit(selectedProduct)} type={"button"}>
            {" "}
            Add{" "}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
