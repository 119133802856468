import React from "react";
import { Appointment } from "../../generated/nest-graphql";
import { makeStyles } from "@material-ui/core/styles";
import { formatToTime } from "../../lib/functions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { path } from "ramda";

export const useStyles = makeStyles({
  root: {
    padding: 2,
    width: 190,
    background: "#66A61E",
  },
});

export const AppointmentEvent: React.FC<{ appointment: Appointment }> = ({ appointment }) => {
  const { startDate, endDate } = appointment;
  const jobId = path(["job", "id"], appointment);
  const serviceLocation = path(["job", "serviceLocation"], appointment);
  return (
    <>
      {jobId ? (
        <Link to={`/jobs/${jobId}`}>
          <AppointmentEventContent startDate={startDate} endDate={endDate} serviceLocation={serviceLocation} />
        </Link>
      ) : (
        <AppointmentEventContent startDate={startDate} endDate={endDate} serviceLocation={serviceLocation} />
      )}
    </>
  );
};

const AppointmentEventContent: React.FC<{
  startDate: string;
  endDate: string;
  serviceLocation?: any;
}> = ({ startDate, endDate, serviceLocation }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} variant="outlined">
      <Typography variant="caption" display="block">
        {serviceLocation}
      </Typography>
      <Typography variant="caption" display="block">
        {formatToTime(startDate)} - {formatToTime(endDate)}
      </Typography>
    </Paper>
  );
};
