import * as React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "ramda";

const useStyles = makeStyles({
  root: { fontWeight: "bold" },
});

export const ReadOnlyItem: React.FC<{
  label: string;
  value: string;
  removeOnValueEmpty?: boolean;
}> = ({ label, value, removeOnValueEmpty = false }) => {
  const classes = useStyles();
  if (removeOnValueEmpty && isEmpty(value)) {
    return null;
  }

  return (
    <Box>
      <Typography className={classes.root} variant={"body1"}>
        {label}
      </Typography>
      <Typography variant={"body1"}>{value}</Typography>
    </Box>
  );
};
