import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateTechnicianArgs } from "../../generated/nest-graphql";
import { CREATE_TECHNICIAN } from "../../graphql/mutations/createTechnician";
import React from "react";
import { Container } from "../../components/Container";
import { TechnicianDetailsForm, TechnicianDetailsFormValues } from "../../components/Forms/TechnicianDetailsForm";
import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { technicianDetailsSpec } from "../../components/spec/technicianDetailsSpec";
import { defaultTechnicianDaySchedule } from "../../data/defaultTechnicianDaySchedule";

const AddTechniciansPage = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createTechnician] = useMutation<Mutation, MutationCreateTechnicianArgs>(CREATE_TECHNICIAN);
  const onSubmit = async (values: TechnicianDetailsFormValues, formikHelpers: FormikHelpers<any>) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      formikHelpers.setFieldError("password", "Passwords do not match");
      return;
    }

    const result = await createTechnician({
      variables: {
        // @ts-ignore
        createTechnicianInput: technicianDetailsSpec(values),
      },
    });
    const id = result.data.createTechnician.id;
    showSuccess({ message: "Successfully Added Technician" });
    await history.push(`/technicians/${id}`);
  };

  return (
    <>
      <Container>
        <div className="mt-16" />
        <h3>Create a Technician</h3>
        <TechnicianDetailsForm
          setPassword={true}
          initialValues={{
            email: "",
            firstName: "",
            isActive: true,
            lastName: "",
            payMultiplier: 1.0,
            role: null,
            market: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            timeZone: "",
            homePartsStore: null,
            schedulingDays: defaultTechnicianDaySchedule,
            onWayMessage: null,
            serviceAreaAnchorAddress: null,
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </>
  );
};
export default AddTechniciansPage;
