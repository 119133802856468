import React from "react";
import Box from "@material-ui/core/Box";
import { detailsViewTitleStyles } from "../../material-ui-styles";

export const DetailViewContainer: React.FC<{ title: string }> = ({
  children,
  title,
}) => {
  const classes = detailsViewTitleStyles();
  return (
    <Box border={1} borderRadius={4} marginTop={2}>
      <Box className={classes.root} borderBottom={1} paddingX={2} paddingY={1}>
        {title}
      </Box>
      <Box paddingX={2} paddingY={2}>
        {children}
      </Box>
    </Box>
  );
};
