import { Activity } from "../../generated/nest-graphql";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { formatDateTime } from "../../lib/functions";

const drawerWidth = 380;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6) + 1,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    toolbar: theme.mixins.toolbar,
  })
);

export const ActivityFeedDrawer = ({ activities }: { activities: Activity[] }) => {
  const classes = useStyles();
  const [open, , toggleDrawer] = useToggle(false);
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor={"right"}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <div>
        <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="end">
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      {open && (
        <>
          <Typography className={"px-4"} variant={"h6"}>
            Activity
          </Typography>
          <div className="mt-4">
            {activities &&
              activities.map((activity, index) => {
                return <ActivityItem key={index} {...activity} />;
              })}
          </div>
        </>
      )}{" "}
    </Drawer>
  );
};

export const ActivityItem: React.FC<Activity> = ({ actor, verb, createdAt }) => {
  return (
    <div className="bg-black text-white rounded flex flex-col p-4 my-4">
      <div className="text-bold">{actor}</div>
      <div className={"overflow-x-hidden overflow-y-scroll"}>{`${JSON.stringify(verb)}`}</div>
      <div>{formatDateTime(new Date(createdAt))}</div>
    </div>
  );
};
