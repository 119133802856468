import React from "react";

export const DeleteButton: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, disabled, ...rest }) => {
  return (
    <button
      className="inline-block justify-center bg-red-500 text-white py-2 text-lg font-semibold px-5 rounded hover:bg-red-900"
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
