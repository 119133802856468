import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetContactArgs,
  QueryGetDefaultServicesArgs,
  QueryGetJobsArgs,
} from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { cond, path, pathOr, T } from "ramda";
import { Button } from "../Buttons/Button";
import { AddJob } from "../Jobs/AddJob";
import { JobColumns, jobsToJobColumns } from "../Jobs/JobsTable";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const ContactsJobs: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [enableAddJob, setEnableAddJob] = useState(false);
  const { data } = useQuery<Query, QueryGetJobsArgs>(GET_JOBS, {
    variables: {
      limit: 50,
      filter: {
        contact: contactId,
      },
    },
  });
  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["lead", "vehicleInfo", "year"], contactResult?.data?.getContact ?? {}),
        make: pathOr("", ["lead", "vehicleInfo", "make"], contactResult?.data?.getContact ?? {}),
        model: pathOr("", ["lead", "vehicleInfo", "model"], contactResult?.data?.getContact ?? {}),
      },
    },
  });
  if (!data || !path(["data", "getContact"], contactResult) || !defaultServices) return null;
  const contactData = contactResult.data.getContact;
  return (
    <div className={"px-4"}>
      {enableAddJob ? (
        <AddJob
          initialValues={{
            taxable: true,
            appointmentId: null,
            status: "New",
            type: "Brake Repair Service",
            jobName: "",
            email: pathOr("", ["email"], contactData),
            market: pathOr(null, ["lead", "market"], contactData),
            rearPadLife: pathOr(null, ["lead", "vehicleInfo", "rearPadLife"], contactData),
            odometer: pathOr(null, ["lead", "vehicleInfo", "odometer"], contactData),
            licensePlate: pathOr(null, ["lead", "vehicleInfo", "licensePlate"], contactData),
            frontPadLife: pathOr(null, ["lead", "vehicleInfo", "frontPadLife"], contactData),
            symptoms: pathOr(null, ["lead", "vehicleInfo", "symptoms"], contactData),
            vin: pathOr(null, ["lead", "vehicleInfo", "vin"], contactData),
            year: pathOr(null, ["lead", "vehicleInfo", "year"], contactData),
            extraInfo: pathOr(null, ["lead", "vehicleInfo", "extraInfo"], contactData),
            make: pathOr(null, ["lead", "vehicleInfo", "make"], contactData),
            model: pathOr(null, ["lead", "vehicleInfo", "model"], contactData),
            serviceLocation: pathOr("", ["lead", "address"], contactData),
            contact: contactData,
            items: [],
            serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
            services: cond([
              [
                () => DEFAULT_SERVICE_CATALOGUE_USED === SERVICE_CATALOGUE_USED_SERVICES,
                () =>
                  defaultServices.getDefaultServices.map((service) => {
                    return {
                      ...service,
                      items: service.items.filter(({ behavior }) => behavior === "Default"),
                    };
                  }),
              ],
              [T, () => []],
            ])(null),
            discounts: [],
            privateNotes: "",
            serviceLocationNotes: "",
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-end">
            <div className="my-4 mr-0">
              <Button onClick={() => setEnableAddJob(true)}>+ Add Job</Button>
            </div>
          </div>
          <SelectablePageableEntityTable
            title={"Jobs"}
            queryResult={data}
            columns={JobColumns}
            queryKey={"getJobs"}
            numRecords={data.getJobs.pageInfo.numRecords}
            limit={50}
            spec={jobsToJobColumns}
          />
        </>
      )}
    </div>
  );
};
