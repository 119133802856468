import React from "react";
import { ContactsTable } from "../../components/Contacts/ContactsTable";

const ContactsPage: React.FC<{}> = (props) => {
  return (
    <div className="px-8 py-4">
      <div>
        <ContactsTable />
      </div>
    </div>
  );
};

export default ContactsPage;
