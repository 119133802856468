import gql from "graphql-tag";
import { paymentDetailsFields } from "./getPayment";

export const GET_PAYMENTS_PAGINATED = gql`
  query getPaymentsPaginated($paginatedQueryInput: PaginatedQueryInput!) {
    getPaymentsPaginated(paginatedQueryInput: $paginatedQueryInput) {
      edges {
        ${paymentDetailsFields}
      }
      pageInfo {
        endCursor
        hasNextPage
        numRecords
      }
    }
  }
`;
