import React from "react";
import { Form, Formik } from "formik";
import { TextField } from "../FormFields/TextField";
import CurrencyInput from "../FormFields/CurrencyInput";
import { SubmitButton } from "../Buttons/SubmitButton";

export type ProductValues = {
  name: string;
  laborCost: string;
  partsCost: string;
  amount: string;
  description: string;
};

export const ProductDetailsForm: React.FC<{
  initialValues: ProductValues;
  onSubmit: any;
}> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<ProductValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <div className="grid grid-cols-2 gap-2">
              <TextField name={"name"} label={"Name"} required={true} />
              <CurrencyInput name={"amount"} label={"Amount"} required={true} />
              <CurrencyInput name={"laborCost"} label={"Technician Pay"} required={true} />
              <CurrencyInput name={"partsCost"} label={"Parts Cost"} required={true} />
              <TextField name={"partNumber"} label={"Part Number"} />
              <TextField name={"description"} label={"Description"} />
            </div>
            <div className="flex flex-row justify-between">
              <div>
                <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                  Submit
                </SubmitButton>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
