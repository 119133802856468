import * as React from "react";
import * as Option from "fp-ts/Option";
import { SelectField } from "./SelectField";
import { always, pluck } from "ramda";
import { useMarkets } from "../../hooks/useMarkets";
import { pipe } from "fp-ts/lib/function";

export const getFieldValueFromMarkets = (markets: any, field: any, market: any) => {
  const marketObj = {};
  if (markets) {
    markets
      .filter((item: any) => item.name === market)
      .forEach((item: any) => {
        marketObj[item.name] = item[field];
      });
  }
  return marketObj;
};

export const MarketFieldSelect: React.FC<{
  name: string;
  required: boolean;
  postOnChange?: any;
}> = ({ name, required, postOnChange }) => {
  const markets: any = useMarkets();
  const marketOptions = pipe(Option.fromNullable(markets), Option.map(pluck("name")), Option.getOrElse(always([])));
  return (
    <SelectField name={name} options={marketOptions} required={required} label={"Market"} postOnChange={postOnChange} />
  );
};
