import React from "react";
import classNames from "classnames";

export const Button: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, className, disabled = false, ...rest }) => {
  const disabledClassName =
    "disabled:bg-primarylight disabled:cursor-not-allowed";
  const baseClassName =
    "inline-block justify-center bg-primary hover:bg-primarydark text-white py-2 text-lg font-semibold px-5 rounded";
  return (
    <button
      disabled={disabled}
      className={classNames(baseClassName, disabledClassName, className)}
      {...rest}
    >
      {children}
    </button>
  );
};
