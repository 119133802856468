import React from "react";
import { Form, Formik } from "formik";
import { SubmitButton } from "../Buttons/SubmitButton";
import { TextField } from "../FormFields/TextField";
import { Product } from "../../generated/nest-graphql";
import { useToggle } from "../../hooks/useToggle";
import { Button } from "../Buttons/Button";
import { append } from "ramda";
import { NuModal } from "../NuModal";
import { ProductsTable } from "../ProductSets/ProductTable";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { AddProductForm } from "./AddProductForm";

export type ProductSetDetailsFormValues = {
  name: string;
  products: Product[];
  description?: string;
};

export const ProductSetDetailsForm: React.FC<{
  onSubmit: any;
  initialValues: ProductSetDetailsFormValues;
}> = ({ onSubmit, initialValues }) => {
  const [addProductModelOpen, , toggleAddProductModelOpen] = useToggle();
  return (
    <Formik<ProductSetDetailsFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        isSubmitting,
        isValid,
        errors,
        setFieldValue,
        values: { products },
      }) => {
        return (
          <Form>
            <DetailViewContainer title={"Details"}>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField name={"name"} label={"Name"} required={true} />
                <TextField
                  name={"description"}
                  label={"Description"}
                  multiline={true}
                  rows={4}
                />
              </div>
            </DetailViewContainer>
            <DetailViewContainer title={"Products"}>
              <div className="flex flex-row justify-start mb-4">
                <Button onClick={toggleAddProductModelOpen} type={"button"}>
                  + Add Product
                </Button>
              </div>
              <div className="mb-4">
                <ProductsTable
                  products={products}
                  setFieldValue={setFieldValue}
                />
              </div>
              {errors && errors.products && (
                <div className="text-red-600 mb-2">{errors.products}</div>
              )}
            </DetailViewContainer>
            <div className="flex flex-row justify-between mt-4">
              <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                Submit
              </SubmitButton>
            </div>
            <NuModal isOpen={addProductModelOpen} title="Add Product">
              <AddProductForm
                onSubmit={(product: Product) => {
                  const newProductList = append(product, products);
                  setFieldValue("products", newProductList);
                  setFieldValue("product", undefined);
                  toggleAddProductModelOpen();
                }}
                onCancel={toggleAddProductModelOpen}
              />
            </NuModal>
          </Form>
        );
      }}
    </Formik>
  );
};
