import React from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetInvoicesPaginatedArgs } from "../../generated/nest-graphql";
import { applySpec, mergeDeepRight, path, pipe, prop } from "ramda";
import { Column } from "react-table";
import { CurrencyCell } from "../Estimates/EstimatesTable";
import { Link } from "react-router-dom";
import { cleanObject, formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { AddToolbarButton } from "../Table/AddToolbarButton";
import { LinearProgress } from "@material-ui/core";

type InvoicesTableType = {
  id: string;
  invoiceNumber: string;
  contact: {
    id: string;
    firstName: string;
    lastName: string;
  };
  issuedDate: string;
  balance: string;
  total: string;
  job: {
    id;
    status;
  };
  status: string;
  balanceDue: string;
  createdBy: string;
};

export const InvoiceColumns: Column<InvoicesTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/invoices/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Invoice Number", accessor: "invoiceNumber" },
  {
    Header: "Contact",
    accessor: "contact",
    Cell: (props) => {
      const { firstName, lastName, id } = props.cell.value;
      return (
        <Link className={"text-primary w-full block text-center"} to={`/contacts/${id}`}>
          {firstName} {lastName}
        </Link>
      );
    },
  },
  { Header: "Issued Date", accessor: "issuedDate" },
  { Header: "Balance", accessor: "balanceDue", Cell: CurrencyCell },
  { Header: "Total", accessor: "total", Cell: CurrencyCell },
  {
    Header: "Job",
    accessor: "job",
    Cell: (props) => {
      const { status, id } = props.cell.value;
      return (
        <Link className={"text-primary w-full block text-center"} to={`/jobs/${id}`}>
          {status}
        </Link>
      );
    },
  },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Status", accessor: "status" },
];

export const InvoicesTable: React.FC<{ filters: any }> = ({ filters }) => {
  const limit = 50;
  const filtersToSet = cleanObject(filters);
  const { data, fetchMore, loading } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: filtersToSet,
        limit,
        skip: 0,
      },
    },
  });
  if (!data) return null;
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          limit,
          filter: filtersToSet,
          skip: data.getInvoicesPaginated.pageInfo.offset + 50,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getInvoicesPaginated: {
            // @ts-ignore
            edges: [...prev.getInvoicesPaginated.edges, ...fetchMoreResult.getInvoicesPaginated.edges],
            // @ts-ignore
            pageInfo: mergeDeepRight(prev.getInvoicesPaginated.pageInfo, fetchMoreResult.getInvoicesPaginated.pageInfo),
            // @ts-ignore
            __typename: prev.getInvoicesPaginated.__typename,
          },
        };
      },
    });
  };

  return (
    <>
      {loading && <LinearProgress />}
      <SelectablePageableEntityTable
        title={"Invoices"}
        queryResult={data}
        columns={InvoiceColumns}
        queryKey={"getInvoicesPaginated"}
        loadMore={() => loadMore(data.getInvoicesPaginated.pageInfo.endCursor)}
        numRecords={data.getInvoicesPaginated.pageInfo.numRecords}
        limit={limit}
        spec={invoicesToInvoiceColumns}
        endAdornments={<AddToolbarButton link="/add/invoices" title="Invoice" />}
      />
    </>
  );
};

export const invoicesToInvoiceColumns = applySpec({
  id: prop("id"),
  invoiceNumber: prop("invoiceNumber"),
  contact: {
    id: path(["contact", "id"]),
    firstName: path(["contact", "firstName"]),
    lastName: path(["contact", "lastName"]),
  },
  createdBy: prop("createdBy"),
  issuedDate: pipe(prop("issuedDate"), formatDateTime),
  balanceDue: prop("balanceDue"),
  total: prop("total"),
  status: prop("status"),
  job: {
    id: path(["job", "id"]),
    status: path(["job", "status"]),
  },
});
