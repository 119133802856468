import React, { useState } from "react";
import { EntityTable } from "../TableViewsPages/EntityTable";
import { Column } from "react-table";
import { ItemFormProductSelect } from "../FormFields/ItemFromProductSelect";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { PartsStore, Product } from "../../generated/nest-graphql";
import DebouncedCurrencyInput from "../FormFields/DebouncedCurrencyInput";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import { Box } from "@material-ui/core";
import { PartsStoreSelectField } from "../FormFields/PartsStoreSelectField";
import { Field } from "formik";
import { prop } from "ramda";

export type ItemsTableType = {
  product?: string;
  partsStore?: PartsStore;
  name: string;
  isInEstimate?: boolean;
  description?: string;
  amount: string;
  partNumber?: string;
  laborCost: string;
  partsCost: string;
  vendorPartsCost?: string;
};
type ItemsTableProps = {
  items: ItemsTableType[];
  postItemSelect: (prefix: string) => (value: ProductOptionType) => void;
  onDeleteItem: (idx: number) => () => void;
  products: Product[];
  partsStores: any;
};
export const ItemsTable: React.FC<ItemsTableProps> = ({
  products,
  items,
  postItemSelect,
  onDeleteItem,
  partsStores,
}) => {
  const [key, setKey] = useState(1);
  const ItemsColumns: Column<ItemsTableType>[] = [
    {
      Header: "Product",
      accessor: "product",
      width: 150,
      Cell: ({ row: { index } }) => {
        const prefix = `items[${index}]`;
        return (
          <ItemFormProductSelect
            products={products}
            required={false}
            name={`${prefix}.product`}
            label={"Product"}
            postSelect={(value: any) => {
              postItemSelect(prefix)(value);
              setKey(key + 1); // Force table re-render to update table values
            }}
          />
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      width: 250,
      Cell: renderNameInput,
    },
    {
      Header: "Description",
      accessor: "description",
      width: 150,
      Cell: renderDescriptionInput,
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 75,
      Cell: renderAmountInput,
    },
    {
      Header: "Technician Pay",
      accessor: "laborCost",
      width: 75,
      Cell: renderLaborCostInput,
    },
    {
      Header: "Customer Parts Price",
      accessor: "partsCost",
      width: 75,
      Cell: renderPartsCostInput,
    },
    {
      Header: "Is in Estimate",
      accessor: "isInEstimate",
      width: 75,
      Cell: (props) => (
        <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {props.cell.value ? "Y" : "N"}
        </Box>
      ),
    },
    {
      Header: "Vendor Parts Cost",
      accessor: "vendorPartsCost",
      width: 75,
      Cell: renderVendorPartsCostInput,
    },
    {
      Header: "Part Number",
      accessor: "partNumber",
      width: 150,
      Cell: renderPartNumberInput,
    },
    {
      Header: "Parts Store",
      accessor: "partsStore",
      width: 150,
      Cell: (props) => (
        <>
          <Field name={`items[${props.row.index}].partsStore`}>
            {({ field, meta, form }: any) => (
              <PartsStoreSelectField
                name={`items[${props.row.index}].partsStore`}
                label={""}
                value={field.value}
                required={false}
                onChange={(_: any, newValue: any) => {
                  form.setFieldValue(`items[${props.row.index}].partsStore`, prop("value", newValue));
                }}
                error={meta.error}
                options={partsStores}
              />
            )}
          </Field>
        </>
      ),
    },
  ];

  // Force table re-render on table item delete
  const onRemove = (index: number) => () => {
    onDeleteItem(index)();
    setKey(key + 1);
  };

  return (
    <>
      <EntityTable<ItemsTableType>
        key={key}
        title={"Items"}
        data={items}
        columns={ItemsColumns}
        pluginHooks={(hooks) => {
          hooks.visibleColumns.push((columns) => [
            ...columns,
            {
              id: "delete",
              Header: <div>Delete</div>,
              width: 75,
              Cell: (props: any) => {
                return (
                  <div className="px-4">
                    <IconButton onClick={onRemove(props.row.index)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              },
            },
          ]);
        }}
        numRecords={items.length}
        editable={true}
      />
    </>
  );
};

// react-table workaround input unfocus issue: https://github.com/tannerlinsley/react-table/issues/1346
const renderNameInput = ({ row: { index } }: any) => (
  <DebouncedTextField name={`items[${index}].name`} label="" required={true} />
);
const renderDescriptionInput = ({ row: { index } }: any) => (
  <DebouncedTextField name={`items[${index}].description`} label="" />
);
const renderAmountInput = ({ row: { index } }: any) => (
  <DebouncedCurrencyInput name={`items[${index}].amount`} label="" required={true} />
);
const renderLaborCostInput = ({ row: { index } }: any) => (
  <DebouncedCurrencyInput name={`items[${index}].laborCost`} label="" required={true} />
);
const renderPartsCostInput = ({ row: { index } }: any) => (
  <DebouncedCurrencyInput name={`items[${index}].partsCost`} label="" required={true} />
);
const renderVendorPartsCostInput = ({ row: { index } }: any) => (
  <DebouncedCurrencyInput name={`items[${index}].vendorPartsCost`} label="" />
);
const renderPartNumberInput = ({ row: { index } }: any) => (
  <DebouncedTextField name={`items[${index}].partNumber`} label="" />
);
