import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { FilterSelectField } from "../FormFields/FilterSelectField";
import { Form, Formik } from "formik";
import { Technician } from "../../generated/nest-graphql";
import { useMarkets } from "../../hooks/useMarkets";
import { TechnicianSelect } from "../FormFields/TechnicianSelect";
import { Button } from "../Buttons/Button";
import { DateInput } from "../FormFields/DateInput";
import { startOfDay } from "date-fns";
import { defaultTo, pluck } from "ramda";

export type InvoicesFilterFormValues = {
  status: string;
  issuedDateStartDate: Date;
  issuedDateEndDate: Date;
  market: string;
  technician: Technician;
};
export const InvoicesTableFilterForm: React.FC<{
  initialValues: InvoicesFilterFormValues;
  onSubmit: any;
}> = ({ initialValues, onSubmit }) => {
  const markets = useMarkets();
  return (
    <Formik<InvoicesFilterFormValues> onSubmit={onSubmit} initialValues={initialValues}>
      {({ resetForm, submitForm, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          submitForm();
        }, [submitForm, values]);

        return (
          <Form>
            <div className={"grid grid-cols-4 gap-4"}>
              <Box>
                <FilterSelectField
                  label={"Status"}
                  name={"status"}
                  options={["Draft", "Sent", "UnPaid", "Paid", "Partially Paid"]}
                />
              </Box>
              <Box>
                <DateInput label={"Issued Date Start"} name={"issuedDateStartDate"} dateModifier={startOfDay} />
              </Box>
              <Box>
                <DateInput label={"Issued Date Finish"} name={"issuedDateEndDate"} dateModifier={startOfDay} />
              </Box>
              <Box>
                {markets && (
                  <FilterSelectField
                    label={"Market"}
                    options={defaultTo([], markets && pluck("name", markets))}
                    name={"market"}
                  />
                )}
              </Box>
              <Box style={{ width: 300 }}>
                <TechnicianSelect market={""} name={"technician"} label={"Technician"} />
              </Box>
              <Button
                onClick={() => {
                  resetForm({
                    touched: {
                      market: false,
                      status: false,
                      issuedDateEndDate: false,
                      issuedDateStartDate: false,
                      technician: null,
                    },
                    values: {
                      status: null,
                      issuedDateStartDate: null,
                      issuedDateEndDate: null,
                      market: null,
                      technician: null,
                    },
                  });
                }}
              >
                Reset
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
