import gql from "graphql-tag";
import { marketFields } from "../queries/getMarket";

export const CREATE_MARKET = gql`
  mutation createMarket($createMarketInput: CreateMarketInput!) {
    createMarket(createMarketInput: $createMarketInput) {
      ${marketFields}
    }
  }
`;
