import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const LocationFieldOption: React.FC<{ parts: any; secondaryText: string }> = ({
  parts,
  secondaryText,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon className={classes.icon} />
      </Grid>
      <Grid item xs>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
        <Typography variant="body2" color="textSecondary">
          {secondaryText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LocationFieldOption;
