import gql from "graphql-tag";

export const appointmentDetailsFields = `
      id
      allDay
      endDate
      startDate
      subject
      timeZone
      timeWindow {
        startTimeWindow
        endTimeWindow
      }
      technician {
        id
        firstName
        lastName
        market
        isActive
      }
      job {
        id
        status
        jobName
        jobNumber
        serviceLocation
        type
        market
      }
      overrideOutsideServiceZone
  `;
export const GET_APPOINTMENTS = gql`
  query getAppointments($startRange: Date!, $endRange: Date!, $filter: JSON) {
    getAppointments(
      startRange: $startRange
      endRange: $endRange
      filter: $filter
    ) {
      ${appointmentDetailsFields}
    }
  }
`;
