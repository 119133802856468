import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useFullURI } from "../../hooks/useFullURI";

enum TabStatusEnum {
  ACTIVE,
  NOTACTIVE,
}

export const TabItem: React.FC<{ href: string }> = ({ children, href }) => {
  const pathname = useFullURI();
  const realStatus =
    href === pathname ? TabStatusEnum.ACTIVE : TabStatusEnum.NOTACTIVE;
  const baseClassNames = "inline-block py-2 px-4 font-semibold bg-black";
  const activeClassNames = "bg-black border-l border-t border-r rounded-t";
  const notActiveClassNames = "text-blue-500";
  const classesToApply = classNames(
    baseClassNames,
    realStatus === TabStatusEnum.ACTIVE ? activeClassNames : "",
    realStatus === TabStatusEnum.NOTACTIVE ? notActiveClassNames : ""
  );
  return (
    <li className="-mb-px mr-1">
      <Link className={classesToApply} to={href}>
        {children}
      </Link>
    </li>
  );
};
