import gql from "graphql-tag";
import { partsStoreDetailsFields } from "../queries/getPartsStore";

export const UPDATE_PARTS_STORE = gql`
  mutation updatePartsStore($id: ID!, $partsStoreInput: PartsStoreInput!) {
    updatePartsStore(id: $id, partsStoreInput: $partsStoreInput) {
      ${partsStoreDetailsFields}
    }
  }
`;
