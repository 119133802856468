import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { Box } from "@material-ui/core";
import { TextField } from "../FormFields/TextField";
import { SubmitButton } from "../Buttons/SubmitButton";

export type UserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

const userSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  password: Yup.string().required("Required"),
});
export const AddOpsManagerForm = ({ initialValues, onSubmit }) => {
  return (
    <Formik<UserFormValues> initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Box className="grid grid-cols-2 gap-2">
              <TextField name={"email"} label={"Email"} required={true} type={"email"} />
              <div></div>
              <TextField name={"firstName"} label={"First Name"} required={true} />
              <TextField name={"lastName"} label={"Last Name"} required={true} />
              <TextField name={"password"} label={"Password"} type={"password"} required={true} />
              <TextField name={"confirmPassword"} label={"Confirm Password"} type={"password"} required={true} />
              <div className="flex flex-row justify-between col-span-2">
                <div>
                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                    Submit
                  </SubmitButton>
                </div>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
