import React from "react";
import { Formik } from "formik";
import { filter, isNil, pipe, pluck, propEq, uniq } from "ramda";
import {
  DriveTimeDistanceFilterSectionInternals,
  TechniciansFilterSectionFormValues,
} from "./DriveTimeDistanceFilterSectionInternals";
import { Technician } from "../../generated/nest-graphql";

export const DriveTimeDistanceFilterSection: React.FC<{
  initialTechnicians: string[];
  initialMarkets: string[];
  includeIsActive: boolean | null;
  setIncludeIsActive: (val: boolean) => void;
  setTechniciansToInclude;
  setMarketsToInclude;
  eventTechnicians: Technician[];
}> = ({
  setTechniciansToInclude,
  setMarketsToInclude,
  initialMarkets,
  initialTechnicians,
  includeIsActive,
  setIncludeIsActive,
  eventTechnicians,
}) => {
  const filteredTechs = filter((technician: Technician) => {
    if (isNil(includeIsActive)) return true;
    // @ts-ignore
    return propEq("isActive", includeIsActive, technician);
  }, eventTechnicians);
  const allTechnicians = pluck("id", filteredTechs);
  // @ts-ignore
  const markets = pipe(pluck("market"), uniq)(filteredTechs);
  return (
    <Formik<TechniciansFilterSectionFormValues>
      enableReinitialize={true}
      initialValues={{
        technicians: initialTechnicians.length ? initialTechnicians : allTechnicians,
        markets: initialMarkets.length ? initialMarkets : markets,
      }}
      onSubmit={console.log}
    >
      {({ values, setFieldValue }) => {
        return (
          <DriveTimeDistanceFilterSectionInternals
            setTechniciansToInclude={setTechniciansToInclude}
            setMarketsToInclude={setMarketsToInclude}
            includeIsActive={includeIsActive}
            setIncludeIsActive={setIncludeIsActive}
            setFieldValue={setFieldValue}
            values={values}
            technicians={filteredTechs}
            markets={markets}
            allTechnicians={allTechnicians}
          />
        );
      }}
    </Formik>
  );
};
