import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "../util";
type SnackbarState = {
  showSnackBar: boolean;
  snackBarMessage?: string;
};
export const snackbarInitialState: SnackbarState = {
  showSnackBar: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: snackbarInitialState,
  reducers: {
    show: (state, action: PayloadAction<{ message: string }>) => {
      state.showSnackBar = true;
      state.snackBarMessage = action.payload.message;
    },
    close: (state) => {
      state.showSnackBar = false;
      state.snackBarMessage = null;
    },
  },
});

export const { show, close: snackbarClose } = snackbarSlice.actions;
export const useShowSuccess = () => {
  const dispatch = useAppDispatch();
  return ({ message }: { message: string }) => dispatch(show({ message }));
};
