import * as React from "react";
import { ProductSetsTable } from "../../components/ProductSets/ProductSetsTable";

const ProductSetsPage: React.FC<{}> = () => {
  return (
    <div className="px-8 py-4 overflow-hidden">
      <div className="overflow-auto">
        <ProductSetsTable />
      </div>
    </div>
  );
};

export default ProductSetsPage;
