import { useMutation, useQuery } from "@apollo/client";
import { cond, keys, path, pathOr, pipe, prop, T } from "ramda";
import React from "react";
import { useShowSuccess } from "../../redux/slices/snackbar";
import {
  Mutation,
  MutationUpdateEstimateArgs,
  Query,
  QueryGetDefaultServicesArgs,
  QueryGetEstimateArgs,
} from "../../generated/nest-graphql";
import { UPDATE_ESTIMATE } from "../../graphql/mutations/updateEstimate";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { cleanObject, objectDiff } from "../../lib/functions";
import { ActivityFeedDrawer } from "../ActivityFeed/ActivityFeedDrawer";
import { EstimateDetailsForm, EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { estimateDetailSpec } from "../specs/estimateDetailSpec";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const EstimateDetails: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const { data } = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: data?.getEstimate?.vehicleInfo?.year ?? "",
        make: data?.getEstimate?.vehicleInfo?.make ?? "",
        model: data?.getEstimate?.vehicleInfo?.model ?? "",
      },
    },
  });
  const showSuccess = useShowSuccess();
  const [updateEstimate] = useMutation<Mutation, MutationUpdateEstimateArgs>(UPDATE_ESTIMATE);
  if (!data || !defaultServices) return null;
  const estimateNumber = path<string>(["getEstimate", "estimateNumber"], data);
  const initialValues: EstimateDetailsFormValues = {
    year: pathOr("", ["vehicleInfo", "year"], data.getEstimate),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], data.getEstimate),
    model: pathOr("", ["vehicleInfo", "model"], data.getEstimate),
    make: pathOr("", ["vehicleInfo", "make"], data.getEstimate),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], data.getEstimate),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], data.getEstimate),
    odometer: pathOr("", ["vehicleInfo", "odometer"], data.getEstimate),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], data.getEstimate),
    vin: pathOr("", ["vehicleInfo", "vin"], data.getEstimate),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], data.getEstimate),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], data.getEstimate),
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], data.getEstimate),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], data.getEstimate),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "rearBrakeSymptoms"], data.getEstimate),
    issuedDate: data.getEstimate.issuedDate,
    taxable: data?.getEstimate?.taxable,
    serviceLocation: data.getEstimate.serviceLocation,
    estimateNotes: data.getEstimate.estimateNotes,
    privateNotes: data.getEstimate.privateNotes,
    market: data.getEstimate.market,
    serviceLocationNotes: data.getEstimate.serviceLocationNotes,
    contact: data.getEstimate.contact,
    items: data.getEstimate.items.map((item) => {
      return {
        name: prop("name", item),
        description: prop("description", item),
        amount: prop("amount", item),
        laborCost: prop("laborCost", item),
        partNumber: prop("partNumber", item),
        partsCost: prop("partsCost", item),
        vendorPartsCost: prop("vendorPartsCost", item),
        isInEstimate: prop("isInEstimate", item),
        product: prop("product", item),
        partsStore: prop("partsStore", item),
      };
    }),
    status: prop("status", data.getEstimate),
    serviceCatalogueUsed: data.getEstimate?.serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
    services: cond([
      [
        (_val) =>
          data.getEstimate?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES &&
          data.getEstimate?.services?.length > 0,
        () => data.getEstimate?.services,
      ],
      [
        () => data.getEstimate?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES,
        () =>
          defaultServices.getDefaultServices.map((service) => {
            return {
              ...service,
              items: service.items.filter(({ behavior }) => behavior === "Default"),
            };
          }),
      ],
      [T, () => []],
    ])(null),
    discounts: data?.getEstimate?.priceInfo?.discounts ?? [],
  };
  const onSubmit = async (values: EstimateDetailsFormValues, formikHelkpers) => {
    const diff = objectDiff(values, initialValues);
    // @ts-ignore
    const updates = pipe(estimateDetailSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateEstimate({
        variables: {
          id: estimateId,
          updateEstimateInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Estimate" });
    }
  };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <EstimateDetailsForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          estimateId={estimateId}
          estimateNumber={estimateNumber}
        />
      </div>
      <ActivityFeedDrawer activities={data.getEstimate.activityFeed} />
    </div>
  );
};
