import { useMemo } from "react";
import * as moment from "moment-timezone";

export const useTimezones = () => {
  const timeZones = moment.tz.names();
  return useMemo(() => {
    return timeZones.map((tz) => {
      return {
        label: `${tz} (GMT${moment.tz(tz).format("Z")})`,
        value: tz,
      };
    });
  }, [timeZones]);
};
