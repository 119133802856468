import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

export const ButtonThin: React.FC<
  { disabled?: boolean; isSubmitting?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, className, disabled = false, isSubmitting, ...rest }) => {
  const disabledClassName =
    "disabled:bg-primarylight disabled:cursor-not-allowed";
  const baseClassName =
    "inline-block justify-center bg-primary hover:bg-primarydark text-white font-semibold py-2 px-4 rounded";
  return (
    <button
      disabled={disabled}
      className={classNames(baseClassName, disabledClassName, className)}
      {...rest}
    >
      {isSubmitting ? (
        <FontAwesomeIcon icon={faSpinner} spin={true} />
      ) : (
        children
      )}
    </button>
  );
};
