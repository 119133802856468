import React, { Fragment, useState } from "react";
import { PossibleEjiItem, Query } from "../../generated/nest-graphql";
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Box } from "@material-ui/core";
import { Button } from "../Buttons/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import ProductRow, { cleanedServices } from "./ProductRow";
import { useQuery } from "@apollo/client";
import { GET_SERVICE_CATALOGUE } from "../../graphql/queries/getServiceCatalogue";
import { cleanedDiscounts } from "./ServicesReceiptSection";
import { pipe } from "fp-ts/lib/function";

type ProductsExpansionProps = {
  serviceIdx: number;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
};

const ProductsExpansionSection = ({
  serviceIdx,
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
}: ProductsExpansionProps) => {
  return (
    <Box margin={1} padding={2}>
      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell>
              Product
              <br />
              Type
            </TableCell>
            <TableCell>Selection</TableCell>
            <TableCell style={{ width: "7%" }}>Units</TableCell>
            <TableCell style={{ width: "10%" }}>Customer Price</TableCell>
            <TableCell className={"w-1/12"}>Vendor Unit Cost</TableCell>
            <TableCell style={{ width: "15%" }}>Part #</TableCell>
            <TableCell className={"w-1/6"}>Parts Store</TableCell>
            <TableCell>Private Notes</TableCell>
            <TableCell style={{ width: "3%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Field name={`services[${serviceIdx}].items`}>
            {({ field: { value: products = [] } }) => (
              <Fragment>
                {products.map((product, prodIdx) => (
                  <ProductRow
                    product={product}
                    prodIdx={prodIdx}
                    serviceIdx={serviceIdx}
                    partsStores={partsStores}
                    ejiType={ejiType}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                  />
                ))}
              </Fragment>
            )}
          </Field>
        </TableBody>
      </Table>
      <Field name={`services[${serviceIdx}].name`}>
        {({ field: { value }, form: { values } }) => (
          <AddProduct
            serviceIdx={serviceIdx}
            serviceName={value}
            ejiType={ejiType}
            calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
            homePartsStore={values?.technician?.homePartsStore}
          />
        )}
      </Field>
    </Box>
  );
};

type ProductOption = {
  label: string;
  value: PossibleEjiItem;
};

const AddProduct = ({ serviceIdx, serviceName, ejiType, calculatePossibleEJIPriceInfo, homePartsStore }) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductOption | null>(null);
  const { data } = useQuery<Query>(GET_SERVICE_CATALOGUE);
  if (!data) return null;
  const productList: PossibleEjiItem[] = data.getServiceCatalogue
    .find(({ name }) => name === serviceName)
    ?.products.map((product) => {
      return {
        serviceProductId: product.id,
        type: product.type,
        units: product.defaultUnits,
        behavior: product.behavior,
        category: product.category,
        customerPrice: product.defaultCustomerPrice,
        overriddenCustomerPrice: false,
        productSelection: { name: "" },
        vendorUnitCost: null,
        orderItem: {
          partsStore: homePartsStore ?? null,
        },
      };
    });
  const productsToOptions = (productList = []) => {
    return productList.map((product) => {
      return {
        label: product.type,
        value: product,
      };
    });
  };

  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedProduct}
        onChange={(_, selectedOption: ProductOption) => {
          setSelectedProduct(selectedOption);
        }}
        options={productsToOptions(productList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={`services[${serviceIdx}].items`}>
          {({ form: { values } }) => (
            <Button
              onClick={() => {
                if (!!selectedProduct) {
                  let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                  servicesCopy[serviceIdx].items.push(selectedProduct.value);
                  calculatePossibleEJIPriceInfo({
                    variables: {
                      calculatePossibleEJIPriceInfoInput: {
                        services: cleanedServices(servicesCopy),
                        discounts: cleanedDiscounts(values.discounts) ?? [],
                        marketName: values.market,
                        taxable: values.taxable,
                        calculateAllServices: ejiType === "INVOICE",
                      },
                    },
                  });
                  setSelectedProduct(null);
                }
              }}
              type={"button"}
            >
              + Add Product
            </Button>
          )}
        </Field>
      </Box>
    </div>
  );
};

export default ProductsExpansionSection;
