import * as React from "react";
import { useField } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { applySpec, equals, identity, map, propOr } from "ramda";
import { ErrorTextField } from "./ErrorTextField";

interface Props {
  name: string;
  label: string;
  loading?: boolean;
  required: boolean;
  options: string[];
  postOnChange?: (val: any) => void;
}

export type OptionType = {
  label: string;
  value: string;
};
const toOptions = (arr: string[]) =>
  map<string, OptionType>(
    applySpec<OptionType>({
      label: identity,
      value: identity,
    }),
    arr
  );

export const AutocompleteVehicleSelect = ({ name, required, label, options, loading, postOnChange }: Props) => {
  const [field, meta, helper] = useField(name);
  const { onChange, ...rest } = field;
  const mappedOptions = toOptions(options);
  return (
    <>
      <Autocomplete<OptionType, boolean, false, true>
        {...rest}
        autoSelect
        getOptionLabel={propOr(null, "label")}
        onChange={(event, newValue: OptionType) => {
          helper.setValue(newValue);
          postOnChange(newValue);
        }}
        getOptionSelected={equals}
        renderInput={(params) => <TextField {...params} required={required} label={label} variant="outlined" />}
        loading={loading}
        options={mappedOptions}
      />
      <ErrorTextField fieldName={name} displayError={!!meta.error} />
    </>
  );
};
