import { equals } from "ramda";
import * as Yup from "yup";
export const servicesSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().required(),
    technicianPay: Yup.string().required(),
    items: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(),
        productSelection: Yup.object().when("category", {
          is: equals("Part"),
          then: Yup.object()
            .shape({
              name: Yup.string().required(),
            })
            .required(),
          otherwise: Yup.object()
            .shape({
              name: Yup.string().notRequired(),
            })
            .nullable()
            .notRequired(),
        }),
        units: Yup.number().when("category", {
          is: (val) => val === "Part" || val === "Labor",
          then: Yup.number().required(),
        }),
        customerPrice: Yup.string().required(),
        vendorUnitCost: Yup.string()
          .nullable()
          .when("category", { is: (val) => val === "Part", then: Yup.string().required() }),
      })
    ),
  })
);
