import React from "react";
import { TabItem } from "../TabView/TabItem";
import { EstimateDetailsContainer } from "./EstimateDetailsContainer";

export const EstimatesTabView: React.FC<{ estimateId: string }> = ({
  estimateId,
}) => {
  return (
    <div className={"mt-8"}>
      <ul className={"flex border-b"}>
        <TabItem href={`/estimates/${estimateId}`}>Estimate</TabItem>
        <TabItem href={`/estimates/${estimateId}?tab=job`}>Job</TabItem>
        <TabItem href={`/estimates/${estimateId}?tab=invoice`}>Invoice</TabItem>
        <TabItem href={`/estimates/${estimateId}?tab=payment`}>Payment</TabItem>
      </ul>
      <EstimateDetailsContainer estimateId={estimateId} />
    </div>
  );
};
