import { Column } from "react-table";
import { PartsStore, Query } from "../../generated/nest-graphql";
import { Link } from "react-router-dom";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { path, prop } from "ramda";
import { AddToolbarButton } from "../Table/AddToolbarButton";

const PartsStoreColumns: Column<PartsStore>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/parts-stores/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  {
    Header: "Market",
    accessor: "market",
    Cell: (props) => {
      return <>{path(["cell", "value", "name"], props)}</>;
    },
  },
  { Header: "Store No.", accessor: "storeNumber" },
  { Header: "Store Name", accessor: "name" },
  { Header: "Address", accessor: "address" },
];

export const PartsStoresTable = () => {
  const { data } = useQuery<Query>(GET_PARTS_STORES);
  if (!data) return null;
  return (
    <SelectableEntityTable
      data={prop("getPartsStores", data)}
      columns={PartsStoreColumns}
      numRecords={data.getPartsStores.length}
      title={"Parts Stores"}
      endAdornments={<AddToolbarButton link={"/add/parts-store"} title={"Parts Store"} />}
    />
  );
};
