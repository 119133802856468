import React from "react";
import { path, prop } from "ramda";
import { useQuery } from "@apollo/client";
import { Query, Appointment } from "../../generated/nest-graphql";
import { GET_DRIVE_TIME } from "../../graphql/queries/getDriveTime";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getErrorMessage } from "./functions";

export const useStyles = makeStyles({
  root: {
    padding: 2,
    width: 190,
    background: "#5779BB",
  },
});

export const TravelDistanceEvent: React.FC<{
  originAppointment: Appointment;
  destinationAppointment: Appointment;
}> = ({ originAppointment, destinationAppointment }) => {
  const classes = useStyles();
  const origin = path(["job", "serviceLocation"], originAppointment);
  const destination = path(["job", "serviceLocation"], destinationAppointment);
  const { data } = useQuery<Query>(GET_DRIVE_TIME, {
    variables: {
      addresses: [origin, destination],
    },
  });
  const driveTimeDistance = path(["getDriveTime", 0], data);
  const distance = path(["distance", "text"], driveTimeDistance);
  const duration = path(["duration", "text"], driveTimeDistance);
  // @ts-ignore
  const status: string = prop("status", driveTimeDistance);
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`}
    >
      <Paper className={classes.root} variant="outlined" style={status !== "OK" ? { background: "#FF0000" } : {}}>
        <Typography variant="caption" display="block">
          from: {origin}
        </Typography>
        <Typography variant="caption" display="block">
          to: {destination}
        </Typography>
        <Typography variant="caption" display="block">
          {status !== "OK" ? "Error: " + getErrorMessage(status) : `Travel: ${distance} / ${duration}`}
        </Typography>
      </Paper>
    </a>
  );
};
