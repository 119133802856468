import React from "react";
import { NuModal } from "../NuModal";
import { Button } from "@material-ui/core";
import { CancelButton } from "../Buttons/CancelButton";
import { useFormikContext } from "formik";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateEstimateArgs } from "../../generated/nest-graphql";
import { CREATE_ESTIMATE } from "../../graphql/mutations/createEstimate";
import { pipe } from "ramda";
import { useToggle } from "../../hooks/useToggle";
import { JobAddEstimateModal } from "./JobAddEstimateModal";
import { cleanObject } from "../../lib/functions";
import { estimateDetailSpec } from "../specs/estimateDetailSpec";

export const JobNoEstimateModal: React.FC<{
  open: boolean;
  toggle: () => void;
  onSubmit: (values, helpers) => void;
}> = ({ open, toggle, onSubmit }) => {
  const { values } = useFormikContext<JobDetailsFormValues>();

  const [addEstimateModalOpen, , toggleAddEstimateModalOpen] = useToggle(false);
  const [createEstimate] = useMutation<Mutation, MutationCreateEstimateArgs>(CREATE_ESTIMATE);
  const onCreateEstimate = async (values: JobDetailsFormValues) => {
    // @ts-ignore
    const input = pipe(estimateDetailSpec, cleanObject)(values);
    const result = await createEstimate({
      variables: {
        // @ts-ignore
        createEstimateInput: { ...input, status: "Draft" },
      },
    });
    const id = result.data.createEstimate.id;
    await onSubmit({ ...values, estimate: id }, null);
  };

  return (
    <>
      <NuModal isOpen={open} title="Job Estimate">
        Job does not have an estimate, Choose an option:
        <div className="flex flex-row m-4">
          <Button fullWidth variant="contained" onClick={() => onCreateEstimate(values)} color="primary">
            Duplicate Job to Estimate
          </Button>
        </div>
        <div className="flex flex-row m-4">
          <Button
            fullWidth
            variant="contained"
            type="submit"
            onClick={async () => {
              await onSubmit(values, null);
              toggle();
            }}
            color="primary"
          >
            Create Job without Estimate
          </Button>
        </div>
        <div className="flex flex-row m-4">
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              toggle();
              toggleAddEstimateModalOpen();
            }}
            color="primary"
          >
            Create Estimate for Contact
          </Button>
        </div>
        <div className="flex flex-row m-4">
          <div>
            <CancelButton onClick={toggle}>Cancel</CancelButton>
          </div>
        </div>
      </NuModal>
      <JobAddEstimateModal open={addEstimateModalOpen} toggle={toggleAddEstimateModalOpen} values={values} />
    </>
  );
};
