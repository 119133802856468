import gql from "graphql-tag";

export const contactDetailsFields = `
        id
      outreachMedium
        firstName
        lastName
        email
        phoneNumber
        createdAt
        updatedAt
        mHelpId
        oldMongoId
        timeZone
        lead {
            id
            address
            hearAboutUs
            market
            source
            vehicleInfo {
              frontPadLife
                licensePlate
                make
                model
                odometer
                rearPadLife
                vin
                year
                extraInfo
                symptoms
                customerExpectation
                additionalNotes
                frontBrakeSymptoms
                rearBrakeSymptoms
            }
            zip
        }
        activityFeed {
            id
            actor
            objectId
            objectType
            createdAt
            verb
        }
        stripeCustomer
        stripePaymentMethods
        `;

export const GET_CONTACT_DETAILS = gql`query getContactDetails($id: ID!) {
    getContact(id: $id) {
        ${contactDetailsFields}
    }
}`;
