import * as React from "react";
import { PaymentsTable } from "../../components/Payments/PaymentsTable";

const PaymentsPage = () => {
  return (
    <>
      <div className="px-8 py-4 overflow-hidden">
        <div className="flex flex-row justify-between">
          <div />
        </div>
        <div className="overflow-auto">
          <PaymentsTable />
        </div>
      </div>
    </>
  );
};

export default PaymentsPage;
