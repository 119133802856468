import gql from "graphql-tag";

export const estimateListFields = `id
            estimateNumber
            contact {
                id
                firstName
                lastName
            }
            issuedDate
            market
            price
            privateNotes
            serviceLocation
            serviceLocationNotes
            status
            createdBy
            createdAt
            updatedAt`;

export const GET_ESTIMATES_PAGINATED = gql`query getEstimatesPaginated($paginatedQueryInput: PaginatedQueryInput!) {
    getEstimatesPaginated(paginatedQueryInput: $paginatedQueryInput) {
        pageInfo {
            hasNextPage
            offset
            numRecords
        }
        edges {
            ${estimateListFields}
        }
    }
}`;
