import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Table } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export const BaseTableUIEditable: React.FC<{
  prepareRow: any;
  rows: any;
  getTableProps: any;
  getTableBodyProps: any;
  headerGroups: any[];
}> = ({ headerGroups, getTableProps, getTableBodyProps, rows, prepareRow }) => {
  return (
    <TableContainer>
      <Table {...getTableProps()} stickyHeader size={"medium"}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow className={"py-2"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell padding={"none"} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
