import { Column } from "react-table";
import { Market, Query } from "../../generated/nest-graphql";
import { Link } from "react-router-dom";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_MARKETS } from "../../graphql/queries/getMarkets";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { prop } from "ramda";
import { AddToolbarButton } from "../Table/AddToolbarButton";

const MarketColumns: Column<Market>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/markets/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Name", accessor: "name" },
  { Header: "Timezone", accessor: "timeZone" },
];

export const MarketsTable = () => {
  const { data } = useQuery<Query>(GET_MARKETS);
  if (!data) return null;
  return (
    <SelectableEntityTable
      data={prop("getMarketsV2", data)}
      columns={MarketColumns}
      numRecords={data.getMarketsV2.length}
      title={"Markets"}
      endAdornments={<AddToolbarButton link={"/add/markets"} title={"Market"} />}
    />
  );
};
