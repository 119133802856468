import React from "react";
import { driveTimes } from "./functions";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DriveTimeDistanceTableCell } from "./DriveTimeDistanceTableCell";
import { propOr } from "ramda";
import { makeStyles } from "@material-ui/core/styles";
import { isPresent } from "../../lib/functions";

export const useStyles = makeStyles({
  root: {
    background: "#000",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
    height: 60,
    padding: 8,
    border: "2px solid #a9a9a9",
  },
});

export const DriveTimeDistanceTableRows: React.FC<{
  technicians: any[];
}> = ({ technicians }) => {
  const classes = useStyles();
  return (
    <>
      {driveTimes.map((time, index) => {
        return (
          <TableRow key={index}>
            <TableCell className={classes.root}>{time}</TableCell>
            {isPresent(technicians) ? (
              technicians.map((technician, index) => (
                <DriveTimeDistanceTableCell
                  key={index}
                  appointments={propOr([], "appointments")(technician)}
                  time={time}
                />
              ))
            ) : (
              <TableCell></TableCell>
            )}
          </TableRow>
        );
      })}
    </>
  );
};
