import * as React from "react";
import * as Yup from "yup";
import { useS3Upload } from "../../hooks/useS3Upload";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationGeneratePresignedUrlArgs,
} from "../../generated/nest-graphql";
import { GENERATE_PRESIGNED_URL } from "../../graphql/mutations/generatePresignedUrl";
import { Form, Formik } from "formik";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { TextField } from "../FormFields/TextField";
import { ExecutionResult } from "graphql";

type FileFormValues = {
  caption: string;
  fileName: string;
  url: string;
};

const fileFormValidationSchema = Yup.object().shape({
  caption: Yup.string().notRequired(),
  url: Yup.string().required("Required"),
  fileName: Yup.string().required("Required"),
});

const FileFormInternals: React.FC<{
  contactId: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  generatePresignedUrl: (options?: any) => Promise<ExecutionResult<Mutation>>;
  onCancel: () => void;
  isSubmitting: boolean;
  isValid: boolean;
}> = ({
  contactId,
  setFieldValue,
  generatePresignedUrl,
  onCancel,
  isSubmitting,
  isValid,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useS3Upload({
    contactId,
    onError: console.error,
    onUploadDone: async ({ url, fileName }) => {
      setFieldValue("url", url);
      setFieldValue("fileName", fileName);
    },
    onUploadStart: async (img) => {
      const result = await generatePresignedUrl({
        variables: {
          customerId: contactId,
          fileName: img,
        },
      });
      return result.data.generatePresignedUrl;
    },
  });
  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <Form>
      <section className="container">
        <h3>Add File</h3>
        <div
          {...getRootProps({ className: "dropzone bg-black-900 p-4 rounded" })}
        >
          <input {...getInputProps()} />
          <p>Drag file here, or click to select file</p>
        </div>
        <div className="py-4">
          <TextField name={"caption"} label={"Caption"} />
        </div>
        <div>
          <h4>File</h4>
          <ul>{files}</ul>
        </div>
      </section>
      <div className="flex flex-row justify-between">
        <div>
          <CancelButton type={"button"} onClick={onCancel}>
            Cancel
          </CancelButton>
        </div>
        <div>
          <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
            Submit
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
};

export const FileForm: React.FC<{
  contactId: string;
  onSubmit: (values, helper) => void;
  initialValues: FileFormValues;
  onCancel: () => void;
}> = ({ contactId, initialValues, onSubmit, onCancel }) => {
  const [generatePresignedUrl] = useMutation<
    Mutation,
    MutationGeneratePresignedUrlArgs
  >(GENERATE_PRESIGNED_URL);
  return (
    <Formik<FileFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={fileFormValidationSchema}
    >
      {({ isValid, isSubmitting, values, setFieldValue }) => {
        return (
          <FileFormInternals
            contactId={contactId}
            setFieldValue={setFieldValue}
            generatePresignedUrl={generatePresignedUrl}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
        );
      }}
    </Formik>
  );
};
