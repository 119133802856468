import gql from "graphql-tag";
import { appointmentDetailsFields } from "../queries/getAppointments";

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($createAppointmentInput: AppointmentInput!) {
    createAppointment(createAppointmentInput: $createAppointmentInput) {
      ${appointmentDetailsFields}
    }
  }
`;
