export const padLifeOptions = [
  "1mm",
  "1.5mm",
  "2mm",
  "2.5mm",
  "3mm",
  "3.5mm",
  "4mm",
  "4.5mm",
  "5mm",
  "5.5mm",
  "6mm",
  "6.5mm",
  "7mm",
  "7.5mm",
  "8mm",
];
