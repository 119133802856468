import React from "react";
import { getFieldValueFromMarkets, MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { getYearsV2 } from "../../lib/getYearsV2";
import { useLazyQuery } from "@apollo/client";
import { GET_MAKES } from "../../graphql/queries/getMakes";
import { Query, QueryGetMakesArgs, QueryGetModelsArgs, QueryGetSubModelsArgs } from "../../generated/nest-graphql";
import { always, cond, equals, isNil, none, pathOr, propOr, T } from "ramda";
import { GET_MODELS } from "../../graphql/queries/getModels";
import { GET_SUB_MODELS } from "../../graphql/queries/getSubModels";
import { AutocompleteVehicleSelect } from "../FormFields/AutocompleteVehicleSelect";
import { PricingToolFormValues } from "../Forms/PricingToolForm";
import { AutocompletePromo } from "../FormFields/AutocompletePromo";
import { FormikAutocomplete } from "../FormFields/FormikAutocomplete";
import { dayOfWeekOptions, leadTimeOptions, leadTimes } from "./PricingToolConstants";
import { DateTime } from "luxon";
import { useMarkets } from "../../hooks/useMarkets";

interface Props {
  values: PricingToolFormValues;
  setFieldValue: any;
  queryServiceCatalogueItem: any;
}
export const PricingToolInnerForm = ({ values, setFieldValue, queryServiceCatalogueItem }: Props) => {
  const markets = useMarkets();
  const getMakes = (year) => {
    if (year) {
      return propOr([], "getMakes", makesData) as string[];
    }
    return [];
  };
  const getModels = (year, make) => {
    if (none(isNil, [year, make])) {
      return propOr([], "getModels", modelsData) as string[];
    }
    return [];
  };
  const getSubModels = (year, make, model) => {
    if (none(isNil, [year, make, model])) {
      return propOr([], "getSubModels", subModelsData) as string[];
    }
    return [];
  };
  const years = getYearsV2();
  const [queryMakes, { data: makesData, loading: makesLoading }] = useLazyQuery<Query, QueryGetMakesArgs>(GET_MAKES);
  const [queryModels, { data: modelsData, loading: modelsLoading }] = useLazyQuery<Query, QueryGetModelsArgs>(
    GET_MODELS
  );
  const [querySubModels, { data: subModelsData, loading: subModelsLoading }] = useLazyQuery<
    Query,
    QueryGetSubModelsArgs
  >(GET_SUB_MODELS);
  return (
    <>
      <div className="grid grid-cols-5 gap-4 mb-3">
        <MarketFieldSelect
          name={"market"}
          required={true}
          postOnChange={(market) => {
            const { year, make, model, subModel } = values;
            if (market && year && make && model && subModel) {
              queryServiceCatalogueItem({
                variables: {
                  year: year.value,
                  make: make.value,
                  model: model.value,
                  subModel: subModel.value,
                  market: market,
                },
              });
            }
          }}
        />
        <AutocompleteVehicleSelect
          name={"year"}
          label={"Year"}
          required={true}
          options={years}
          postOnChange={(year) => {
            setFieldValue("make", null);
            setFieldValue("model", null);
            setFieldValue("subModel", null);
            if (year) {
              queryMakes({
                variables: {
                  year: year.value,
                },
              });
            }
          }}
        />
        <AutocompleteVehicleSelect
          name={"make"}
          label={"Make"}
          required
          options={getMakes(values.year)}
          loading={makesLoading}
          postOnChange={(make) => {
            setFieldValue("model", null);
            setFieldValue("subModel", null);
            if (make) {
              queryModels({
                variables: {
                  make: make.value,
                  year: values.year.value,
                },
              });
            }
          }}
        />
        <AutocompleteVehicleSelect
          name={"model"}
          label={"Model"}
          required={true}
          loading={modelsLoading}
          options={getModels(values.year, values.make)}
          postOnChange={(model) => {
            setFieldValue("subModel", null);
            if (model) {
              querySubModels({
                variables: {
                  make: values.make.value,
                  year: values.year.value,
                  model: model.value,
                },
              });
            }
          }}
        />
        <AutocompleteVehicleSelect
          name={"subModel"}
          label={"Sub Model"}
          loading={subModelsLoading}
          required
          options={getSubModels(values.year, values.make, values.model)}
          postOnChange={(subModel) => {
            const { year, make, model, market } = values;
            if (subModel && market) {
              queryServiceCatalogueItem({
                variables: {
                  year: year.value,
                  make: make.value,
                  model: model.value,
                  subModel: subModel.value,
                  market: market,
                },
              });
            }
          }}
        />
        <AutocompletePromo name="percentageDiscount" />
        <FormikAutocomplete
          label="Day of Appointment"
          name="dayOfWeek"
          options={dayOfWeekOptions}
          postOnChange={(day) => {
            setFieldValue("leadTime", null);
            if (day) {
              const days = {
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6,
                sunday: 7,
              };
              const marketTimeZone = getFieldValueFromMarkets(markets, "timeZone", values.market);
              const timeZone = pathOr(null, [values.market], marketTimeZone);
              // return if no timezone because we technically cant determine the day.
              if (isNil(timeZone)) return;
              // monday = 1, tuesday = 2, etc...
              const currentDay = DateTime.local().setZone(timeZone).weekday;
              const selectedWeekday = days[day.value];
              const diff =
                selectedWeekday >= currentDay ? selectedWeekday - currentDay : selectedWeekday + 7 - currentDay;
              const leadTime = cond([
                [equals(0), always(leadTimes.sameDay)],
                [equals(1), always(leadTimes.nextDay)],
                [T, always(leadTimes.twoPlusDays)],
              ])(diff);
              setFieldValue("leadTime", leadTime);
            }
          }}
        />
        <FormikAutocomplete label="Lead Time" name="leadTime" options={leadTimeOptions} />
      </div>
    </>
  );
};
