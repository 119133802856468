import gql from "graphql-tag";

export const partsStoreDetailsFields = `
  id
  storeNumber
  name
  address
  vendor
  market {
    id
    name
  }
  updatedAt
  createdAt`;

export const GET_PARTS_STORE = gql`
  query getPartsStore($id: ID!) {
    getPartsStore(id: $id) {
      ${partsStoreDetailsFields}
    }
  }
`;
