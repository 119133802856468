import * as React from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { applySpec, identity, map, propOr } from "ramda";
import { FieldError } from "react-hook-form";

type AutoCompleteSelectFieldProps = {
  value: any;
  label: string;
  loading?: boolean;
  required?: boolean;
  options: string[];
  onChange: any;
  error: FieldError;
};
export type OptionType = {
  label: string;
  value: string;
  inputValue?: string;
};
const filter = createFilterOptions<OptionType>();

const toOptions = (arr: string[]) =>
  map<string, OptionType>(
    applySpec<OptionType>({
      label: identity,
      value: identity,
    }),
    arr
  );

export const AutoCompleteSelectFieldRHF = ({
  value,
  required,
  label,
  options,
  loading = false,
  onChange,
  error,
}: AutoCompleteSelectFieldProps) => {
  const mappedOptions = toOptions(options);
  return (
    <>
      <Autocomplete<OptionType, boolean, false, true>
        value={{ label: value, value }}
        handleHomeEndKeys
        freeSolo
        selectOnFocus
        clearOnBlur
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") return option;
          if (typeof option.value === "string") {
            return propOr("", "label", option);
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return propOr("", "inputValue", option);
          }
          // Regular option
          return propOr("", "label", option);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add "${params.inputValue}"`,
              value: params.inputValue,
            });
          }
          return filtered;
        }}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} required={required} label={label} variant="outlined" />}
        loading={loading}
        options={mappedOptions}
      />
      {error && <div>{error.message}</div>}
    </>
  );
};
