import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetDefaultServicesArgs,
  QueryGetEstimateArgs,
  QueryGetInvoicesPaginatedArgs,
} from "../../generated/nest-graphql";
import { cond, head, path, pathOr, T } from "ramda";
import { AddInvoice } from "../Invoices/AddInvoice";
import { Button } from "../Buttons/Button";
import { InvoiceDetails } from "../Invoices/InvoiceDetails";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const EstimateInvoice: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const [enableAddInvoice, setEnableAddInvoice] = useState(false);
  const limit = 50;
  const estimateResults = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
    fetchPolicy: "cache-first",
  });
  const { data } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          estimate: estimateId,
        },
        limit,
        skip: 0,
      },
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["getInvoice", "vehicleInfo", "year"], data),
        model: pathOr("", ["getInvoice", "vehicleInfo", "model"], data),
        make: pathOr("", ["getInvoice", "vehicleInfo", "make"], data),
      },
    },
  });
  if (!path(["getInvoicesPaginated"], data) && !estimateResults?.data?.getEstimate) return null;
  if (!path(["getInvoicesPaginated", "edges", "length"], data)) {
    const {
      market,
      contact,
      items,
      taxable,
      serviceLocation,
      vehicleInfo,
      privateNotes,
      services,
      priceInfo,
      serviceCatalogueUsed,
    } = estimateResults.data.getEstimate;
    return (
      <div>
        {enableAddInvoice ? (
          <AddInvoice
            initialValues={{
              status: "Draft",
              contact,
              taxable,
              serviceLocation,
              market,
              items,
              technician: null,
              issuedDate: new Date(),
              dueDate: new Date(),
              ...vehicleInfo,
              privateNotes,
              estimate: estimateId,
              serviceCatalogueUsed: serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
              services: cond([
                [
                  (_val) => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && services?.length > 0,
                  () => services,
                ],
                [
                  () => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES,
                  () =>
                    defaultServices.getDefaultServices.map((service) => {
                      return {
                        ...service,
                        items: service.items.filter(({ behavior }) => behavior === "Default"),
                      };
                    }),
                ],
                [T, () => []],
              ])(null),
              discounts: priceInfo?.discounts ?? [],
            }}
          />
        ) : (
          <Button onClick={() => setEnableAddInvoice(true)}>Add Invoice</Button>
        )}
      </div>
    );
  }
  const invoice = head(data.getInvoicesPaginated.edges);
  return <InvoiceDetails invoiceId={invoice.id} />;
};
