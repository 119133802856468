import gql from "graphql-tag";

export const DeleteFile = gql`
  mutation DeleteFile($fileName: String!, $contact: ID!) {
    deleteFile(fileName: $fileName, contact: $contact) {
      fileName
      createdAt
      updatedAt
    }
  }
`;
