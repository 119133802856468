import React from "react";
import { TableCell, TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import TablePagination from "@material-ui/core/TablePagination";

type BaseTableUIProps = {
  prepareRow: any;
  rows: any;
  getTableProps: any;
  getTableBodyProps: any;
  headerGroups: any[];
  numSelected: number;
  title: string;
  pageInfo?: {
    page: number;
    numTotal: number;
    onChangePage: (event: unknown, newPage: number) => void;
  };
  endAdornments?: React.ReactNode;
};

export const BaseTableUI: React.FC<BaseTableUIProps> = ({
  headerGroups,
  pageInfo,
  getTableProps,
  getTableBodyProps,
  numSelected,
  rows,
  prepareRow,
  title,
  endAdornments,
}) => {
  return (
    <Paper>
      <TableContainer>
        <EnhancedTableToolbar numSelected={numSelected} title={title} endAdornments={endAdornments} />
        <Table {...getTableProps()} stickyHeader size={"medium"}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>{column.render("Header")}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pageInfo && (
        <TablePagination
          rowsPerPageOptions={[50]}
          component="div"
          count={pageInfo.numTotal}
          rowsPerPage={50}
          page={pageInfo.page}
          onChangePage={pageInfo.onChangePage}
        />
      )}
    </Paper>
  );
};
