import React from "react";
import { useField } from "formik";
import { default as CoreTextField } from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";

export const TextField: React.FC<{
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string | JSX.Element;
}> = ({ name, label, type, required, multiline, rows, helperText }) => {
  const [field, meta] = useField(name);
  const { value, ...fieldRest } = field;
  return (
    <CoreTextField
      fullWidth
      type={type}
      error={!!meta.error}
      variant={"outlined"}
      multiline={multiline}
      rows={rows}
      label={label}
      required={required}
      value={value ? value : ""}
      helperText={
        <>
          {helperText}
          <br />
          <ErrorTextField fieldName={field.name} displayError={!!meta.error} />
        </>
      }
      {...fieldRest}
    />
  );
};
