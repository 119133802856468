import { useLayoutEffect, useState } from "react";

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
    left: myRef.current.offsetLeft,
  });

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    left: 0,
  });

  useLayoutEffect(() => {
    if (myRef.current) {
      setDimensions(getDimensions());
    }

    // eslint-disable-next-line
  }, [myRef]);

  return dimensions;
};
