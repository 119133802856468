import * as React from "react";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateProductSetArgs } from "../../generated/nest-graphql";
import { CREATE_PRODUCT_SET } from "../../graphql/mutations/createProductSet";
import { ProductSetDetailsFormValues, ProductSetDetailsForm } from "../Forms/ProductSetDetailsForm";
import { FormikHelpers } from "formik";
import { isEmpty, pipe } from "ramda";
import { productSetDetailsSpec } from "../specs/productSetDetailsSpec";
import { cleanObject } from "../../lib/functions";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";

export const AddProductSet: React.FC<{}> = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createProductSet] = useMutation<Mutation, MutationCreateProductSetArgs>(CREATE_PRODUCT_SET);
  const onSubmit = async (values: ProductSetDetailsFormValues, formikHelpers: FormikHelpers<any>) => {
    const { products } = values;
    if (isEmpty(products)) {
      formikHelpers.setErrors({ products: "Products listing cannot be empty" });
      return;
    }
    const input = pipe(productSetDetailsSpec, cleanObject)(values);
    const result = await createProductSet({
      variables: {
        // @ts-ignore
        createProductSetInput: input,
      },
    });
    const id = result.data.createProductSet.id;
    showSuccess({ message: "Successfully Added Product Set" });
    await history.push(`/product-sets/${id}`);
  };

  return (
    <ProductSetDetailsForm
      onSubmit={onSubmit}
      initialValues={{
        name: "",
        description: "",
        products: [],
      }}
    />
  );
};
