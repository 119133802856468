import currency from "currency.js";
import { useMemo } from "react";
import { map, mergeRight } from "ramda";
import { flow } from "fp-ts/lib/function";
import { PricingToolFormValues } from "../components/Forms/PricingToolForm";

export function useAddableData(values: PricingToolFormValues) {
  const addableData = useMemo(
    () =>
      flow(
        map((val: any) => {
          const { parts = 0, labor = 0, discount = 0 } = val;
          const basePrice = currency(parts).add(currency(labor));

          return mergeRight(val, { price: basePrice.toString(), finalPrice: basePrice.subtract(discount).toString() });
        })
      )(values.addableItemRows),
    [values.addableItemRows]
  );
  return addableData;
}
