import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetDefaultServicesArgs,
  QueryGetEstimateArgs,
  QueryGetJobsPaginatedV2Args,
} from "../../generated/nest-graphql";
import { AddJob } from "../Jobs/AddJob";
import { cond, head, path, pathOr, prop, T } from "ramda";
import { JobDetails } from "../Jobs/JobDetails";
import { Button } from "../Buttons/Button";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { GET_JOBS_PAGINATED_V2 } from "../../graphql/queries/getJobsPaginatedV2";
import { GET_DEFAULT_SERVICES } from "../../graphql/queries/getDefaultServices";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const EstimateJob: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const [enableAddJob, setEnableAddJob] = useState(false);
  const estimatesResult = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
    fetchPolicy: "cache-first",
  });
  const { data } = useQuery<Query, QueryGetJobsPaginatedV2Args>(GET_JOBS_PAGINATED_V2, {
    variables: {
      paginatedQueryInput: {
        limit: 1,
        filter: {
          "jobCopy.estimate": estimateId,
        },
        skip: 0,
      },
    },
  });
  const { data: defaultServices } = useQuery<Query, QueryGetDefaultServicesArgs>(GET_DEFAULT_SERVICES, {
    variables: {
      getDefaultServicesInput: {
        year: pathOr("", ["getInvoice", "vehicleInfo", "year"], data),
        model: pathOr("", ["getInvoice", "vehicleInfo", "model"], data),
        make: pathOr("", ["getInvoice", "vehicleInfo", "make"], data),
      },
    },
  });
  if (!path(["getJobsPaginatedV2", "edges"], data) || !estimatesResult.data) return null;
  const {
    contact,
    items,
    market,
    serviceLocation,
    serviceLocationNotes,
    vehicleInfo,
    taxable,
    privateNotes,
    estimateNotes,
    services,
    priceInfo,
    serviceCatalogueUsed,
  } = estimatesResult.data.getEstimate;
  if (!path(["getJobsPaginatedV2", "edges", "length"], data)) {
    return (
      <div>
        {enableAddJob ? (
          <AddJob
            initialValues={{
              contact,
              taxable,
              appointmentId: null,
              items,
              estimate: estimateId,
              market,
              serviceLocation,
              serviceLocationNotes,
              description: estimateNotes,
              status: "New",
              type: "Brake Repair Service",
              jobName: "",
              partsLocation: "",
              make: prop("make", vehicleInfo),
              model: prop("model", vehicleInfo),
              year: prop("year", vehicleInfo),
              extraInfo: prop("extraInfo", vehicleInfo),
              vin: vehicleInfo?.vin ?? null,
              licensePlate: vehicleInfo?.licensePlate ?? null,
              privateNotes,
              serviceCatalogueUsed: serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
              services: cond([
                [
                  (_val) => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && services?.length > 0,
                  () => services,
                ],
                [
                  () => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES,
                  () =>
                    defaultServices.getDefaultServices.map((service) => {
                      return {
                        ...service,
                        items: service.items.filter(({ behavior }) => behavior === "Default"),
                      };
                    }),
                ],
                [T, () => []],
              ])(null),
              discounts: priceInfo?.discounts ?? [],
            }}
          />
        ) : (
          <Button onClick={() => setEnableAddJob(true)}>Add Job</Button>
        )}
      </div>
    );
  }
  const job = head(data.getJobsPaginatedV2.edges);
  return <JobDetails jobId={job.id} />;
};
