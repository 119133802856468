import * as React from "react";
import { Column, useRowSelect, useTable } from "react-table";
import { HeaderRenderer } from "./HeaderRenderer";
import { CellRenderer } from "./CellRenderer";
import { BaseTableUI } from "./BaseTableUI";

type SelectablePageableEntityTableRendererProps<T extends object> = {
  prevPage: () => void;
  canPrevPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  currentPage: number;
  title: string;
  numRecords: number;
  data: any[];
  columns: Array<Column<T>>;
  endAdornments?: React.ReactNode;
};
export const SelectablePageableEntityTableRenderer = <T extends object>({
  data,
  title,
  columns,
  canNextPage,
  canPrevPage,
  nextPage,
  numRecords,
  prevPage,
  currentPage,
  endAdornments,
}: SelectablePageableEntityTableRendererProps<T>) => {
  const useTableProps = useTable<T>(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: HeaderRenderer,
          Cell: CellRenderer,
        },
        ...columns,
      ]);
    }
  );
  const {
    getTableProps,
    headerGroups,
    rows,
    getTableBodyProps,
    prepareRow,
    selectedFlatRows,
  } = useTableProps;
  return (
    <>
      <BaseTableUI
        pageInfo={{
          numTotal: numRecords,
          onChangePage: async (event, newPage) => {
            currentPage < newPage ? nextPage() : prevPage();
          },
          page: currentPage,
        }}
        title={title}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        numSelected={selectedFlatRows.length}
        rows={rows}
        endAdornments={endAdornments}
      />
    </>
  );
};
