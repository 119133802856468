import React, { useState } from "react";
import { pluck, pathOr, prop } from "ramda";
import { Column } from "react-table";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { DELETE_CONTACT_PAYMENT_METHOD } from "../../graphql/mutations/deleteContactPaymentMethod";
import { SET_CONTACT_DEFAULT_PAYMENT_METHOD } from "../../graphql/mutations/setContactDefaultPaymentMethod";
import { useQuery, useMutation } from "@apollo/client";
import { Query, QueryGetContactArgs, Mutation } from "../../generated/nest-graphql";
import { EntityTable } from "../TableViewsPages/EntityTable";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { useShowSuccess } from "../../redux/slices/snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AddCreditCard } from "./AddCreditCard";

type CreditCardTableType = {
  brand: string;
  country: string;
  exp_month: string;
  exp_year: string;
  last4: string;
};

const CreditCardColumns: Column<CreditCardTableType>[] = [
  { Header: "Brand", accessor: "brand" },
  { Header: "Country", accessor: "country" },
  { Header: "Expiration Month", accessor: "exp_month" },
  { Header: "Expiration Year", accessor: "exp_year" },
  { Header: "Last 4 Digits", accessor: "last4" },
];

export const CreditCardsTable: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [key, setKey] = useState(1);
  const { data, loading } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const paymentMethods = pathOr([], ["getContact", "stripePaymentMethods"], data);
  const cards = pluck("card", paymentMethods);
  const showSuccess = useShowSuccess();
  const [deleteContactPaymentMethod, { loading: deleteLoading }] = useMutation<Mutation>(DELETE_CONTACT_PAYMENT_METHOD);
  const [setDefaultContactPaymentMethod, { loading: setDefaultLoading }] = useMutation<Mutation>(
    SET_CONTACT_DEFAULT_PAYMENT_METHOD
  );

  const onRemove = (index: number) => async () => {
    await deleteContactPaymentMethod({
      variables: {
        contactId,
        paymentMethodId: paymentMethods[index].id,
      },
    });
    showSuccess({ message: "Successfully Removed Credit Card" });
    setKey(key + 1);
  };
  const setDefault = (index: number) => async () => {
    if (paymentMethods[index].default) {
      return;
    }
    await setDefaultContactPaymentMethod({
      variables: {
        contactId,
        paymentMethodId: paymentMethods[index].id,
      },
    });
    showSuccess({ message: "Successfully Defaulted Credit Card" });
    setKey(key + 1);
  };

  if (!data || loading) {
    return null;
  }
  return (
    <>
      <div style={{ height: 10 }}>{(loading || deleteLoading || setDefaultLoading) && <LinearProgress />}</div>
      <EntityTable<CreditCardTableType>
        key={key}
        title={"Credit Cards"}
        data={cards}
        numRecords={cards.length}
        columns={CreditCardColumns}
        pluginHooks={(hooks) => {
          hooks.visibleColumns.push((columns) => [
            ...columns,
            {
              id: "delete",
              Header: <div>Delete</div>,
              width: 75,
              Cell: (props) => {
                return (
                  <div className="px-4">
                    <IconButton onClick={onRemove(props.row.index)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              },
            },
            {
              id: "setDefault",
              Header: <div>Set Default</div>,
              width: 75,
              Cell: (props) => {
                const index = props.row.index;
                return (
                  <div className="px-4">
                    <IconButton onClick={setDefault(index)}>
                      {prop("default", paymentMethods[index]) ? <CheckCircle /> : <CheckCircleOutline />}
                    </IconButton>
                  </div>
                );
              },
            },
          ]);
        }}
        endAdornments={<AddCreditCard tableKey={key} setKey={setKey} contactId={contactId} />}
      />
    </>
  );
};
