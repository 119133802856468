import React from "react";
import { Form, Formik } from "formik";
import { Query, QueryGetMarketForAddressArgs, Technician } from "../../generated/nest-graphql";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import * as Yup from "yup";
import { DeleteButton } from "../Buttons/DeleteButton";
import { AppointmentFormFields } from "../Appointments/AppointmentFormFields";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@material-ui/core/Box";
import { flow } from "fp-ts/lib/function";
import { isNil, not, path } from "ramda";
import { useQuery } from "@apollo/client";
import { GET_MARKET_FOR_ADDRESS } from "../../graphql/queries/getMarketForAddress";

export type AppointmentFormValues = {
  startDate: Date;
  endDate: Date;
  startTimeWindow: Date;
  endTimeWindow: Date;
  technician: Technician | null;
  job?: string | null;
  subject: string;
  allDay: boolean;
  id?: string | null;
  timeZone?: string;
  overrideOutsideServiceZone?: boolean;
  startDateChanged?: boolean;
  rescheduleReason?: string | null;
};
type AppointmentFormProps = {
  initialValues?: AppointmentFormValues;
  onCancel: any;
  market?: string;
  serviceLocation?: string;
  onSubmit: any;
  onDelete?: any;
  onDeleteSubmitting?: boolean;
};
const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
  subject: Yup.string().required("Required"),
  allDay: Yup.boolean(),
  technician: Yup.object().required("Required"),
  overrideOutsideServiceZone: Yup.boolean().oneOf([true], "Must Acknoledge Override"),
  startDateChanged: Yup.boolean().nullable(),
  rescheduleReason: Yup.string().when("startDateChanged", {
    is: true,
    then: Yup.string().required("Must enter a reschedule reason"),
    otherwise: Yup.string().nullable(),
  }),
});
export const AppointmentForm: React.FC<AppointmentFormProps> = ({
  onDeleteSubmitting,
  market,
  serviceLocation,
  initialValues,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const marketResults = useQuery<Query, QueryGetMarketForAddressArgs>(GET_MARKET_FOR_ADDRESS, {
    variables: {
      address: serviceLocation!,
    },
    skip: isNil(serviceLocation),
  });
  const outsideServiceArea = isNil(serviceLocation)
    ? false // default to false when no service location is given...
    : flow(path(["data", "getMarketForAddress"]), isNil)(marketResults);
  const initialOverrideOutsideServiceZone = not(outsideServiceArea);
  const initialValuesToUse = initialValues
    ? { ...initialValues, overrideOutsideServiceZone: initialOverrideOutsideServiceZone }
    : {
        id: null,
        startDate: new Date(),
        endDate: new Date(),
        startTimeWindow: new Date(),
        endTimeWindow: new Date(),
        subject: "",
        job: null,
        technician: null,
        allDay: false,
        timeZone: "",
        startDateChanged: false,
        overrideOutsideServiceZone: initialOverrideOutsideServiceZone,
      };

  return (
    <Formik<AppointmentFormValues>
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValuesToUse}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Box marginBottom={1}>
              <AppointmentFormFields outsideServiceArea={outsideServiceArea} market={market} />
            </Box>
            <Box display="flex">
              <Box justifyContent="flex-start" flexGrow={1}>
                <CancelButton type={"button"} onClick={onCancel}>
                  Cancel
                </CancelButton>
              </Box>
              <Box justifyContent="center" flexGrow={1}>
                {onDelete && initialValues?.id && (
                  <DeleteButton type={"button"} onClick={onDelete}>
                    {onDeleteSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : "Delete"}
                  </DeleteButton>
                )}
              </Box>
              <Box justifyContent="flex-end">
                <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                  Submit
                </SubmitButton>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
