import React, { Fragment, useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { DateInput } from "../FormFields/DateInput";
import { Button } from "../Buttons/Button";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { TextField } from "../FormFields/TextField";
import Modal from "react-modal";
import { ItemForm, ItemFormValues } from "./ItemForm";
import { anyPass, append, equals, isEmpty, isNil, keys, omit, path, pipe, prop, propOr, remove } from "ramda";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import { Contact, EjiDiscount, EjiService, PossibleEjiService, Query } from "../../generated/nest-graphql";
import { handleNoDecimal } from "../../lib/functions";
import { VehicleInfoFormSection, VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { NuModal } from "../NuModal";
import { SaveCancelRow } from "../SaveCancelRow";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { useToggle } from "../../hooks/useToggle";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { useQuery } from "@apollo/client";
import { Space } from "../Space";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { usePartsStores } from "../../hooks/usePartsStores";
import ServicesSection from "../Services/ServicesSection";
import { servicesSchema } from "../../yupSchemas";

export type EstimateDetailsFormValues = VehicleInfoValues & {
  status: string;
  items: ItemsTableType[];
  taxable?: boolean;
  contact?: Contact;
  market: string;
  serviceLocation?: string;
  issuedDate: Date;
  estimateNotes?: string;
  serviceLocationNotes?: string;
  privateNotes?: string;
  job?: string;
  serviceCatalogueUsed?: string;
  services: PossibleEjiService[] | EjiService[];
  discounts: EjiDiscount[];
};

const EstimateDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("required"),
  market: Yup.string().required("required"),
  estimateNotes: Yup.string().required("required"),
  services: servicesSchema,
});

export const EstimateDetailsForm: React.FC<{
  initialValues: EstimateDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  estimateId?: string;
  estimateNumber?: string;
  submitRowNotFixed?: boolean;
  onCancel?: () => void;
}> = ({ initialValues, onSubmit, estimateId, estimateNumber, submitRowNotFixed = false, onCancel }) => {
  Modal.setAppElement("#root");
  const [modalIsOpen, , toggleModal] = useToggle();
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const partsStoresData = usePartsStores();
  return (
    <div ref={currentRef}>
      <Formik<EstimateDetailsFormValues>
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={EstimateDetailsFormValidationSchema}
      >
        {({ isSubmitting, isValid, setValues, resetForm, values, setFieldValue, errors }) => {
          const onDeleteItem = (idx) => () => {
            const result = remove(idx, 1, values.items);
            setFieldValue("items", result);
          };
          const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
            const selectedValue = prop("value", value);
            const valueKeys = pipe(omit(["id"]), keys)(selectedValue);
            valueKeys.forEach((key) => {
              const val = selectedValue[key];
              const keyWithPrefix = `${prefix}.${key}`;
              setFieldValue(keyWithPrefix, val ? val : "");
            });
          };
          const market = prop("market", values);
          const partsStores = anyPass([isNil, isEmpty])(values.market)
            ? partsStoresData
            : partsStoresData.filter((x) => equals(path(["market", "name"], x), values.market));
          const taxable: boolean = propOr(true, "taxable", values);
          return (
            <div>
              <Form>
                {estimateNumber && <div className={"mb-4"}>Estimate Number: {estimateNumber}</div>}
                <div className={"grid grid-cols-2 gap-4 mb-4"}>
                  <SelectField name={"status"} options={["Draft", "Sent"]} label={"Status"} />
                  <DateInput name={"issuedDate"} label={"Issued Date"} />
                  {/* estimateId && <ActionsSelect entityName={"Estimate"} entityId={estimateId} /> */}
                </div>
                <DetailViewContainer title={"Contact info"}>
                  <div className={"grid grid-cols-3 gap-4"}>
                    <ContactsSelect name={"contact"} label={"Contact"} />
                    <div className="col-span-2">
                      <LocationSearchInput name={"serviceLocation"} label={"Service Location"} />
                    </div>
                    <MarketFieldSelect name={"market"} required={true} />
                  </div>
                </DetailViewContainer>
                <VehicleInfoFormSection />
                <DetailViewContainer title={"Notes"}>
                  <div className={"grid grid-cols-3 gap-4"}>
                    <TextField
                      name={"estimateNotes"}
                      label={"Estimate Notes"}
                      multiline={true}
                      rows={4}
                      required={true}
                    />
                    <TextField
                      name={"serviceLocationNotes"}
                      label={"Service Location Notes"}
                      multiline={true}
                      rows={4}
                    />
                    <TextField
                      name={"privateNotes"}
                      label={"Private Notes"}
                      multiline={true}
                      rows={4}
                      helperText="Internal notes used by ops and technicians."
                    />
                  </div>
                </DetailViewContainer>
                {values?.serviceCatalogueUsed === "Services" ? (
                  <DetailViewContainer title={"Services"}>
                    {!market || market === "ZipUnknown" || market === "" ? (
                      "Please select a market first."
                    ) : (
                      <ServicesSection
                        showTable={values?.services?.length > 0}
                        partsStores={partsStores}
                        values={values}
                        setValues={setValues}
                        ejiType={"ESTIMATE"}
                      />
                    )}
                  </DetailViewContainer>
                ) : (
                  <>
                    <DetailViewContainer title={"Items"}>
                      <div className="flex flex-row justify-start mb-4">
                        <div>
                          <Button onClick={toggleModal} type={"button"}>
                            + Add Item
                          </Button>
                        </div>
                      </div>
                      {!loading && data.getProducts && (
                        <ItemsTable
                          items={values.items}
                          onDeleteItem={onDeleteItem}
                          postItemSelect={postSelectProduct}
                          products={data.getProducts}
                          partsStores={partsStores}
                        />
                      )}{" "}
                    </DetailViewContainer>
                    <ItemsReceiptSection
                      items={prop("items", values) as ItemFormValues[]}
                      market={market}
                      taxable={taxable}
                    />
                  </>
                )}
                <Space height={30} />
                <SaveCancelRow
                  width={width}
                  offsetLeft={left}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  onCancel={() => {
                    resetForm(initialValues);
                    onCancel && onCancel();
                  }}
                  notFixed={submitRowNotFixed}
                />
                <NuModal isOpen={modalIsOpen} title="Add Item">
                  {!loading && data.getProducts && (
                    <ItemForm
                      products={data.getProducts}
                      onSubmit={async (itemFormValues: ItemFormValues) => {
                        const oldItems: ItemsTableType[] = values.items;
                        const { product, name, description, partNumber, partsCost, laborCost, amount } = itemFormValues;
                        const cleanedItemFormValues: ItemsTableType = {
                          amount: handleNoDecimal(amount),
                          laborCost: handleNoDecimal(laborCost),
                          partsCost: handleNoDecimal(partsCost),
                          partNumber,
                          description,
                          isInEstimate: true,
                          name,
                          product,
                        };
                        const result = append(cleanedItemFormValues, oldItems);
                        setFieldValue("items", result, false);
                        toggleModal();
                      }}
                      onCancel={toggleModal}
                    />
                  )}
                </NuModal>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
