import React from "react";
import { GET_PRODUCT_SET } from "../../graphql/queries/getProductSet";
import { Mutation, MutationUpdateProductSetArgs, Query, QueryGetProductSetArgs } from "../../generated/nest-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PRODUCT_SET } from "../../graphql/mutations/updateProductSet";
import { ProductSetDetailsForm, ProductSetDetailsFormValues } from "../Forms/ProductSetDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import { FormikHelpers } from "formik";
import { isEmpty, keys, pipe } from "ramda";
import { productSetDetailsSpec } from "../specs/productSetDetailsSpec";
import { useShowSuccess } from "../../redux/slices/snackbar";

export const ProductSetDetails: React.FC<{ productSetId: string }> = ({ productSetId }) => {
  const { data } = useQuery<Query, QueryGetProductSetArgs>(GET_PRODUCT_SET, {
    variables: {
      id: productSetId,
    },
  });

  const [updateProductSet] = useMutation<Mutation, MutationUpdateProductSetArgs>(UPDATE_PRODUCT_SET);
  const showSuccess = useShowSuccess();
  if (!data) return null;
  const { products, name, description } = data.getProductSet;
  const initialValues: ProductSetDetailsFormValues = {
    products,
    name,
    description,
  };
  const onSubmit = async (values: ProductSetDetailsFormValues, formikHelpers: FormikHelpers<any>) => {
    const { products } = values;
    if (isEmpty(products)) {
      formikHelpers.setErrors({ products: "Products listing cannot be empty" });
      return;
    }
    const diff = objectDiff(values, initialValues);
    // @ts-ignore
    const updates = pipe(productSetDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateProductSet({
        variables: {
          id: productSetId,
          // @ts-ignore
          updateProductSetInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Product Set" });
    }
  };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <ProductSetDetailsForm onSubmit={onSubmit} initialValues={initialValues} />
      </div>
    </div>
  );
};
