import { __, includes, isEmpty, isNil, filter, pipe, prop, where } from "ramda";

export const loadGoogleMapsScript = (id: string) => {
  if (document.getElementById(id)) {
    return;
  }

  const mapsApiKey = "AIzaSyDRZkNoSOvupa2xr4DAGxDexWGEq06Z2tc";
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;
  document.querySelector("head").appendChild(script);
};

export const setPostalCode = (
  placeId: string,
  fieldName: string,
  setFieldValue: any
) => {
  const geocoder = { current: null };

  if (!geocoder.current && (window as any).google) {
    geocoder.current = new (window as any).google.maps.Geocoder();
  }

  if (!geocoder.current || isEmpty(placeId) || isNil(placeId)) {
    return;
  }

  geocoder.current.geocode({ placeId }, (results: any, status: string) => {
    if (status !== "OK") {
      return;
    }
    const postalCodeComponent = pipe(
      prop("address_components"),
      //@ts-ignore
      filter(where({ types: includes("postal_code") }), __)
      //@ts-ignore
    )(results[0]);

    // Location might be too broad to have a unique postal code
    const postalCode = isEmpty(postalCodeComponent)
      ? ""
      : prop("long_name", postalCodeComponent[0]);

    setFieldValue(fieldName, postalCode);
  });
};
