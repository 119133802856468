import { Appointment } from "../../generated/nest-graphql";
import { formatToTime } from "../../lib/functions";
import { map, findIndex, equals } from "ramda";
import { setMinutes } from "date-fns";

export const driveTimes = [
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 AM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const STATUS_ERROR_MESSAGES = {
  NOT_FOUND: "Not found",
  ZERO_RESULTS: "No results",
  MAX_ROUTE_LENGTH_EXCEEDED: "Max route length exceeded",
};

export const getErrorMessage = (code: string) => {
  return STATUS_ERROR_MESSAGES[code] || "Internal Server Error";
};

export const findAppointmentIndexTime = (time: string, appointments: Appointment[]) => {
  const appointmentTimes = map((apt: Appointment) => {
    const flooredDate = setMinutes(new Date(apt.startDate), 0);
    return formatToTime(flooredDate);
  })(appointments);
  return findIndex(equals(time))(appointmentTimes);
};
