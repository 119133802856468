// date-fns format
export const DATE_TIME_FORMAT: string = "MM/dd/yyyy hh:mm a";

export const DATE_FORMAT: string = "MM/dd/yyyy";

export const TIME_FORMAT: string = "hh:mm a";
export const RESCHEDULE_REASONS = [
  "Customer: No show or no response",
  "Customer: Could not access vehicle",
  "Customer: Schedule conflict",
  "Customer: Did not provide VIN or LP",
  "Customer: Did not provide reason",
  "Customer: Weather",
  "Customer: Other",
  "HQ: Scheduling Mistake",
  "HQ: Technician running behind",
  "HQ: Incorrect parts",
  "HQ: Part availability",
  "HQ: Technician availability",
  "HQ: Technician could not complete service",
  "HQ: Weather",
  "HQ: Route optimization",
  "HQ: Re-quote",
  "HQ: Overbooked Time Slot",
  "HQ: Other",
];

export const WITHDRAW_REASONS = [
  "Customer: Decided on alternative option",
  "Customer: Price too high",
  "Customer: Rebooked for another time",
  "Customer: No show or no response",
  "Customer: Would not pay Service Call fee",
  "Customer: Could not access vehicle",
  "Customer: Schedule conflict",
  "Customer: Services no longer needed",
  "Customer: Did not provide VIN or LP",
  "Customer: Did not provide reason",
  "HQ: Technician running behind",
  "HQ: Incorrect parts",
  "HQ: Part availability",
  "HQ: Technician availability",
  "HQ: Technician could not complete service",
  "HQ: Weather",
  "HQ: Duplicate Job Booked",
  "Other: Freeform",
];

export const EJI_DISCOUNT_TYPE_PERCENT = "Percent";
export const EJI_DISCOUNT_TYPE_FLAT = "Flat";

export const DISCOUNT_REASONS = [
  "Marketing: Radio discount",
  "Marketing: Student / Teacher Discount",
  "Marketing: Friends & Family Discount",
  "Sales: Pricing Negotiation",
  "Sales: Competitor Price Match",
  "Sales: Increase Tech Utilization - Flash Sales",
  "Sales: Offer discount to close the lead immediately",
  "Sales: Follow Up Discount to incentivize lead to book in a certain time frame",
  "Support: Technician was late",
  "Support: Reschedule discount",
  "Support: Requote discount",
  "Support: Appeasement for poor service",
  "Support: Damage (in the case when a discount will cover cost of damages)",
  "Support: Misdiagnosis",
  "Support: Customer was charged the wrong amount",
  "Support: Customer discount was not applied",
];

export const SERVICE_CATALOGUE_USED_ITEMS = "Items";

export const SERVICE_CATALOGUE_USED_SERVICES = "Services";

export const DEFAULT_SERVICE_CATALOGUE_USED:
  | typeof SERVICE_CATALOGUE_USED_ITEMS
  | typeof SERVICE_CATALOGUE_USED_SERVICES = SERVICE_CATALOGUE_USED_SERVICES;
