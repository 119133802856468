import React from "react";
import { EstimatesTable } from "../../components/Estimates/EstimatesTable";

const EstimatesPage = () => {
  return (
    <>
      <div className="px-8 py-4">
        <div>
          <EstimatesTable />
        </div>
      </div>
    </>
  );
};
export default EstimatesPage;
