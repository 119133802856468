import React from "react";
import { useField } from "formik";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorTextField } from "./ErrorTextField";
import { InputAdornment } from "@material-ui/core";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { DATE_FORMAT } from "../../lib/constants";

export const DateInput: React.FC<{
  name: string;
  label: string;
  dateModifier?: any;
  required?: boolean;
  postChange?: (val: Date) => void;
}> = ({ name, label, dateModifier, required, postChange }) => {
  const [field, meta, helpers] = useField(name);
  const { value, ...fieldRest } = field;
  const renderValue = () => {
    if (value instanceof Date) return value;
    if (typeof value === "string") return new Date(value);
    return null;
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        error={!!meta.error}
        value={renderValue()}
        inputVariant="outlined"
        fullWidth
        format={DATE_FORMAT}
        label={label}
        required={required}
        {...fieldRest}
        onChange={(date) => {
          helpers.setValue(dateModifier ? dateModifier(date) : date);
          postChange && postChange(date);
        }}
        helperText={
          <ErrorTextField fieldName={field.name} displayError={!!meta.error} />
        }
      />
    </MuiPickersUtilsProvider>
  );
};

export const DateInputNonFormik: React.FC<{
  value: Date;
  label: string;
  required?: boolean;
  postChange?: (val: Date) => void;
}> = ({ value, label, required, postChange }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={value}
        inputVariant="outlined"
        fullWidth
        format={DATE_FORMAT}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday />
            </InputAdornment>
          ),
        }}
        required={required}
        onChange={(date) => {
          postChange && postChange(date);
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
